//Styles
import Styles from './styles.module.scss'
// Icons
import { ReactComponent as BackIcon } from '../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as UploadIcon } from '../../../theme/assets/icons/upload_file_icon.svg'
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close.svg'
import { ReactComponent as ClipIcon } from '../../../theme/assets/icons/paper_clip_blue_icon.svg'
// Core
import { useMemo, useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//Dropzone
import { useDropzone } from 'react-dropzone';
//Actions
import { settingsActions } from '../../../actions'
//Utils
import { convertBytes } from '../../../utils'
//Compoennts
import { Spinner } from '../../ChatSystem/Common/Spinner'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderColor: '#D89B95',
  borderStyle: 'dashed',
  borderRadius: 12,
  outline: 'none',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out',
};


const focusedStyle = {
  borderColor: '#D89B95'
};


export const UploadlFiles = ({ type, closeEditor }) => {
  const dispatch = useDispatch()

  const loading = useSelector(state => state.profile.loadingForSettings)

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [newDocuments, setNewDocuments] = useState([]);
  const [previousDocuments, setPreviousDocuments] = useState([]);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused } = useDropzone({
      accept: { 'text/plain': ['.pdf', '.doc', '.docx'] },
      maxFiles: 5,
      maxSize: 10 * 1024 * 1024
    });

  const user = useSelector(state => state.auth.user)
  const documents = useSelector(state => state.therapist.documents)

  useEffect(() => {
    acceptedFiles.map((file) => setUploadedFiles(prev => [...prev, file]))
    acceptedFiles.map((file) => setNewDocuments(prev => [...prev, file]))
  }, [acceptedFiles])

  useEffect(() => {
    if (documents.length) {
      const sortedDocuments = documents.filter(document => document?.file_type === type)
      setPreviousDocuments([...sortedDocuments])
      setUploadedFiles(prev => [...prev, ...sortedDocuments])
    }
  }, [documents])

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }), [
    isFocused,
  ]);

  const uploadFiles = () => {
    if (newDocuments.length) {
      console.log(newDocuments)

      const data = {
        token: user.token,
        files: [...newDocuments],
        type,
      }

      dispatch(settingsActions.uploadFiles(data))
    }
    setNewDocuments([])
  }

  const removeFileFromArray = (id) => {
    const isDocumentAlreadyUploaded = previousDocuments?.find(document => document?.id === id)
    const newArray = uploadedFiles.filter(file => file.id !== id)

    if (!!isDocumentAlreadyUploaded) {

      const data = {
        token: user.token,
        id,
      }

      dispatch(settingsActions.deleteFiles(data))
    } else {
      setNewDocuments(newArray)
    }
    setUploadedFiles(newArray)
  }

  const goBack = () => closeEditor()

  return <div className={Styles.main_container}>
    <div className={Styles.header}>
      <BackIcon onClick={goBack} />
      <h6>{`Upload your ${type} documents`}</h6>
    </div>
    {loading ? (
      <div className={Styles.spinner}>
        <Spinner />
      </div>
    ) : (
      <>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <div className={Styles.inner_container}>
            <UploadIcon />
            <div className={Styles.text_container}>
              <p className={Styles.paragraph}> Drag .pdf,.doc,.docx file here or click to open browser</p>
              <p className={Styles.description}>Maximum upload size: 10MB</p>
            </div>
          </div>
        </div>
        <div className={Styles.files}>
          {uploadedFiles?.map((file, index) => (
            <div key={index} className={Styles.files__file}>
              <div className={Styles.text}>
                <ClipIcon />
                <p>{file.name || file?.filename}</p>
                <span>({convertBytes(file.size)})</span>
              </div>
              <CloseIcon className={Styles.files__file__delete} onClick={() => removeFileFromArray(file.id)} />
            </div>
          ))}
        </div>
        <div className={Styles.btn_container}>
          <button
            disabled={!newDocuments.length}
            className={newDocuments.length ? Styles.btn_container__next : Styles.btn_container__next_disabled}
            onClick={uploadFiles}>
            Continue
          </button>
        </div></>
    )}
  </div >
}