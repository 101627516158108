// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const therapistApi = {
  getAllScreenings: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/screenings/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getOneScreening: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/profiles/${data.id}/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  acceptApplication: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/screenings/${data.id}/accept/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  declineApplication: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/screenings/${data.id}/decline/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload)
    })
  },
  getAllPatients: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/profiles/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getHistory: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/profiles/${data.id}/history/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getDocuments: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/documents/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getDocumentsForAdmins: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/${data.id}/documents/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getNotes: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/profiles/${data.id}/notes/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  createNote: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/parents/profiles/${data.id}/notes/create/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
}
