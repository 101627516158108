// Components
import { Authorization, AdvisorSignUpNewPassword } from '../../components'

/**
 * AdvisorSignUpUpdatePage component for Authorization and AdvisorSignUpNewPassword.
 *
 * @component
 * @category Public routing
 * @returns {JSX.Element} Components
 * @example
 * <{@link Authorization} />
 *    <{@link AdvisorSignUpNewPassword} />
 * </Authorization>
 */

export const AdvisorSignUpUpdatePage = () => {
  return (
    <Authorization>
      <AdvisorSignUpNewPassword />
    </Authorization>
  )
}
