// Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Components
import { ChildProfileTherapistHistory, GeneralProfileInformation, ParentProfileQuestionnairePart } from '../../components'
// Actions
import { therapistActions } from '../../actions'
import { childOnboardingQuestions } from '../../data'

export const ChildProfilePage = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.therapist.oneApplication)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [advisorInfo, setAdvisorInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)

  useEffect(() => {
    if (profileId && user?.token) {
      const dataForRequest = {
        id: profileId,
        token: user?.token,
      }

      dispatch(therapistActions.getOneApplication(dataForRequest))
    }
  }, [profileId, user])

  const returnPreviousOnboarding = () => {
    if (currentProfile?.onboarding) {
      const finalOnboarding = []

      for (const sections of childOnboardingQuestions) {
        for (const onboardSection of currentProfile?.onboarding) {
          if (sections?.id === onboardSection?.id)
            finalOnboarding?.push({ id: sections?.id - 1, main_statement: sections?.question, main_statement_answer: onboardSection?.answer })
        }
      }

      return finalOnboarding
    }
  }

  const returnNewOnboarding = () => {
    if (currentProfile?.questionnaire?.questionnaire) {
      const finalOnboarding = []

      for (const sections of currentProfile?.questionnaire?.questionnaire) {
        for (const onboardSection of currentProfile?.onboarding) {
          if (sections?.id === onboardSection?.id) finalOnboarding?.push({ ...sections, advisor_comment: onboardSection?.advisor_comment })
        }
      }

      return finalOnboarding
    } else {
      return null
    }
  }

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.full_name,
        age: currentProfile?.age,
        birth_date: currentProfile?.birth_date,
        languages: currentProfile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const advisorInfo = {
        full_name: currentProfile?.advisor,
      }

      const questionnaire = {
        familyDynamics: currentProfile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.questionnaire?.parental_wellbeing,
        questionnaire: returnNewOnboarding() || returnPreviousOnboarding(),
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setAdvisorInfo(advisorInfo)
      setQuestionnaire(questionnaire)
    }
  }, [currentProfile])

  return (
    <div style={{ padding: '30px', display: 'flex', gap: '20px', height: '100%' }}>
      <div style={{ width: '70%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
        <div style={{ width: '100%', height: '40%' }}>
          <GeneralProfileInformation profile={profileInfo} client={clientInfo} advisor={advisorInfo} />
        </div>
        <div style={{ width: '100%', height: '38%' }}>
          <ParentProfileQuestionnairePart questionnaire={questionnaire} />
        </div>
      </div>
      <div style={{ width: '50%' }}>
        <ChildProfileTherapistHistory />
      </div>
    </div>
  )
}
