//MUI
import { TextField, InputAdornment } from "@mui/material";
import styled from '@emotion/styled';
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'
//Styles
import Styles from './styles.module.scss'
//Core
import { useState, useEffect } from "react";

const StyledField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    color: '#626262',
    fontFamily: 'Poppins_Regular',
    fontSize: '16px',
    border: 'none',
    ':hover': {
      borderColor: '#C7BCCE'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#C7BCCE',
      borderWidth: '1px'
    },
  },
}));

export const TextFieldAdornment = (props) => {

  if (!props.register) return null

  return (
    <div className={`${Styles.text_wrapper} ${props?.class}`}>
      {props.label && (
        <label>
          {props.label}
          <span>{props?.requiredIcon}</span>
        </label>
      )}
      <StyledField
        {...props?.register}
        placeholder={props?.placeholder || ''}
        InputProps={{
          startAdornment: <InputAdornment className={props?.start_ador_style} position="start">{props?.startAdornment}</InputAdornment>,
          endAdornment: <InputAdornment className={props?.end_ador_style} position="end">{props?.endAdornment}</InputAdornment>
        }}
      />
      {props?.error && <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
        {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
        <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
      </div>}
    </div>
  )
}