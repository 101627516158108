// Components
import { Authorization, PasswordReset } from '../../components'

/**
 * PasswordResetPage component for Authorization and PasswordReset.
 *
 * @component
 * @category Public routing
 * @returns {JSX.Element} Components
 * @example
 * <{@link Authorization} />
 *    <{@link PasswordReset} />
 * </Authorization>
 */

export const PasswordResetPage = () => {
  return (
    <Authorization>
      <PasswordReset />
    </Authorization>
  )
}
