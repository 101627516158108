// Core
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Actions
import { adminActions } from '../../../../actions'
import { setStatus } from '../../../../reducers/generalSlice'
// Styles
import Styles from './styles.module.scss'
// Images
import closeIcon from '../../../../theme/assets/icons/close_white.svg'
// Components
import { SaveButton, TextAreaField, Loader } from '../../../GeneralComponents'
// Types
import { TherapistType } from '../../../../types'

const schema = Yup.object().shape({
  reason: Yup.string().required('Please enter reason for decline').max(150, 'Message should contain no more than 150 symbols'),
})

export const DeclineTherapist = (props) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.general.loading)
  const status = useSelector((state) => state.general.status)
  const user = useSelector((state) => state.auth.user)

  const [length, setLength] = useState(0)

  const {
    control,
    setValue,
    reset,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      reason: '',
    },
  })

  useEffect(() => {
    if (status === 200) {
      dispatch(setStatus(0))
      props.onClose()
    }
  }, [status])

  const onFormSubmit = (values) => {
    if (user.token) {
      const data = {
        id: props.therapist?.id,
        update: {
          status: 'decline',
          message: values.reason,
        },
        token: user.token,
      }

      dispatch(adminActions.declineTherapist(data))
      reset()
    }
  }

  return (
    <section className={Styles.info_container}>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className={Styles.decline_container}>
          <h3 className={Styles.info_caption}>
            <span>{`${props.therapist?.user?.full_name} - Decline`}</span>
            <img className={Styles.close_btn} src={closeIcon} onClick={props.onClose} alt="close" />
          </h3>
          <div className={Styles.info_content}>
            <TextAreaField
              name="reason"
              control={control}
              onChange={(event) => {
                setValue('reason', event.target.value, {
                  shouldValidate: true,
                })
                setLength(event.target.value.length)
              }}
              label={'Indicate the reason for decline'}
              class={Styles.textarea_wrap}
              classError={Styles.error}
              placeholder={'Reason text ...'}
              error={errors?.reason}
              maxlength={150}
              requiredIcon={'*'}
            />
            <span className={Styles.length_text}>{`${length}/150`}</span>
          </div>
          {loading ? (
            <Loader class={Styles.loader} />
          ) : (
            <div className={Styles.btn_container}>
              <SaveButton title={'Send'} class={Styles.send_btn} isValid={isValid} />
            </div>
          )}
        </div>
      </form>
    </section>
  )
}

DeclineTherapist.propTypes = {
  onClose: func,
  therapist: TherapistType,
}
