// Core
import { string, object, bool, number, func, shape } from 'prop-types'
import { useEffect, useState } from 'react'
// Instruments
import { Controller } from 'react-hook-form'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

export const TextAreaField = (props) => {
  if (!props.name || !props.control || !props.label) return null

  const [defValue, setDefValue] = useState(props?.defaultValue)
  const [newValue, setNewValue] = useState('')

  useEffect(() => {
    if (newValue) {
      setDefValue('')
    }
  }, [newValue])
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <div className={`${Styles.text_wrapper} ${props?.class}`}>
            <label>
              {props.label}
              <span>{props?.requiredIcon}</span>
            </label>
            <textarea
              {...field}
              value={field?.value || defValue || ''}
              autoFocus={props?.autoFocus}
              className={props?.inputClass}
              placeholder={props?.placeholder}
              disabled={props?.disabled}
              maxLength={props?.maxlength}
              onChange={(e) => {
                field?.onChange(e)
                setNewValue(e.target.value)
                if (props?.customOnChange) props?.customOnChange(e?.target?.value)
              }}
              onBlur={props?.onBlur}
              {...props.register}
            />
            {props?.error && (
              <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                {props?.error && <div className={`${Styles.error} ${props.classError}`}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>}
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

TextAreaField.defaultProps = {
  type: 'text',
}

TextAreaField.propTypes = {
  name: string,
  control: object,
  label: string,
  class: string,
  requiredIcon: string,
  autoFocus: bool,
  onChange: func,
  inputClass: string,
  placeholder: string,
  disabled: bool,
  maxlength: number,
  onBlur: func,
  register: object,
  classError: string,
  error: shape({
    message: string,
  }),
}
