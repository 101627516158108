import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'


export const schemaValid = (userType, has_password) => {
  return yupResolver(
    Yup.object().shape({
      full_name:
        Yup.string()
          .required('This field is required')
          .min(3, 'Your name should be at least 3-30 characters long')
          .max(30, 'Your name should be at least 3-30 characters long')
          .matches(/^[a-zA-Z\u0600-\u06FF\s.]*$/i, 'Your name should not contain numbers'),
      email:
        Yup.string()
          .required('This field is required')
          .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com'),
      languages: userType === 'advisor' ? (
        Yup.array()
          .of(
            Yup.lazy((value) => {
              switch (typeof value) {
                case 'object':
                  return Yup.object().shape({
                    value: Yup.string()
                      .min(3, 'This field should be at least 3 characters long')
                      .max(30, 'This field should be no more than 30 characters long')
                      .matches(/^[^0-9а-я]*$/i, 'This field should not contain numbers'),
                  })
                case 'string':
                  return Yup.string()
                    .min(3, 'This field should be at least 3 characters long')
                    .max(30, 'This field should be no more than 30 characters long')
                    .matches(/^[^0-9а-я]*$/i, 'This field should not contain numbers')
                default:
                  return Yup.mixed()
              }
            }),
          )
          .nullable(true)
          .required('This field is required')
          .min(1, 'This field should be at least 1 option')
          .max(5, 'This field should be no more than 5 options')
      ) : Yup.array(),
      country: userType !== 'admin' ? (
        Yup.object()
          .nullable(true)
          .required('This field is required')
      ) : Yup.string().nullable(true),
      old_password: ((userType === 'parent' || userType === 'therapist') && has_password) ? (
        Yup.string()
      ) : Yup.string().nullable(true),
      password: (userType === 'parent' || userType === 'therapist') ? (
        Yup.string()
      ) : Yup.string().nullable(true),
    })
  )
}