//Core
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
// Data
import { parent_onboarding_family_dynamics } from '../../../../data'
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE } from '../../../../data/enums'
// Utils
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from '../AddChild/locale.js/en.json'
import ar from '../AddChild/locale.js/ar.json'
import { translateFamilyDynamics } from '../../../../data/locale.data'

export const ParentOnboardingFamilyDynamics = ({ setOnboardingStep }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const [questionId, setQuestionId] = useState(0)

  const [localStorageObject, setLocalStorageObject] = useState(JSON.parse(getLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS))?.[0])

  const amountOfQuestions = parent_onboarding_family_dynamics?.length

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  useEffect(() => {
    const storage = JSON.parse(getLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS))
    if (!storage) {
      setLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS, JSON.stringify(parent_onboarding_family_dynamics))
    } else {
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      if (mainLocalStorage.family_dynamics_read_view) {
        setQuestionId(mainLocalStorage.family_dynamics_read_view)
      } else {
        for (const question of storage) {
          if (question?.answer) {
            continue
          } else {
            setQuestionId(question?.id)
            break
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    setLocalStorageObject(JSON.parse(getLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS))[questionId])
  }, [questionId])

  const handleSelectOption = (answer) => {
    localStorageObject.answer = answer
    const finalStorage = JSON.parse(getLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS))
    finalStorage?.splice(questionId, 1, localStorageObject)
    setLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS, JSON.stringify(finalStorage))
    if (localStorageObject?.id + 1 === amountOfQuestions) {
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      return
    } else {
      setQuestionId((prev) => prev + 1)
    }
  }

  const handleGoBack = () => {
    if (questionId === 0) {
      setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER)
    } else {
      setQuestionId((prev) => prev - 1)
    }
  }

  const handleGoNext = () => {
    if (!localStorageObject?.answer) {
      localStorageObject.answer = SKIPPED_VALUE
      const finalStorage = JSON.parse(getLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS))
      finalStorage?.splice(questionId, 1, localStorageObject)
      setLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS, JSON.stringify(finalStorage))
    }

    if (localStorageObject?.id + 1 === amountOfQuestions) {
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER
      mainLocalStorage.family_dynamics_read_view = null
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      return
    } else {
      setQuestionId((prev) => prev + 1)
    }
  }

  return (
    <div className={`${Styles.main_container}`}>
      {localStorageObject?.id === questionId && (
        <div className={Styles.main_container__content}>
          <h4>{translateFamilyDynamics?.[localStorageObject?.question]?.[selectedLanguage]}</h4>
          <div className={Styles.main_container__content__btns}>
            {localStorageObject?.options?.map((option) => (
              <div
                onClick={() => handleSelectOption(option?.value)}
                className={localStorageObject.answer === option.value ? Styles.main_container__content__btns__selected : Styles.main_container__content__btns__item}
                key={option?.id}
              >
                {translateFamilyDynamics?.[option?.value]?.[selectedLanguage]}
              </div>
            ))}
          </div>
        </div>
      )}
      <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} className={Styles.main_container__footer}>
        <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoBack}>
          {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          {t('add_child:footer:back')}
        </div>
        <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoNext}>
          {t('add_child:footer:next')} {selectedLanguage === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </div>
      </div>
    </div>
  )
}
