// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const zoomApi = {
  getZoomInformation: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/zoom/meetings/${data.id}/join/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  createSessionNote: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/create/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getSessionNote: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  approveSessionNote: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/approve/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  updateSessionNote: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/update/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  submitSessionNote: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/submit/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  discardEdditingSessionNote: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/zoom/meetings/${data.meetingId}/notes/discard/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
}
