// Constants
import { apiPath } from '../constants'
// Instruments
import axios from 'axios'

export const settingsApi = {
  changeUserInfo: (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/users/${data.id}/update/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.update)
    })
  },
  changeUserLanguages: (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/advisors/${data.id}/update/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.update)
    })
  },
  changeUserAvatar: (data) => {
    return fetch(`${apiPath}/users/upload-pic/`, {
      method: 'POST',
      headers: {
        Authorization: `Token ${data.token}`,
      },
      body: data.update,
    })
  },

  deleteUserAvatar: (data) => {
    return fetch(`${apiPath}/users/remove-pic/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${data.token}`,
      },
    })
  },
  uploadFiles: (data) => {
    const formData = new FormData();
    formData.append('file_type', data.type);
    data.files.map(file => formData.append('files', file))

    return axios({
      method: 'POST',
      url: `${apiPath}/therapists/documents/upload/`,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Token ${data.token}`,
      },
      data: formData
    })
  },
  deleteFiles: (data) => {
    return axios({
      method: 'DELETE',
      url: `${apiPath}/therapists/documents/${data.id}/delete/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  secureUploadFiles: (data) => {

    return axios({
      method: 'POST',
      url: `${apiPath}/therapists/documents/secure-upload/`,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data.payload
    })
  }
}
