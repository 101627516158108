/**
 * @fileoverview Redux slice for chat-related state management
 * @module chatSlice
 */

// Core
import { createSlice } from '@reduxjs/toolkit'
/**
 * Chat slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Array} initialState.allChannels - List of all chat channels.
 * @property {Object} initialState.contactDetails - Details of the contact.
 * @property {Array} initialState.chatAttachments - List of chat attachments.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setAllChannels - Sets the list of all chat channels.
 * @property {Function} reducers.setContactDetails - Sets the contact details.
 * @property {Function} reducers.setChatAttachments - Sets the list of chat attachments.
 */

export const chatSlice = createSlice({
  name: 'admin',
  initialState: {
    allChannels: [],
    contactDetails: {},
    chatAttachments: [],
  },
  reducers: {
    /**
     * Sets the list of all chat channels.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of chat channels.
     */
    setAllChannels: (state, action) => {
      state.allChannels = action.payload
    },
    /**
     * Sets the contact details.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new contact details.
     */
    setContactDetails: (state, action) => {
      state.contactDetails = action.payload
    },
    /**
     * Sets the list of chat attachments.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of chat attachments.
     */
    setChatAttachments: (state, action) => {
      state.chatAttachments = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setAllChannels - Action creator for setting the list of chat channels.
 * @property {Function} setContactDetails - Action creator for setting the contact details.
 * @property {Function} setChatAttachments - Action creator for setting the list of chat attachments.
 */

export const { setAllChannels, setContactDetails, setChatAttachments } = chatSlice.actions

export default chatSlice.reducer
