//API
import { zoomApi } from '../api'
//Actions
import { setMeetingInfo, setSessionNote } from '../reducers/zoomSlice'
import { setLoading, setModalLoading } from '../reducers/generalSlice'

export const zoomActions = {
  getMeetingInformation: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .getZoomInformation(data)
        .then((res) => {
          dispatch(setMeetingInfo(res.data))
          dispatch(setModalLoading(false))
        })
        .catch((e) => {
          console.log('zoomApi.getZoomInformation', e)
          dispatch(setModalLoading(false))
        })
    } catch (e) {
      console.log('zoomActions.getMeetingInformation', e)
      dispatch(setModalLoading(false))
    }
  },
  createSessionNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .createSessionNote(data)
        .then(() => {
          dispatch(setModalLoading(false))
          data.action()
        })
        .catch((e) => {
          console.log('zoomApi.createSessionNote', e)
          dispatch(setModalLoading(false))
          data.action()
        })
    } catch (e) {
      console.log('zoomActions.createSessionNote', e)
      dispatch(setModalLoading(false))
    }
  },
  getSessionNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .getSessionNote(data)
        .then((res) => {
          dispatch(setSessionNote(res?.data))
          dispatch(setModalLoading(false))
        })
        .catch((e) => {
          console.log('zoomApi.getSessionNote', e)
          dispatch(setModalLoading(false))
        })
    } catch (e) {
      console.log('zoomActions.getSessionNote', e)
      dispatch(setModalLoading(false))
    }
  },
  approveSessionNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .approveSessionNote(data)
        .then(() => {
          dispatch(setModalLoading(false))
          if (data?.action) data?.action()
        })
        .catch((e) => {
          console.log('zoomApi.approveSessionNote', e)
          dispatch(setModalLoading(false))
          if (data?.action) data?.action()
        })
    } catch (e) {
      console.log('zoomActions.approveSessionNote', e)
      dispatch(setModalLoading(false))
    }
  },
  updateSessionNote: (data) => () => {
    try {
      zoomApi
        .updateSessionNote(data)
        .then(() => {
          data?.action()
        })
        .catch((e) => {
          console.log('zoomApi.updateSessionNote', e)
          data?.actionOnError()
        })
    } catch (e) {
      console.log('zoomActions.updateSessionNote', e)
    }
  },
  submitSessionNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .submitSessionNote(data)
        .then(() => {
          dispatch(setModalLoading(false))
          data?.action()
        })
        .catch((e) => {
          console.log('zoomApi.submitSessionNote', e)
          dispatch(setModalLoading(false))
          data?.action()
        })
    } catch (e) {
      console.log('zoomActions.submitSessionNote', e)
      dispatch(setModalLoading(false))
    }
  },
  discardEdditingSessionNote: (data) => (dispatch) => {
    dispatch(setModalLoading(true))
    try {
      zoomApi
        .discardEdditingSessionNote(data)
        .then(() => {
          dispatch(setModalLoading(false))
          if (data?.action) data?.action()
        })
        .catch((e) => {
          console.log('zoomApi.submitSessionNote', e)
          dispatch(setModalLoading(false))
        })
    } catch (e) {
      console.log('zoomActions.submitSessionNote', e)
      dispatch(setModalLoading(false))
    }
  },
}
