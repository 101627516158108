/**
 * @fileoverview Redux slice for profile-related state management
 * @module profileSlice
 */

// Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Profile slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {string} initialState.avatar - Profile avatar URL.
 * @property {boolean} initialState.loadingForSettings - Indicates if loading for settings is in progress.
 * @property {string} initialState.timeZone - Information about the user's timezone.
 * @property {Object} initialState.hintModal - State related to the hint modal.
 * @property {boolean} initialState.hintModal.isOpen - Indicates if the hint modal is open.
 * @property {number} initialState.hintModal.client_onboarding_step - The hint modal step.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setAvatar - Sets the profile avatar URL.
 * @property {Function} reducers.setLoadingForSettings - Sets the loading state for settings.
 * @property {Function} reducers.setTimeZone - Sets the user's timezone.
 * @property {Function} reducers.setHintModalDetails - Sets the hint modal details.
 */
export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    avatar: '',
    loadingForSettings: false,
    timeZone: '',
    hintModal: {
      isOpen: false,
      client_onboarding_step: 0,
    },
  },
  reducers: {
    /**
     * Sets the profile avatar URL.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new avatar URL.
     */
    setAvatar: (state, action) => {
      state.avatar = action.payload
    },
    /**
     * Sets the loading state for settings.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {boolean} action.payload - The new loading state.
     */
    setLoadingForSettings: (state, action) => {
      state.loadingForSettings = action.payload
    },
    /**
     * Sets the user's timezone.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new timezone.
     */
    setTimeZone: (state, action) => {
      state.timeZone = action.payload
    },
    /**
     * Sets the hint modal details.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new hint modal details.
     */
    setHintModalDetails: (state, action) => {
      state.hintModal = { ...state.hintModal, ...action.payload }
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setAvatar - Action creator for setting the avatar URL.
 * @property {Function} setLoadingForSettings - Action creator for setting the loading state for settings.
 * @property {Function} setTimeZone - Action creator for setting the timezone.
 * @property {Function} setHintModalDetails - Action creator for setting the hint modal details.
 */
export const { setAvatar, setTimeZone, setLoadingForSettings, setHintModalDetails } = profileSlice.actions

export default profileSlice.reducer
