//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'

export const Notifications = ({
  icon,
  title,
  secondaryText,
  userName,
  linkOncklick,
  onClose,
}) => {
  return (
    <div className={Styles.main_container}>
      <div className={Styles.icon_conatiner}>
        {icon}
      </div>
      <div className={Styles.text_container}>
        <p>{title}</p>
        <span>{secondaryText} <span className={Styles.text_container__user}>{userName}</span></span>
      </div>
      <div className={Styles.icon} onClick={onClose}>
        <CloseIcon />
      </div>
    </div>
  )
}
