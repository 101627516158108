// Core
import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
// Routing
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom'
import history from './lib/history'
// Localization
import './i18n'
// Instruments
import './theme/main.scss'
import 'react-toastify/dist/ReactToastify.css'
// Store
import store from './store'
// Components
import App from './App'
import { clarity } from 'react-microsoft-clarity'

const root = createRoot(document.getElementById('root'))

clarity.init(process.env.REACT_APP_CLARITY_ID)
clarity.consent()

root.render(
  <Provider store={store}>
    <HistoryRouter history={history}>
      <App />
    </HistoryRouter>
  </Provider>,
)
