// Components
import { Authorization, SignUp, SliderAuth } from '../../components'

export const SignUpPage = () => {
  return (
    <Authorization>
      <SignUp />
    </Authorization>
  )
}
