// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

export const schemaValidProvideDiscount = (activeOption) => {
  return yupResolver(
    Yup.object().shape({
      discount_value_percentage: activeOption === 'percentage' ?
        Yup.string()
          .min(1, `The percentage value must be at least 1`)
          .max(2, `The percentage value shouldn't be more than 99`) :
        Yup.string().notRequired(),
      discount_value_amount: activeOption === 'amount' ?
        Yup.string()
          .min(1, `The amount value must be at least 1`)
          .max(3, `The amount value shouldn't be more than 999`) :
        Yup.string().notRequired()

    })
  );
};


