// Core
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
// Routing
import { Link, useLocation } from 'react-router-dom'
// Mui
import { Tooltip } from '@mui/material'
// Styles
import Styles from './styles.module.scss'
// Images
import logo from '../../theme/assets/icons/logo.svg'
// Components
import { UserMenu } from '../UserMenu'
import { ParentNav } from '../ParentFlow'
import { AdminNav } from '../AdminPanel'
import { TherapistNav } from '../TherapistFlow'
import { NotificationSound } from '../ChatSystem/Common/Chat'
import { UserSettings } from '../SettingsPopup'
//Icons
import { ReactComponent as HelpIcon } from '../../theme/assets/icons/question.svg'
import { ReactComponent as ContactSupport } from '../../theme/assets/icons/contact_support_icon.svg'
import { ReactComponent as EnvelopIcon } from '../../theme/assets/icons/envelop-animated-icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as ArrowDownIcon } from '../../theme/assets/icons/arrow_down_icon.svg'
import UK_FLAG_IMG from '../../theme/assets/images/uk-flag.png'
import AR_FLAG_IMG from '../../theme/assets/images/ar-flag.png'
//Actions
import { setHintModalDetails } from '../../reducers/profileSlice'
//Websocket
import WebSocketService from '../../websocket'
//i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const Header = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  useEffect(() => {
    i18n.addResourceBundle('en', 'Header', en)
    i18n.addResourceBundle('ar', 'Header', ar)
  }, [])

  const [webSocketInstance, setWebSocketInstance] = useState(null)

  const isAuth = useSelector((state) => state.auth.isAuth)
  const user = useSelector((state) => state.auth.user)

  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)
  const [isOpenHeaderMenu, setIsOpenHeaderMenu] = useState(false)

  const [isNotificationInit, setIsNotificationInit] = useState(true)
  const [playNotification, setPlayNotification] = useState(false)

  const [isShowIcon, setIsShowIcon] = useState(false)
  const [isLanguageSelectorOpen, setIsLanguageSelectorOpen] = useState(false)

  const [preferableLanguage, setPreferableLanguage] = useState('en')

  const dataForLanguages = {
    en: 'English',
    ar: 'Arabic',
  }

  const languagesArray = [
    { abbr: 'en', value: 'English', imageURL: UK_FLAG_IMG },
    { abbr: 'ar', value: 'Arabic', imageURL: AR_FLAG_IMG },
  ]

  useEffect(() => {
    setPreferableLanguage(localStorage.getItem('language'))
    i18n.changeLanguage(localStorage.getItem('language'))
  }, [])

  useEffect(() => {
    if (user?.token) {
      const webSocket = new WebSocketService('notifications', 0, user)
      if (isNotificationInit) {
        webSocket.setNotificationCallback((message) => {
          setPlayNotification(true)
          setIsShowIcon(true)
          setTimeout(() => setIsShowIcon(false), 5000)
        })

        setWebSocketInstance(webSocket)
        setIsNotificationInit(false)
      }
    }
  }, [user])

  useEffect(() => {
    if (webSocketInstance && webSocketInstance?.state !== 1) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  const handleShowHeaderMenu = () => setIsOpenHeaderMenu((prev) => !prev)

  const handleOpenSettings = () => setIsOpenSettingsModal(true)
  const handleCloseSettings = () => setIsOpenSettingsModal(false)

  const location = useLocation()
  const { pathname } = location
  const headerMobileRef = useRef(null)

  const showMobileBurger = pathname !== '/login' && pathname !== '/signup' && pathname !== '/login/pass-recovery' && pathname !== '/login/authentication-link'

  const getHomePage = () => {
    switch (user?.user?.user_type) {
      case 'therapist':
        return '/profile/therapist/applications'
      case 'parent':
        return '/profile/parent/therapy'
      case 'advisor':
        return '/admin_panel/advisor/applications'
      case 'admin':
        return '/admin_panel/admin/advisors'
      default:
        return '/login'
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerMobileRef.current && !headerMobileRef.current.contains(event.target) && !event.target.closest(`.${Styles.login_mobile}`)) {
        setIsOpenHeaderMenu(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleOpenHintModal = () => {
    dispatch(setHintModalDetails({ client_onboarding_step: '0', isOpen: true }))
  }

  const handleContactSupport = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_CONTACT_SUPPORT_NUMBER}&text=${encodeURIComponent(process.env.REACT_APP_CONTACT_SUPPORT_MSG)}`,
      '_blank',
    )
  }

  const handleOpenLanguageSelector = () => {
    setIsLanguageSelectorOpen((prev) => !prev)
  }

  const handleSelectLanguage = (value) => {
    localStorage.setItem('language', value)
    setPreferableLanguage(value)
    setIsLanguageSelectorOpen(false)
    i18n.changeLanguage(value)
  }

  return (
    <header className={Styles.header}>
      <div className={Styles.container}>
        <Link to={getHomePage()} className={Styles.logo_wrap}>
          <img className={Styles.logo} src={logo} alt="logo image" />
        </Link>
        {showMobileBurger && (
          <div className={Styles.login_burger}>
            {user?.user?.user_type === 'parent' && <HelpIcon onClick={handleOpenHintModal} />}
            {isShowIcon && <EnvelopIcon className={Styles.envelop} />}
            <div ref={headerMobileRef} className={Styles.menu_bar} onClick={handleShowHeaderMenu}>
              {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className={Styles.menu_line}></div>
              ))}
            </div>
          </div>
        )}
        {isOpenHeaderMenu && (
          <div className={Styles.mobile_content}>
            {user.user?.user_type === 'parent' && isAuth && user.token && user?.user?.is_email_confirmed && location.pathname !== '/success' && <ParentNav />}
            {user.user?.user_type === 'therapist' && isAuth && user.token && user?.user?.is_email_confirmed && <TherapistNav isIconShow={isShowIcon} />}
            {user.user?.user_type !== 'parent' && user.user?.user_type !== 'therapist' && isAuth && user.token && user?.user?.is_email_confirmed && <AdminNav />}
            <div className={Styles.login_mobile}>
              {location.pathname !== '/success' && (
                <div className={Styles.login_wrap}>{isAuth && user.token && user?.user?.is_email_confirmed && <UserMenu openSettings={handleOpenSettings} />}</div>
              )}
            </div>
            {user?.user?.user_type === 'parent' && (
              <p onClick={handleContactSupport} className={Styles.support}>
                {t('Header:support')}
              </p>
            )}
          </div>
        )}

        <div className={Styles.content}>
          {user.user?.user_type === 'parent' && isAuth && user.token && user?.user?.is_email_confirmed && location.pathname !== '/success' && <ParentNav isIconShow={isShowIcon} />}
          {user.user?.user_type === 'therapist' && isAuth && user.token && user?.user?.is_email_confirmed && <TherapistNav isIconShow={isShowIcon} />}
          {user.user?.user_type !== 'parent' && user.user?.user_type !== 'therapist' && isAuth && user.token && user?.user?.is_email_confirmed && (
            <AdminNav isIconShow={isShowIcon} />
          )}
        </div>
        <div className={Styles.login}>
          {location.pathname !== '/success' && (
            <div className={Styles.login_wrap}>
              {user?.user?.user_type === 'parent' && <HelpIcon onClick={handleOpenHintModal} />}
              {user?.user?.user_type === 'parent' && (
                <div style={{ position: 'relative', width: '150px', height: '40px' }}>
                  <div className={Styles.language}>
                    <div onClick={handleOpenLanguageSelector} className={Styles.language__selected}>
                      <div>
                        <img src={preferableLanguage === 'en' ? UK_FLAG_IMG : AR_FLAG_IMG} />
                        <p>{dataForLanguages[preferableLanguage]}</p>
                      </div>
                      {isLanguageSelectorOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
                    </div>
                    {isLanguageSelectorOpen &&
                      languagesArray
                        ?.filter((item) => item?.abbr !== preferableLanguage)
                        ?.map((item) => (
                          <div onClick={() => handleSelectLanguage(item?.abbr)} key={item?.abbr} className={Styles.language__selected}>
                            <div>
                              <img src={item?.imageURL} />
                              <p>{item?.value}</p>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              )}
              {user?.user?.user_type === 'parent' && (
                <div onClick={handleContactSupport} className={Styles.support}>
                  <ContactSupport />
                  <p>{t('Header:support')}</p>
                </div>
              )}
              {isAuth && user.token && user?.user?.is_email_confirmed && <UserMenu openSettings={handleOpenSettings} />}
            </div>
          )}
        </div>
        {isOpenSettingsModal && <UserSettings open={isOpenSettingsModal} onClose={handleCloseSettings} />}
        <NotificationSound play={playNotification} setPlayNotification={setPlayNotification} />
      </div>
    </header>
  )
}
