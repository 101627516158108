//Core
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Images
import { ReactComponent as ClockImage } from '../../theme/assets/images/clock_image.svg'
import { ReactComponent as ErrorImage } from '../../theme/assets/images/something_went_wrong_image.svg'
//Styles
import Styles from './styles.module.scss'
//Actions
import { verificationActions } from '../../actions/verificationActions'

export const Verification = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const dispatch = useDispatch()

  const status = useSelector(state => state.verification.responseStatus)
  const createdToken = useSelector(state => state.verification.createdToken)

  useEffect(() => {
    if (token) {
      dispatch(verificationActions.verifyLink({ token }))
    } else if (createdToken) {
      dispatch(verificationActions.verifyLink({ token: createdToken }))
    }
  }, [])
  return <div className={Styles.main}>
    {status === 0 ? (
      <div className={Styles.text}>
        <h3>Almost there</h3>
        <p>Please wait a second</p>
      </div>

    ) : (
      <div className={Styles.text_error}>
        <h3>Someting went wrong</h3>
        <p>Please try again</p>
      </div>
    )}

    {status === 0 ? <ClockImage /> : <ErrorImage />}
  </div>
}