// Core
import { useDispatch, useSelector } from 'react-redux'
// Actions
import { clearError, setError } from '../../../reducers/generalSlice'
import { authActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { Loader } from '../../GeneralComponents'
//Images
import VerifyPhoto from '../../../theme/assets/images/therapist_registration_success.jpg'

export const VerifyEmail = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.general.loading)
  const user = useSelector((state) => state.auth.user)
  const therapistEmail = useSelector((state) => state.auth.therapistEmail)

  console.log(user)

  const onVerifyEmail = () => {
    const data = {
      email: user?.email || therapistEmail,
    }

    dispatch(authActions.sendVerifyEmail(data))
  }
  return (
    <div className={Styles.mainContainer}>
      <div className={Styles.mainContainer__content}>
        <div className={Styles.mainContainer__content__text}>
          <h4>
            Please confirm that you want to use email
            <h4 className={Styles.mainContainer__content__text__email}>{` ${user?.email || therapistEmail || 'example@gmail.com'}`}</h4>
          </h4>
          <p>Once it’s done you will be able to start.</p>
        </div>
        <div className={Styles.mainContainer__content__btn_container}>
          <p>Didn’t get any link?</p>
          <div onClick={onVerifyEmail} className={Styles.mainContainer__content__btn_container__btn}>
            Send a new one
          </div>
        </div>
      </div>
      <div className={Styles.mainContainer__pic_container}>
        <img src={VerifyPhoto} />
      </div>
    </div>
  )
}
