// Core
import { string } from 'prop-types'
// Styles
import Styles from './styles.module.scss'

export const HeaderSection = (props) => {
  return (
    <h1 className={Styles.main_caption}>
      <span>{props.header_title}</span>
    </h1>
  )
}

HeaderSection.propTypes = {
  header_title: string,
}
