// Core
import { string, object, bool, shape } from 'prop-types'
import { useState } from 'react'
// Instruments
import { Controller } from 'react-hook-form'
// Mui
import { DatePicker, LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateField } from '@mui/x-date-pickers/DateField/DateField'

// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

export const DatePickerField = (props) => {
  if (!props.name || !props.control) return null

  const [isCalendarOpen, setCalendarOpen] = useState(false)
  const [selectedDate, setSelectedDate] = useState(null)

  const handleCalendarOpen = () => {
    setCalendarOpen(true)
    setSelectedDate(props?.maximumDate)
  }

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <div className={`${Styles.date_wrapper} ${props?.class}`}>
            <label>
              {props.label}
              <span>{props?.requiredIcon}</span>
            </label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                value={field.value || selectedDate}
                disabled={props?.disabled}
                maxDate={props?.maximumDate}
                minDate={props?.minimumDate}
                open={isCalendarOpen}
                onOpen={handleCalendarOpen}
                onClose={() => setCalendarOpen(false)}
                inputFormat="DD/MM/YYYY"
                className={props?.input_style}
                onChange={(event) => field.onChange(event)}
                renderInput={({ inputRef, inputProps, InputProps }) => (
                  <div>
                    <input type="date" className={`${Styles.date_input}`} ref={inputRef} {...inputProps} placeholder={'DD/MM/YYYY'} />
                    {InputProps?.endAdornment}
                  </div>
                )}
              />
            </LocalizationProvider>
            {props?.error && (
              <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

DatePickerField.propTypes = {
  name: string,
  control: object,
  class: string,
  label: string,
  requiredIcon: string,
  disabled: bool,
  error: shape({
    message: string,
  }),
}
