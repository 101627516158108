//MUI
import { Pagination } from '@mui/material'
import styled from '@emotion/styled'

//Styles
const StyledPagination = styled(Pagination)(({ theme }) => ({
  '& .MuiPagination-ul': {
    display: 'flex',
    justifyContent: 'center',
  },
  '& .MuiPaginationItem-root': {
    fontFamily: 'Poppins_Regular',
    fontWeight: 500,
    color: '#626262',
    background: '#EEE9F3',
  },
  '& .MuiPaginationItem-root.Mui-selected ': {
    fontFamily: 'Poppins_Regular',
    fontWeight: 500,
    color: '#FFFFFF',
    background: '#8450A0',
  },
  '& .MuiPaginationItem-root.Mui-selected:hover': {
    fontFamily: 'Poppins_Regular',
    fontWeight: 500,
    color: '#FFFFFF',
    background: '#8450A0',
  },
}))

export const CustomPagination = ({ dataArray, currentPage, setCurrentPage }) => {
  if (!dataArray.length || !currentPage) return null

  const handleChange = (event, value) => {
    setCurrentPage(value)
  }

  return <StyledPagination count={Math.ceil(dataArray.length / 8)} page={currentPage} onChange={handleChange} />
}
