/**
 * @fileoverview Configuration for Redux Store.
 * @module store
 */

// Core
import { configureStore } from '@reduxjs/toolkit'
// Reducers
import generalReducer from '../reducers/generalSlice'
import authReducer from '../reducers/authSlice'
import adminReducer from '../reducers/adminSlice'
import profileReducer from '../reducers/profileSlice'
import chatSliceReducer from '../reducers/chatSlice'
import advisorOnbordingReducer from '../reducers/advisorOnbordingSlice'
import parentSlice from '../reducers/parentSlice'
import therapistSlice from '../reducers/therapistSlice'
import verificationSlice from '../reducers/verificationSlice'
import zoomSlice from '../reducers/zoomSlice'

/**
 * Configuration for Redux Store.
 * @type {Store}
 * @property {Reducer} general - Reducer for general data: error message, loaging states etc..
 * @property {Reducer} auth - Reducer for authentication: data for registration, data about current user etc..
 * @property {Reducer} admin - Reducer for data connected to admin user.
 * @property {Reducer} profile - Reducer for profiles.
 * @property {Reducer} chatSystem - Reducer for chat system.
 * @property {Reducer} advisorOnboarding - Reducer for onboarding proccess.
 * @property {Reducer} parent - Reducer for data connected to parent user.
 * @property {Reducer} therapist - Reducer for data connected to therapist user.
 * @property {Reducer} verification - Reducer for verification.
 * @property {Reducer} zoom - Reducer for Zoom data.
 */

export default configureStore({
  reducer: {
    general: generalReducer,
    auth: authReducer,
    admin: adminReducer,
    profile: profileReducer,
    chatSystem: chatSliceReducer,
    advisorOnbording: advisorOnbordingReducer,
    parent: parentSlice,
    therapist: therapistSlice,
    verification: verificationSlice,
    zoom: zoomSlice,
  },
})
