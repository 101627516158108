// Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
// Actions
import { advisorOnbordingActions } from '../../actions/advisorOnbordingActions'
// Components
import { ParentProfileQuestionnairePart } from '../../components'
import { GeneralProfileInformation } from '../../components/ClientProfileComponents/GeneralInformation'

export const AdvisorParentProfilePage = () => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const currentProfile = useSelector((state) => state.advisorOnbording.currentParent)

  const { profileId } = useParams()

  const [profileInfo, setProfileInfo] = useState(null)
  const [clientInfo, setClientInfo] = useState(null)
  const [questionnaire, setQuestionnaire] = useState(null)

  useEffect(() => {
    if (profileId && user?.token) {
      const dataForRequest = {
        profileId,
        token: user?.token,
      }

      dispatch(advisorOnbordingActions.getProfile(dataForRequest))
    }
  }, [profileId, user])

  useEffect(() => {
    if (currentProfile) {
      const profileInfo = {
        full_name: currentProfile?.full_name,
        age: currentProfile?.age,
        birth_date: currentProfile?.birth_date,
        languages: currentProfile?.languages,
        concerns: currentProfile?.concerns,
        behavior: currentProfile?.questionnaire?.child_info?.behavior,
      }

      const clientInfo = {
        full_name: currentProfile?.parent?.full_name,
        country: currentProfile?.parent?.country,
      }

      const questionnaire = {
        familyDynamics: currentProfile?.questionnaire?.family_dynamics,
        parentalWellbeing: currentProfile?.questionnaire?.parental_wellbeing,
        questionnaire: currentProfile?.questionnaire?.questionnaire,
      }

      setProfileInfo(profileInfo)
      setClientInfo(clientInfo)
      setQuestionnaire(questionnaire)
    }
  }, [currentProfile])

  return (
    <div style={{ padding: '30px', display: 'flex', gap: '20px', height: '100%' }}>
      <div style={{ width: '50%' }}>
        <GeneralProfileInformation profile={profileInfo} client={clientInfo} />
      </div>
      <div style={{ width: '50%' }}>
        <ParentProfileQuestionnairePart questionnaire={questionnaire} />
      </div>
    </div>
  )
}
