// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
//Utils
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js'
//Regex
import { EMAIL_REGEX } from '../../../constants'

export const schemaValid = (blockNumber) => {
  const maxDate = new Date()
  maxDate.setFullYear(maxDate.getFullYear() - 90)

  const minDate = new Date()
  minDate.setFullYear(minDate.getFullYear() - 18)

  return yupResolver(
    Yup.object().shape({
      email: blockNumber === 1 ? Yup.string().required('This field is required').matches(EMAIL_REGEX, 'Please enter a valid email address, e.g. joe@mail.com') : Yup.string(),
      full_name:
        blockNumber === 1
          ? Yup.string()
              .required('This field is required')
              .min(3, 'Your name should be at least 3-30 characters long')
              .max(30, 'Your name should be at least 3-30 characters long')
              .matches(/^[a-zA-Z\u0600-\u06FF\s.]*$/i, 'Your name should not contain numbers')
          : Yup.string(),
      phone_number:
        blockNumber === 1
          ? Yup.string()
              .required('This field is required')
              .min(8, 'This field should be at least 7 characters long')
              .test('is-valid-phone', 'Invalid phone number', (value) => {
                if (value && value.length >= 7) {
                  const phone = parsePhoneNumber(value)

                  if (phone) {
                    const number = phone.number
                    const country = phone.country

                    return isValidPhoneNumber(number, country)
                  }
                }

                return false
              })
          : Yup.string(),
      languages:
        blockNumber === 1
          ? Yup.array()
              .of(
                Yup.lazy((value) => {
                  switch (typeof value) {
                    case 'object':
                      return Yup.object().shape({
                        value: Yup.string()
                          .min(3, 'This field should be at least 3 characters long')
                          .max(30, 'This field should be no more than 30 characters long')
                          .matches(/^[^0-9а-я]*$/i, 'This field should not contain numbers'),
                      })
                    case 'string':
                      return Yup.string()
                        .min(3, 'This field should be at least 3 characters long')
                        .max(30, 'This field should be no more than 30 characters long')
                        .matches(/^[^0-9а-я]*$/i, 'This field should not contain numbers')
                    default:
                      return Yup.mixed()
                  }
                }),
              )
              .nullable(true)
              .required('This field is required')
              .min(1, 'This field should be at least 1 option')
              .max(5, 'This field should be no more than 5 options')
          : Yup.array(),
      country: blockNumber === 1 ? Yup.object().nullable(true).required('This field is required') : Yup.object().nullable(true),
      birthday:
        blockNumber === 1
          ? Yup.date()
              .typeError('Invalid date')
              .max(minDate, 'Date must be at least 18 years ago')
              .min(maxDate, 'Date must be within the last 90 years')
              .nullable(true)
              .required('This field is required')
          : Yup.string(),
      professional_qualities:
        blockNumber === 2
          ? Yup.array()
              .of(
                Yup.lazy((value) => {
                  switch (typeof value) {
                    case 'object':
                      return Yup.object().shape({
                        value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                      })
                    case 'string':
                      return Yup.string().min(3, 'This field should be at least 3 characters long')
                    default:
                      return Yup.mixed()
                  }
                }),
              )
              .nullable(true)
              .required('This field is required')
              .min(1, 'This field should be at least 1 option')
          : Yup.array().nullable(true),
      experience:
        blockNumber === 2
          ? Yup.string()
              .required('This field is required')
              .min(1, `This field can't be empty.`)
              .max(3, 'This field must be at most 2 characters')
              .matches(/^(99|[1-9][0-9]?)(\+)?$/, 'Experience must be a positive integer')
          : Yup.string(),
      education_level:
        blockNumber === 2 &&
        Yup.lazy((value) => {
          switch (typeof value) {
            case 'object':
              return Yup.object()
                .shape({
                  value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                })
                .nullable(true)
                .required('This field is required')
            case 'string':
              return Yup.string().min(3, 'This field should be at least 3 characters long').nullable(true).required('This field is required')
            default:
              return Yup.mixed().nullable(true).required('This field is required')
          }
        }),
      licence:
        blockNumber === 2
          ? Yup.array()
              .of(
                Yup.lazy((value) => {
                  switch (typeof value) {
                    case 'object':
                      return Yup.object().shape({
                        value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                      })
                    case 'string':
                      return Yup.string().min(3, 'This field should be at least 3 characters long')
                    default:
                      return Yup.mixed()
                  }
                }),
              )
              .nullable(true)
              .required('This field is required')
              .min(1, 'This field should be at least 1 option')
          : Yup.array(),
      expertise:
        blockNumber === 3
          ? Yup.array()
              .of(
                Yup.lazy((value) => {
                  switch (typeof value) {
                    case 'object':
                      return Yup.object().shape({
                        value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                      })
                    case 'string':
                      return Yup.string().min(3, 'This field should be at least 3 characters long')
                    default:
                      return Yup.mixed()
                  }
                }),
              )
              .nullable(true)
              .required('This field is required')
              .min(1, 'This field should be at least 1 option')
          : Yup.array(),
      clinical_services_provided:
        blockNumber === 3
          ? Yup.array()
              .of(
                Yup.lazy((value) => {
                  switch (typeof value) {
                    case 'object':
                      return Yup.object().shape({
                        value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                      })
                    case 'string':
                      return Yup.string().min(3, 'This field should be at least 3 characters long')
                    default:
                      return Yup.mixed()
                  }
                }),
              )
              .nullable(true)
              .required('This field is required')
              .min(1, 'This field should be at least 1 option')
          : Yup.array(),
      current_status:
        blockNumber === 3 &&
        Yup.lazy((value) => {
          switch (typeof value) {
            case 'object':
              return Yup.object()
                .shape({
                  value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                })
                .nullable(true)
                .required('This field is required')
            case 'string':
              return Yup.string().min(3, 'This field should be at least 3 characters long').nullable(true).required('This field is required')
            default:
              return Yup.mixed().nullable(true).required('This field is required')
          }
        }),
      age_groups_served:
        blockNumber === 3
          ? Yup.string()
              .min(1, `This field is required`)
              .max(250, 'This field must be at most 250 characters')
              .matches(/^\d{1,2}(-\d{1,2})?\+?$/, 'Invalid data format. Examples: 10, 10-12, 10+, 10-12+.')
          : Yup.string(),
      job_advantages:
        blockNumber === 3 &&
        Yup.lazy((value) => {
          switch (typeof value) {
            case 'object':
              return Yup.object()
                .shape({
                  value: Yup.string(),
                })
                .nullable(true)
                .required('This field is required')
            case 'string':
              return Yup.string().nullable(true).required('This field is required')
            default:
              return Yup.mixed().nullable(true).required('This field is required')
          }
        }),
      // job_advantages: blockNumber === 3 ? (
      //   Yup.string()
      //     .required('This field is required')
      // ) : Yup.string(),
    }),
  )
}
