/* eslint-disable no-useless-escape */
// Core
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Actions
import { clearError, setError } from '../../../reducers/generalSlice'
import { setUser } from '../../../reducers/authSlice'
import { authActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, Loader, SaveButton } from '../../GeneralComponents'

const schema = Yup.object().shape({
  email: Yup.string()
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com')
    .required('Please enter your email address'),
})

export const PasswordRecovery = () => {
  const dispatch = useDispatch()

  const loading = useSelector((state) => state.general.loading)
  const error = useSelector((state) => state.general.error)
  const user = useSelector((state) => state.auth.user)

  const [isActiveButton, setIsActiveButton] = useState(true)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  useEffect(() => {
    if (user?.email) {
      setValue('email', user.email, { shouldValidate: true })
    }
  }, [user])

  const onFormSubmit = (values) => {
    dispatch(setUser(values))
    dispatch(authActions.sendRecoveryPassword(values))
    setIsActiveButton(false)

    setTimeout(() => {
      setIsActiveButton(true)
    }, 30000)
    reset()
  }

  const onSendRecoveryPassword = () => {
    if (!user.email || !isActiveButton) {
      dispatch(clearError())

      return null
    }

    const data = { email: user.email }

    dispatch(authActions.sendRecoveryPassword(data))
    setIsActiveButton(false)

    setTimeout(() => {
      setIsActiveButton(true)
    }, 30000)
  }

  return (
    <section className={Styles.password}>
      <div className={Styles.password_container}>
        <h4>{'Forgot password?'}</h4>
        {error && (
          <div className={Styles.password_error}>
            <span />
            <span>{error}</span>
          </div>
        )}
        <p className={Styles.confirm_msg}>{'Please enter the email address you use when creating account and we will send you link to reset password'}</p>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TextField label={'Email address'} class={Styles.form_item} placeholder={'Enter your email'} error={errors?.email} register={register('email')} />
          {loading ? <Loader class={Styles.loader} /> : <SaveButton class={Styles.submit_btn} isValid={isValid && isActiveButton} title={'Send a link'} />}
        </form>
        <div className={Styles.clarification}>
          <span>{'Didn’t get any link?'}</span>
          <button onClick={() => onSendRecoveryPassword()} className={isValid && isActiveButton ? Styles.send_btn : Styles.send_btn_disabled}>
            {'Send a new one'}
          </button>
        </div>
      </div>
    </section>
  )
}
