//Core
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//Mui
import { Modal } from '@mui/material'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as AmountIcon } from '../../../../theme/assets/icons/amount-tag.svg'
import { ReactComponent as PercentageIcon } from '../../../../theme/assets/icons/percentage-icon.svg'
//Styles
import Styles from './styles.module.scss'
//Utils
import { getInitials } from '../../../../utils'
import { useForm, useWatch } from 'react-hook-form'
import { schemaValidProvideDiscount } from './validator'
//Components
import { TextFieldAdornment } from '../../../GeneralComponents'
import { Spinner } from '../../../ChatSystem/Common/Spinner'
//Actions
import { adminActions } from '../../../../actions'

export const ProvideDiscountPopup = ({ open, onClose, therapist, profileId, refetch }) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.modalLoading)

  const [activeOption, setActiveOption] = useState(therapist?.discount_type)
  const [priceUnderDiscount, setPriceUnderDiscount] = useState(therapist?.final_price || therapist?.session_price)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    reset,
    setValue,
    getValues,
    resetField,
    setError,
  } = useForm({
    mode: 'all',
    resolver: schemaValidProvideDiscount(activeOption),
    defaultValues: {
      discount_value_percentage: '',
      discount_value_amount: '',
    },
  })

  useEffect(() => {
    if (therapist?.discount_type === 'amount') return setValue('discount_value_amount', therapist?.discount_value)
    if (therapist?.discount_type === 'percentage') return setValue('discount_value_percentage', therapist?.discount_value)
  }, [therapist])

  const handleChooseActive = (option) => {
    setActiveOption(option)
    if (option === 'percentage') {
      resetField('discount_value_amount')
    } else if (option === 'amount') {
      resetField('discount_value_percentage')
    } else {
      resetField('discount_value_amount')
      resetField('discount_value_percentage')
    }
  }
  const percentageValue = useWatch({ control, name: 'discount_value_percentage' })
  const amountValue = useWatch({ control, name: 'discount_value_amount' })

  const handleReset = () => {
    const dataForDiscounts = {
      token: user?.token,
      payload: {
        profile_id: profileId,
        therapist_id: therapist?.id,
        discount_type: 'none',
        discount_value: '',
      },
    }

    dispatch(adminActions.provideDiscount(dataForDiscounts)).then((res) => {
      onClose()
      refetch((prev) => !prev)
    })
  }

  const handleApply = (data) => {
    const dataForDiscounts = {
      token: user?.token,
      payload: {
        profile_id: profileId,
        therapist_id: therapist?.id,
        discount_type: activeOption || 'none',
        discount_value: data?.discount_value_amount || data?.discount_value_percentage,
      },
    }

    dispatch(adminActions.provideDiscount(dataForDiscounts)).then((res) => {
      onClose()
      refetch((prev) => !prev)
    })
  }

  useEffect(() => {
    const currentPrice = therapist?.session_price

    if (activeOption === 'percentage') {
      const value = getValues('discount_value_percentage')
      const result = currentPrice - currentPrice * (value / 100)
      setPriceUnderDiscount(Math.ceil(result))
    } else if (activeOption === 'amount') {
      const value = getValues('discount_value_amount')
      const result = currentPrice - value
      setPriceUnderDiscount(Math.ceil(result))
    } else if (activeOption === 'is_free') {
      setPriceUnderDiscount(0)
    } else {
      setPriceUnderDiscount(currentPrice)
    }
  }, [percentageValue, amountValue, activeOption])

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <h5>Provide discount</h5>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.user_details}>
          <div className={Styles.user_details__photo}>{getInitials(therapist?.full_name)}</div>
          <div className={Styles.user_details__text}>
            <p>{therapist?.full_name}</p>
            {therapist?.final_price ? (
              <div className={Styles.user_details__text__changed_price}>
                <p>${therapist?.final_price}/h</p>
                <span>${therapist?.session_price}/h</span>
              </div>
            ) : (
              <p className={Styles.therapist_container__info__orig_price}>${therapist?.session_price}/h</p>
            )}
          </div>
        </div>
        <div className={Styles.options_container}>
          <div
            onClick={() => handleChooseActive('percentage')}
            className={
              activeOption !== 'none'
                ? activeOption === 'percentage'
                  ? Styles.options_container__item_active
                  : Styles.options_container__item_blured
                : Styles.options_container__item
            }
          >
            <div>
              {activeOption !== 'percentage' && !getValues('discount_value_percentage') ? (
                <PercentageIcon />
              ) : (
                <TextFieldAdornment
                  register={register('discount_value_percentage')}
                  endAdornment={'%'}
                  value={'20'}
                  startAdornment={'-'}
                  class={Styles.field_wrapper}
                  start_ador_style={Styles.adornment}
                  end_ador_style={Styles.adornment}
                  error={errors.discount_value_percentage}
                />
              )}
            </div>
            <h5>PERCENTAGE</h5>
          </div>
          <div
            onClick={() => handleChooseActive('amount')}
            className={
              activeOption !== 'none' ? (activeOption === 'amount' ? Styles.options_container__item_active : Styles.options_container__item_blured) : Styles.options_container__item
            }
          >
            <div>
              {activeOption !== 'amount' && !getValues('discount_value_amount') ? (
                <AmountIcon />
              ) : (
                <TextFieldAdornment
                  register={register('discount_value_amount')}
                  startAdornment={'-$'}
                  class={Styles.field_wrapper}
                  start_ador_style={Styles.adornment}
                  error={errors.discount_value_amount}
                />
              )}
            </div>
            <h5>AMOUNT</h5>
          </div>
          <div
            onClick={() => handleChooseActive('is_free')}
            className={
              activeOption !== 'none'
                ? activeOption === 'is_free'
                  ? Styles.options_container__item_active
                  : Styles.options_container__item_blured
                : Styles.options_container__item
            }
          >
            <div>$0</div>
            <h5>FREE</h5>
          </div>
        </div>
        <div className={Styles.price_container__item__final}>
          <span>Discount price:</span>
          <p className={Styles.price_container__item}>${priceUnderDiscount}/h</p>
        </div>
        <div className={Styles.footer}>
          <div onClick={handleReset} className={Styles.footer__reset}>
            Reset
          </div>
          <div className={Styles.footer__btns}>
            <div onClick={onClose} className={Styles.footer__btns__cancel}>
              Cancel
            </div>
            <div
              className={
                (isValid && (therapist?.final_price || therapist?.session_price) !== priceUnderDiscount) || activeOption === 'is_free'
                  ? Styles.footer__btns__apply
                  : Styles.footer__btns__apply_disabled
              }
              onClick={handleSubmit(handleApply)}
            >
              Apply
            </div>
          </div>
        </div>
        {loading && (
          <div className={Styles.loading_conatiner}>
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  )
}
