//Core
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
//Form
import { useForm } from 'react-hook-form'
import { schemaValid } from './validation'
//Components
import { DatePickerField, TextField, TextFieldMultiple } from '../../../GeneralComponents'
// Data
import { languages, parent_onboard_child_behavior, parent_onboard_child_concerns } from '../../../../data'
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME } from '../../../../data/enums'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
//Utils
import dayjs from 'dayjs'
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// Icons
import { ReactComponent as TickIcon } from '../../../../theme/assets/icons/check_white.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import ar from './locale.js/ar.json'
import en from './locale.js/en.json'
import { returnBehavior } from './locale.js/locale.functions'

export const ParentOnboardingAddChild = ({ setOnboardingStep, isAccountExists }) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [valuesForConcerns, setValuesForConcerns] = useState([])
  const [valuesForLanguages, setValuesForLanguages] = useState([])
  const [valuesForBehavior, setValuesForBehavior] = useState([])

  const [addChildStep, setAddChildStep] = useState(1)

  const [childName, setChildName] = useState(null)

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  const today = dayjs()
  const maximumDate = today.add(-1, 'year')
  const minimumDate = today.add(-18, 'year')

  const { t } = useTranslation()

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
    control,
    setValue,
  } = useForm({
    mode: 'all',
    resolver: schemaValid(),
    defaultValues: {
      child_name: '',
      birth_date: '',
      concerns: [],
      languages: [],
    },
  })

  useEffect(() => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    setValue('child_name', localStorage?.child_info?.child_name || '')
    setValue('birth_date', localStorage?.child_info?.birth_date || '')
    setValue('concerns', localStorage?.child_info?.concerns || [])
    setValue('languages', localStorage?.child_info?.languages || [])
    setValuesForConcerns(localStorage?.child_info?.concerns || [])
    setValuesForLanguages(localStorage?.child_info?.languages?.map((i) => ({ value: i })) || [])
    setValuesForBehavior(localStorage?.child_info?.behavior || [])

    if (localStorage?.child_info?.child_name) {
      setAddChildStep(2)
      setChildName(localStorage?.child_info?.child_name)
    }
  }, [getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)])

  const handleSubmitChildInformation = (data) => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))

    if (addChildStep === 1) {
      const dataForSave = {
        ...localStorage.child_info,
        ...data,
        languages: data?.languages?.map((i) => i?.value || i),
      }

      localStorage.child_info = dataForSave
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))

      setAddChildStep(2)
    } else {
      const dataForSave = {
        ...data,
        languages: data?.languages?.map((i) => i?.value || i),
        behavior: valuesForBehavior,
      }

      localStorage.child_info = dataForSave
      localStorage.state = `${ONBOARDING_STEP.QUESTIONNAIRE}`
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))
      setOnboardingStep(`${ONBOARDING_STEP.QUESTIONNAIRE}`)
    }
  }

  const handleSelectItem = (item) => {
    const localStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    let finalArray

    if (valuesForBehavior?.some((i) => i?.value === item?.value)) {
      finalArray = valuesForBehavior?.filter((i) => i?.value !== item?.value)
      setValuesForBehavior(finalArray)
    } else {
      finalArray = [...valuesForBehavior, item]
      setValuesForBehavior(finalArray)
    }

    localStorage.child_info.behavior = finalArray
    setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(localStorage))
  }

  const handleCheckIsValid = () => {
    if (addChildStep === 1 && isValid) {
      return true
    } else if (addChildStep === 2 && valuesForBehavior?.length) {
      return true
    } else {
      return false
    }
  }

  const handleGoBack = () => {
    if (isAccountExists) {
      navigate('/profile/parent/therapy')
    } else {
      setAddChildStep(1)
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', textAlign: selectedLanguage === 'en' ? 'left' : 'right' }}>
      <div className={Styles.main_container}>
        <div className={Styles.main_container__header}>
          {addChildStep === 1 ? (
            <>
              <h4>{t('add_child:welcome')}</h4>
              <p>{t('add_child:provide_support')}</p>
            </>
          ) : (
            <p className={'ClarityMask'}>{returnBehavior(childName)?.[selectedLanguage]}</p>
          )}
        </div>
        {addChildStep === 1 && (
          <div className={Styles.main_container__form}>
            <TextField
              label={t('add_child:fields:child_name:label')}
              placeholder={t('add_child:fields:child_name:placeholder')}
              error={errors?.child_name}
              register={register('child_name')}
              inputClass={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
            />
            <DatePickerField
              error={errors?.birth_date}
              name={'birth_date'}
              control={control}
              label={t('add_child:fields:birth_date:label')}
              maximumDate={maximumDate}
              minimumDate={minimumDate}
              class={selectedLanguage === 'en' ? Styles.date_picker_en : Styles?.date_picker_ar}
            />
            <div className={Styles.main_container__form__selector}>
              <TextFieldMultiple
                name="concerns"
                value={valuesForConcerns}
                setNewValue={setValuesForConcerns}
                control={control}
                options={parent_onboard_child_concerns}
                setValue={setValue}
                freeSolo={true}
                disabled={false}
                language={selectedLanguage}
                error={errors?.concerns}
                class_field={selectedLanguage === 'en' ? Styles.text_field_en : Styles?.text_field_ar}
                label={t('add_child:fields:concerns:label')}
                placeholder={t('add_child:fields:concerns:placeholder')}
                hintMessage={t('add_child:fields:concerns:hint')}
              />
            </div>
            <div className={Styles.main_container__form__selector}>
              <TextFieldMultiple
                name="languages"
                value={valuesForLanguages}
                setNewValue={setValuesForLanguages}
                control={control}
                options={languages}
                setValue={setValue}
                freeSolo={false}
                disabled={false}
                error={errors?.languages}
                label={t('add_child:fields:languages:label')}
                placeholder={t('add_child:fields:languages:placeholder')}
                hintMessage={t('add_child:fields:languages:hint')}
                register={register('languages')}
              />
            </div>
          </div>
        )}
        {addChildStep === 2 && (
          <div className={Styles.main_container__behavior_container}>
            {parent_onboard_child_behavior?.map((item, index) => (
              <div
                style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}
                className={
                  valuesForBehavior?.some((i) => i?.value === item?.value) ? Styles.main_container__behavior_container__selected : Styles.main_container__behavior_container__item
                }
                key={index}
                onClick={() => handleSelectItem(item)}
              >
                <TickIcon />
                {item?.[selectedLanguage]}
              </div>
            ))}
          </div>
        )}
        <div className={Styles.main_container__footer} style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}>
          <div onClick={handleGoBack} className={Styles.main_container__footer__back} style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}>
            {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
            <p>{t('add_child:footer:back')}</p>
          </div>
          <div
            onClick={handleSubmit(handleSubmitChildInformation)}
            style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }}
            className={handleCheckIsValid() ? Styles.main_container__next : Styles.main_container__next__disabled}
          >
            <p>{t('add_child:footer:next')}</p>
            {selectedLanguage === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          </div>
        </div>
      </div>
    </div>
  )
}
