// Components
import { Authorization, AdminSignIn } from '../../components'

/**
 * AdvisorSignInPage component for Authorization and AdminSignIn.
 *
 * @component
 * @category Public routing
 * @returns {Authorization} Components
 * @example
 * <{@link Authorization} />
 *    <{@link AdminSignIn} title={"Advisor log in"} />
 * </Authorization>
 */

export const AdvisorSignInPage = () => {
  return (
    <Authorization>
      <AdminSignIn title={'Advisor log in'} />
    </Authorization>
  )
}
