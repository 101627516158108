//Core
import { useState, useEffect } from 'react'
// Components
import {
  ParentOnboardingAddChild,
  ParentOnboardingFamilyDynamics,
  ParentOnboardingOptionalDisclaimer,
  ParentOnboardingParentalWellbeing,
  ParentOnboardingReport,
  QuestionPartMandatory,
} from '../../components/Auth'
// Enums
import { ONBOARDING_STEP } from '../../data/enums'
// Utils
import { getCookie, setCookie } from '../../utils'

export const ParentQuestionnaireForExistingAccount = ({ isAccountExists }) => {
  const [onboardingStep, setOnboardingStep] = useState(ONBOARDING_STEP.ADD_CHILD)

  useEffect(() => {
    const onboardingStateFromCookies = JSON.parse(getCookie('parent_onboarding'))

    if (onboardingStateFromCookies) {
      setOnboardingStep(onboardingStateFromCookies?.state)
    } else {
      const parentOnboardingObject = {
        state: ONBOARDING_STEP.ADD_CHILD,
        report_loading: true,
      }

      setCookie('parent_onboarding', JSON.stringify(parentOnboardingObject))
    }
  }, [])

  return (
    <>
      {onboardingStep === ONBOARDING_STEP.ADD_CHILD && <ParentOnboardingAddChild isAccountExists={isAccountExists} setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.QUESTIONNAIRE && <QuestionPartMandatory setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.FAMILY_DYNAMICS} setOnboardingStep={setOnboardingStep} />
      )}
      {onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS && <ParentOnboardingFamilyDynamics setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.PARENTAL_WELLBEING} setOnboardingStep={setOnboardingStep} />
      )}
      {onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING && <ParentOnboardingParentalWellbeing setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.REPORT && <ParentOnboardingReport isAccountExists={isAccountExists} setOnboardingStep={setOnboardingStep} />}
    </>
  )
}
