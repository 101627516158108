//Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//Utils
import { getInitials, returnCountry } from '../../../utils'
//Data
import { adultOnboardingQuestions, childOnboardingQuestions } from '../../../data'
//Components
import { HistoryRecord } from '../../ParentFlow/Therapy/HistoryRecord'
// Actions
import { therapistActions } from '../../../actions'
//Icons
import { ReactComponent as PreviousPageIcon } from '../../../theme/assets/icons/arrow_left_icon.svg'
//Tools
import { setHistory } from '../../../reducers/therapistSlice'
import WebSocketService from '../../../websocket'

/**
 * ChildProfile component for displaying detailed information about a child profile.
 * @returns {JSX.Element} ChildProfile component.
 */
export const ChildProfileTherapistHistory = () => {
  const { profileId } = useParams()

  const dispatch = useDispatch()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const application = useSelector((state) => state.therapist.oneApplication)
  const history = useSelector((state) => state.therapist.history)

  /**
   * Component state
   */
  const [webSocketInstance, setWebSocketInstance] = useState(null)

  /**
   * Effect to initialize WebSocket connection when user token is available.
   */
  useEffect(() => {
    if (user?.token) {
      setWebSocketInstance(new WebSocketService('sessions', 0, user))
    }
  }, [user])

  /**
   * Effect to connect WebSocket instance when initialized.
   */
  useEffect(() => {
    if (webSocketInstance) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  /**
   * Effect to fetch sessions history using WebSocket and update Redux state.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance.state() !== 1) {
      const waitForSocketConnection = (callback) => {
        setTimeout(() => {
          if (webSocketInstance.state() === 1) {
            callback()
          } else {
            waitForSocketConnection(callback)
          }
        }, 100)
      }

      waitForSocketConnection(() => {
        webSocketInstance.setSessionsCallback((data) => {
          dispatch(setHistory(data))
        })
        webSocketInstance?.fetchSessionsHistory(profileId)
      })
    } else if (webSocketInstance && webSocketInstance.state() === 1) {
      webSocketInstance.setSessionsCallback((data) => {
        console.log(data)
        dispatch(setHistory(data))
      })
      webSocketInstance?.fetchSessionsHistory(profileId)
    }
  }, [webSocketInstance, profileId])

  return (
    <div className={Styles.main_container}>
      <div className={Styles.content_div}>
        <div className={Styles.history_container}>
          <div className={Styles.header}>
            <h3>History</h3>
            <span>
              {history?.length} {history?.length === 1 ? 'meeting' : 'meetings'}
            </span>
          </div>
          {history?.map((record) => (
            <HistoryRecord record={record} clientName={application?.full_name} key={record.id} userRole={'therapist'} parent={application?.parent} />
          ))}
        </div>
      </div>
    </div>
  )
}
