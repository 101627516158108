//Core
import { useRef, useEffect, useState } from "react"
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowUp } from '../../../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as ArrowDown } from '../../../../../theme/assets/icons/arrow_down_icon.svg'
//Utils
import { convertDate } from "../../../../../utils"


export const Note = ({ note, userType }) => {

  const contentRef = useRef(null)

  const [showMore, setShowMore] = useState(false);
  const [showMoreBtn, setShowMoreBtn] = useState(false);

  useEffect(() => {

    const lineHeight = parseInt(window.getComputedStyle(contentRef.current).lineHeight);
    const maxHeight = lineHeight * 4;
    const actualHeight = contentRef.current.scrollHeight;

    if (actualHeight > maxHeight) {
      setShowMoreBtn(true)
    }
  }, [note]);

  const handleShowMore = () => {
    setShowMore(prev => !prev)
  }


  return (
    <div
      key={note?.id}
      className={Styles.content__note_container}>
      <div className={Styles.content__note_container__content}>
        <h6>{note?.title || 'Note'}</h6>
        <span
          ref={contentRef}
          className={showMore ? Styles.show_all : Styles.show_less}>
          {note?.note}
        </span>
        {showMoreBtn && (
          <div>
            {showMore ? (
              <div
                onClick={handleShowMore}
                className={Styles.show_btn}>
                <p>Show less</p>
                <ArrowUp />
              </div>
            ) : (
              <div
                onClick={handleShowMore}
                className={Styles.show_btn}>
                <p>Show more</p>
                <ArrowDown />
              </div>
            )}

          </div>
        )}
      </div>
      <p>{userType !== 'therapist' && `${note?.author}, `}{convertDate(note?.created_at)}</p>
    </div>
  )
}