//MUI
import { Modal } from "@mui/material"
//Styles
import Styles from './styles.module.scss'
//Core
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
//Validator
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
//Components
import { TextField } from '../../../GeneralComponents'
import { Spinner } from "../../../ChatSystem/Common/Spinner"
//Actions
import { adminActions } from "../../../../actions"
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'

const schema = Yup.object().shape({
  apiKey: Yup.string()
    .required('Please enter API key.'),
})

export const SetApiModal = ({ open, onClose, advisorId, therapistId }) => {

  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.modalLoading)


  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      apiKey: '',
    },
  })

  const handleSetAPIKey = async (data) => {

    if (data.apiKey && user.token) {
      const payload = {
        token: user.token,
        info: {
          api_key: data.apiKey,
        }
      }
      if (advisorId) {
        Object.assign(payload.info, { advisor_id: advisorId })
      } else {
        Object.assign(payload.info, { therapist_id: therapistId })
      }
      dispatch(adminActions.setAPIKey(payload))
        .then(res => onClose())
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <CloseIcon className={Styles.icon} onClick={onClose} />
        <div className={Styles.content}>
          <h6>Set API key</h6>
          <div className={Styles.content__form}>
            <p>Go to Cal.com, copy the key and paste here</p>
            <form>
              <TextField
                requiredIcon={' *'}
                class={Styles.form_item}
                placeholder={'abcdef12345'}
                error={errors?.apiKey}
                register={register('apiKey')} />
            </form>
          </div>
        </div>
        <div className={Styles.buttons}>
          <button className={Styles.btn__btn_save} onClick={handleSubmit(handleSetAPIKey)} isValid={isValid}>Save</button>
          <button className={Styles.btn__btn_cancel} onClick={onClose}>Cancel</button>
        </div>
        {loading && (
          <div className={Styles.loading_conatiner}>
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  )
}