// Core
import { element } from 'prop-types'
// Styles
import Styles from './styles.module.scss'

export const Authorization = ({ children }) => {
  return <section className={Styles.auth}>{children}</section>
}

Authorization.propTypes = {
  children: element,
}
