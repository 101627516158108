// Core
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// Actions
import { authActions } from '../../actions'
// Styles
import Styles from './styles.module.scss'
// Images
import settings from '../../theme/assets/icons/settings.svg'
import logout from '../../theme/assets/icons/logout.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const UserMenu = ({ openSettings }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const user = useSelector((state) => state.auth.user)

  const [toggle, setToggle] = useState(false)

  useEffect(() => {
    i18n.addResourceBundle('en', 'user_menu', en)
    i18n.addResourceBundle('ar', 'user_menu', ar)
  }, [])

  const onSettingOpen = () => {
    setToggle(false)
    openSettings()
  }

  const onLogout = () => {
    if (user.token) {
      const data = {
        token: user.token,
      }

      dispatch(authActions.logoutUser(data))
    }
  }

  const arrowClass = !toggle ? Styles.btn_arrow : Styles.btn_arrow_open
  const dropdownList = !toggle ? Styles.dropdown_list : Styles.dropdown_list_open

  return (
    <div className={Styles.user_menu}>
      <div onClick={() => setToggle(!toggle)} className={Styles.dropdown_btn}>
        <span className={`${Styles.btn_title} ClarityMask`}>{user.user?.full_name ? user.user?.full_name : 'Full name'}</span>
        <span className={arrowClass} />
      </div>
      <ul className={dropdownList}>
        <li onClick={onSettingOpen} className={Styles.list_item}>
          <img src={settings} alt="settings image" />
          <span>{t('user_menu:settings')}</span>
        </li>
        <li onClick={onLogout} className={Styles.list_item}>
          <img src={logout} alt="settings image" />
          <span>{t('user_menu:log_out')}</span>
        </li>
      </ul>
    </div>
  )
}
