//Core
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Meeting SDK
import ZoomMtgEmbedded from '@zoom/meetingsdk/embedded'
//Actions
import { zoomActions } from '../../../../actions'
// Constants
import { meetingSdkKey } from '../../../../constants'

export const ZoomPopup = () => {
  const dispatch = useDispatch()

  const meetingInfo = useSelector((state) => state.zoom.meetingInfo)
  const user = useSelector((state) => state.auth.user)

  const { isSelectedOpenZoomModal } = useSelector((state) => state.zoom)

  const client = ZoomMtgEmbedded.createClient()
  let meetingSDKElement = document.getElementById('meetingSDKElement')

  useEffect(() => {
    if (isSelectedOpenZoomModal) {
      const dataForZoom = {
        id: isSelectedOpenZoomModal,
        token: user.token,
      }
      dispatch(zoomActions.getMeetingInformation(dataForZoom))
    }
  }, [isSelectedOpenZoomModal])

  useEffect(() => {
    if (client && meetingInfo) {
      const dataForJoin = {
        signature: meetingInfo.signature,
        sdkKey: meetingSdkKey,
        meetingNumber: meetingInfo.meeting_number,
        password: '',
        userName: meetingInfo.username,
        userEmail: meetingInfo.user_email,
      }

      if (meetingInfo.zak_token) {
        dataForJoin['zak'] = meetingInfo.zak_token
      }

      client
        .init({
          debug: true,
          leaveUrl: 'http://localhost:3000/admin_panel/advisor/parents',
          zoomAppRoot: meetingSDKElement,
          language: 'en-US',
          customize: {
            video: {
              defaultViewType: 'gallery',
              isResizable: true,
              viewSizes: {
                default: {
                  width: 300,
                  height: 300,
                },
              },
            },
          },
        })
        .then(() => {
          client
            .join(dataForJoin)
            .then(() => {
              console.log('joined successfully')
            })
            .catch((error) => {
              console.log('JOIN')
              console.log(error)
            })

          client.on('connection-change', (payload) => {
            console.log('EVENT PAYLOAD')
            console.log(payload)
            const { state } = payload
            if (state === 'Closed') {
              if (user?.user?.user_type !== 'advisor') {
                window.location.reload()
              }
            }
          })
        })
        .catch((error) => {
          console.log('INIT')
          console.log(error)
        })
    }
  }, [client, meetingInfo])

  return <div className={Styles.main} id="meetingSDKElement"></div>
}
