// Core
import { useSelector, useDispatch } from 'react-redux'
// Routes
import { useNavigate } from 'react-router-dom'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Actions
import { settingsActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Images
import closeIcon from '../../../theme/assets/icons/close_white.svg'
// Components
import { SaveButton, TextFieldPassword, Loader } from '../../GeneralComponents'

const schema = Yup.object().shape({
  old_password: Yup.string()
    .required('Please enter password')
    .min(7, 'Password should contain more than 7 symbols')
    .max(30, 'Password should contain no more than 30 symbols')
    .matches(
      /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
      'Include at least 1 digit number, 1 lowercase and 1 uppercase letter',
    ),
  new_password: Yup.string()
    .required('Please enter password')
    .min(7, 'Password should contain more than 7 symbols')
    .max(30, 'Password should contain no more than 30 symbols')
    .matches(
      /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
      'Include at least 1 digit number, 1 lowercase and 1 uppercase letter',
    ),
  repeat_password: Yup.string()
    .required('Please repeat password')
    .min(7, 'Password should contain more than 7 symbols')
    .max(30, 'Password should contain no more than 30 symbols')
    .matches(
      /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
      'Include at least 1 digit number, 1 lowercase and 1 uppercase letter',
    )
    .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
})

export const ChangePassword = () => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.general.loading)
  const user = useSelector((state) => state.auth.user)

  const navigate = useNavigate()

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      old_password: '',
      new_password: '',
      repeat_password: '',
    },
  })

  const onFormSubmit = (values) => {
    if (user.token) {
      const data = {
        id: user?.user?.id,
        update: {
          password: values.new_password,
          old_password: values.old_password,
        },
        token: user.token,
      }

      dispatch(settingsActions.changeUserPassword(data))
    }
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className={Styles.popup_container}>
        <h3 className={Styles.info_caption}>
          <span>{'Settings - Change password'}</span>
          <img className={Styles.close_btn} src={closeIcon} onClick={() => navigate(-1)} alt="close" />
        </h3>
        <div className={Styles.info_content}>
          <TextFieldPassword
            label={'Current password'}
            class={Styles.form_item}
            placeholder={'Enter current password'}
            error={errors?.old_password}
            register={register('old_password')}
          />
          <TextFieldPassword label={'New Password'} class={Styles.form_item} placeholder={'New Password'} error={errors?.new_password} register={register('new_password')} />
          <TextFieldPassword
            label={'Confirm Password'}
            class={Styles.form_item}
            placeholder={'Confirm Password'}
            error={errors?.repeat_password}
            register={register('repeat_password')}
          />
        </div>
        <div className={Styles.btn_container}>{loading ? <Loader class={Styles.loader} /> : <SaveButton title={'Submit'} isValid={isValid} />}</div>
      </div>
    </form>
  )
}
