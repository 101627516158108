/**
 * @fileoverview Redux slice for authentication-related state management
 * @module authSlice
 */

// Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Authentication slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {string} initialState.userType - The type of user (e.g., Parent, Therapist).
 * @property {Object} initialState.therapistFullData - Full data of the therapist.
 * @property {string} initialState.file_upload_token - Token for file uploads.
 * @property {Array} initialState.documentsForRegistartion - List of documents for registration.
 * @property {string} initialState.therapistEmail - Email of the therapist.
 * @property {Object} initialState.user - User information.
 * @property {boolean} initialState.isAuth - Authentication status of the user.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setTherapistFullData - Sets the full data of the therapist.
 * @property {Function} reducers.setUserType - Sets the type of user.
 * @property {Function} reducers.setUser - Sets the user information.
 * @property {Function} reducers.setIsAuth - Sets the authentication status of the user.
 * @property {Function} reducers.setDocumentsForRegistration - Sets the list of documents for registration.
 * @property {Function} reducers.setFileUploadToken - Sets the file upload token.
 * @property {Function} reducers.setTherapistEmail - Sets the email of the therapist.
 */

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    userType: 'Parent',
    therapistFullData: {},
    file_upload_token: '',
    documentsForRegistartion: [],
    therapistEmail: '',
    user: {},
    isAuth: false,
  },
  reducers: {
    /**
     * Sets the full data of the therapist.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new full data of the therapist.
     */
    setTherapistFullData: (state, action) => {
      state.therapistFullData = action.payload
    },
    /**
     * Sets the type of user.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new user type.
     */
    setUserType: (state, action) => {
      state.userType = action.payload
    },
    /**
     * Sets the user information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new user information.
     */
    setUser: (state, action) => {
      state.user = action.payload
    },
    /**
     * Sets the authentication status of the user.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {boolean} action.payload - The new authentication status.
     */
    setIsAuth: (state, action) => {
      state.isAuth = action.payload
    },
    /**
     * Sets the list of documents for registration.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of documents for registration.
     */
    setDocumentsForRegistration: (state, action) => {
      state.documentsForRegistartion = action.payload
    },
    /**
     * Sets the file upload token.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new file upload token.
     */
    setFileUploadToken: (state, action) => {
      state.file_upload_token = action.payload
    },
    /**
     * Sets the email of the therapist.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new email of the therapist.
     */
    setTherapistEmail: (state, action) => {
      state.therapistEmail = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setTherapistFullData - Action creator for setting the full data of the therapist.
 * @property {Function} setUserType - Action creator for setting the type of user.
 * @property {Function} setUser - Action creator for setting the user information.
 * @property {Function} setIsAuth - Action creator for setting the authentication status.
 * @property {Function} setDocumentsForRegistration - Action creator for setting the list of documents for registration.
 * @property {Function} setFileUploadToken - Action creator for setting the file upload token.
 * @property {Function} setTherapistEmail - Action creator for setting the email of the therapist.
 */

export const { setTherapistFullData, setUser, setUserType, setIsAuth, setDocumentsForRegistration, setFileUploadToken, setTherapistEmail } = authSlice.actions

export default authSlice.reducer
