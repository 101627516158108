import { Error404 } from '../../components'
import { NewLoader } from '../../components/GeneralComponents/NewLoader'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { useRouteError } from 'react-router-dom'

export const Error404Page = () => {
  // const user = useSelector((state) => state.auth.user)
  // if (!user?.token) {
  //   ;<Navigate to={'/login'} />
  // }

  return <Error404 />
}
