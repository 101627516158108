//Core
import { useSelector } from 'react-redux'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as EditIcon } from '../../../theme/assets/icons/edit_icon.svg'
import { ReactComponent as ClipIcon } from '../../../theme/assets/icons/paper_clip_blue_icon.svg'
//Utils
import { returnCountry, returnLanguagesFromArray, returnEducationalLevelForClient, returnCurrentyActivityForClient, convertBytes } from '../../../utils'

export const SummaryTherapistRegistration = ({ setBlockNumber, setDocumentsType }) => {
  const therapistInfo = useSelector((state) => state.auth.therapistFullData)
  const documents = useSelector((state) => state.auth.documentsForRegistartion)

  return (
    <div className={Styles.main_block}>
      <div className={Styles.block}>
        <div className={Styles.block__header}>
          <p>Personal info</p>
          <EditIcon onClick={() => setBlockNumber(1)} />
        </div>
        <div className={Styles.info_container}>
          <div className={Styles.info_container__block}>
            <div className={Styles.info_container__block__item}>
              <span>Full name</span>
              <p>{therapistInfo?.full_name}</p>
            </div>
            <div className={Styles.info_container__block__item}>
              <span>Birthday</span>
              <p>{therapistInfo?.birthday}</p>
            </div>
          </div>
          <div className={Styles.info_container__block}>
            <div className={Styles.info_container__block__item}>
              <span>Country</span>
              <p>{returnCountry(therapistInfo?.country)}</p>
            </div>
            <div className={Styles.info_container__block__item}>
              <span>Language</span>
              <p>{therapistInfo?.languages?.map((lang) => returnLanguagesFromArray(lang))?.join(', ')}</p>
            </div>
          </div>
          <div className={Styles.info_container__block}>
            <div className={Styles.info_container__block__item}>
              <span>Email</span>
              <p>{therapistInfo?.email}</p>
            </div>
            <div className={Styles.info_container__block__item}>
              <span>Phone number</span>
              <p>{therapistInfo?.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.block}>
        <div className={Styles.block__header}>
          <p>Professional info</p>
          <EditIcon onClick={() => setBlockNumber(2)} />
        </div>
        <div className={Styles.info_container}>
          <div className={Styles.info_container__item}>
            <span>Professional qualification</span>
            <p>{therapistInfo?.professional_qualities?.join(', ')}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Years of experience</span>
            <p>{therapistInfo?.experience}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Educational degree</span>
            <p>{returnEducationalLevelForClient(therapistInfo?.education_level)}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Licensing board you belong to</span>
            <p>{therapistInfo?.licence?.join(', ')}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Areas of expertise</span>
            <p>{therapistInfo?.expertise?.join(', ')}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>In which facility do you currently provide clinical services?</span>
            <p>{therapistInfo?.clinical_services_provided?.join(', ')}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Do you currently actively provide clinical services?</span>
            <p>{returnCurrentyActivityForClient(therapistInfo?.current_status)}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>Which age groups do you currently work with</span>
            <p>{therapistInfo?.age_groups_served}</p>
          </div>
          <div className={Styles.info_container__item}>
            <span>What would the main advantages of joining Numuw for you?</span>
            <p>{therapistInfo?.job_advantages}</p>
          </div>
        </div>
      </div>
      <div className={Styles.block}>
        <div className={Styles.block__header}>
          <p>Uploaded documents</p>
          <EditIcon
            onClick={() => {
              setDocumentsType('personal')
              setBlockNumber(4)
            }}
          />
        </div>
        <div className={Styles.info_container}>
          <div className={Styles.info_container__documents_block}>
            <p>Personal</p>
            <div className={Styles.info_container__documents_block__container}>
              {documents
                ?.filter((document) => document.type === 'personal')
                ?.map((document) => {
                  return (
                    <div className={Styles.info_container__documents_block__item}>
                      <ClipIcon />
                      <p>{document?.file?.name}</p>
                      <span>({convertBytes(document?.file?.size)})</span>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={Styles.info_container__documents_block}>
            <p>Professional</p>
            <div className={Styles.info_container__documents_block__container}>
              {documents
                ?.filter((document) => document.type === 'professional')
                ?.map((document) => {
                  return (
                    <div className={Styles.info_container__documents_block__item}>
                      <ClipIcon />
                      <p>{document?.file?.name}</p>
                      <span>({convertBytes(document?.file?.size)})</span>
                    </div>
                  )
                })}
            </div>
          </div>
          <div className={Styles.info_container__documents_block}>
            <p>Educational</p>
            <div className={Styles.info_container__documents_block__container}>
              {documents
                ?.filter((document) => document.type === 'educational')
                ?.map((document) => {
                  return (
                    <div className={Styles.info_container__documents_block__item}>
                      <ClipIcon />
                      <p>{document?.file?.name}</p>
                      <span>({convertBytes(document?.file?.size)})</span>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
