//Utils
import { AsYouType } from "libphonenumber-js"
//Core
import { useState } from "react"
//Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'


export const PhoneNumberInput = (props) => {
  const [phoneValue, setPhoneValue] = useState(props?.value || '')

  const phoneFormater = new AsYouType;
  return (
    <div className={`${Styles.phone_number_wrapper} ${props?.class}`}>
      {props.label && (
        <label>
          {props.label}
          <span>{props?.requiredIcon}</span>
        </label>
      )}
      <input
        value={phoneValue}
        autoComplete='off'
        type={props.type}
        autoFocus={props?.autoFocus}
        className={props?.inputClass}
        placeholder={props?.placeholder}
        disabled={props?.disabled}
        {...props.register}
        onChange={(e) => {
          let value = e.target.value

          if (!value.startsWith("+")) {
            value = "+" + value;
          }

          const formattedValue = phoneFormater.input(value);

          setPhoneValue(formattedValue);
          props?.setValue('phone_number', formattedValue, { shouldValidate: true })

        }}
      />
      {props?.hintMessage && <span className={Styles.hint_message}>{props?.hintMessage}</span>}
      {props?.error && <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
        {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
        <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
      </div>}
    </div>
  )
}