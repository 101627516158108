// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const parentApi = {
  getTherapyInfo: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/therapy/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getOneTherapist: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/therapists/${data.id}/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      params: {
        profile_id: data?.profile_id,
      },
    })
  },
  declineTherapist: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/screenings/${data.id}/decline/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  acceptTherapist: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/screenings/${data.id}/accept/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getParentHistory: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/profiles/${data.id}/history/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getProfilesForChat: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/profiles/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
}
