// Components
import { Authorization, SignIn } from '../../components'

export const SignInPage = () => {
  return (
    <Authorization>
      <SignIn />
    </Authorization>
  )
}
