//Core
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
//Styles
import Styles from './Styles.module.scss'
// Data
import {
  mandatory_questions_parent_onboarding,
  parent_onboarding_report_family_dynamics,
  parent_onboarding_report_parental_wellbeing,
  parent_onboarding_report_questionnaire,
} from '../../../../data'
import { MAIN_STATEMENTS_ANSWERS, ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE } from '../../../../data/enums'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
import { ReactComponent as ClockImage } from '../../../../theme/assets/images/clock_image.svg'
// Utils
import { deleteCookie, deleteLocalStorage, getLocalStorage, setLocalStorage } from '../../../../utils'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { returnParagraphForReport, returnParagraphForNoReport } from './locale/locale.functions'

export const ParentOnboardingReport = ({ setOnboardingStep, isAccountExists }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const [childName, setChildName] = useState('')
  const [childInformation, setChildInformarion] = useState({})
  const [questionnaire, setQuestionnaire] = useState([])
  const [familyDynamics, setFamilyDynamics] = useState([])
  const [parentalWellbeing, setParentalWellbeing] = useState([])

  const [familyDynamicsReport, setFamilyDynamicsReport] = useState({})
  const [parentalWellbeingReport, setParentalWellbeingReport] = useState({})

  const [reportLoading, setReportLoading] = useState(false)
  const [isReportExist, setIsReportExist] = useState(true)

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'report', en)
    i18n.addResourceBundle('ar', 'report', ar)
  }, [])

  useEffect(() => {
    const childInformation = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    setChildInformarion(childInformation)
    setChildName(childInformation.child_info?.child_name)
    setReportLoading(childInformation.report_loading)

    setTimeout(() => {
      setReportLoading(false)
      childInformation.report_loading = false
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(childInformation))
    }, 3000)

    for (let i = 0; i < mandatory_questions_parent_onboarding?.length; i++) {
      const questionnairePart = JSON.parse(getLocalStorage(`${PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE}_${i}`))
      setQuestionnaire((prev) => [...prev, questionnairePart])
    }

    setFamilyDynamics(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)))
    setParentalWellbeing(JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)))
  }, [])

  useEffect(() => {
    setFamilyDynamicsReport(parent_onboarding_report_family_dynamics(childName, familyDynamics))
    setParentalWellbeingReport(parent_onboarding_report_parental_wellbeing(childName, parentalWellbeing))
  }, [familyDynamics, childName, parentalWellbeing])

  useEffect(() => {
    const sectionWithYesAnswer = questionnaire?.find((section) => section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0])
    if (!sectionWithYesAnswer && !parentalWellbeingReport && !familyDynamicsReport) {
      setIsReportExist(false)
    } else {
      setIsReportExist(true)
    }
  }, [familyDynamicsReport, parentalWellbeingReport, questionnaire])

  const handleGoBack = () => {
    const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
    const theLastAnsweredQuestionParentalWellbeing = parentalWellbeing?.find((question) => question?.answer !== SKIPPED_VALUE)
    if (theLastAnsweredQuestionParentalWellbeing) {
      mainLocalStorage.parental_wellbeing_read_view = theLastAnsweredQuestionParentalWellbeing?.id
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING)
    } else {
      mainLocalStorage.parental_wellbeing_read_view = null
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
    }
    setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
  }

  const handleGoToAccountCreating = () => {
    if (isAccountExists) {
      // TODO: API call (create child profile)
      const finalObject = {
        payload: {
          child_info: {
            ...childInformation?.child_info,
          },
          questionnaire,
          family_dynamics: familyDynamics,
          parental_wellbeing: parentalWellbeing,
        },
        action: () => {
          deleteCookie(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING)
          deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS)
          deleteLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENTAL_WELLBEING)
          // for (let i = 0; i < mandatory_questions_parent_onboarding.length; i++) {
          //   deleteLocalStorage(`${PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE}_${i}`)
          // }
        },
      }

      console.log(finalObject)
    } else {
      setOnboardingStep(ONBOARDING_STEP.FORM_REGISTRATION)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.FORM_REGISTRATION
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
    }
  }
  return (
    <div className={Styles.main_container}>
      {!reportLoading && (
        <>
          <div className={Styles.main_container__header}>
            <h3>{t('report:header')}</h3>
            <div onClick={handleGoToAccountCreating} className={Styles.main_container__header__btn}>
              {t('report:call_to_action')}
            </div>
            <div className={Styles.main_container__header__disclamer}>
              <span>{t('report:disclaimer:header')}</span> {t('report:disclaimer:paragraph')}
            </div>
            {isReportExist && (
              <h6 className="ClarityMask" style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>
                {returnParagraphForReport(childName)?.[selectedLanguage]}
              </h6>
            )}
          </div>
          {isReportExist && (
            <div className={`${Styles.main_container__content} ClarityMask`}>
              {questionnaire?.map((section) => {
                const sectionSelector = `${PARENT_ONBOARDING_COOKIES_NAME.QUESTIONNAIRE}_${section?.id}`
                if (section?.main_statement_answer === MAIN_STATEMENTS_ANSWERS?.[0]) {
                  return (
                    <div style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }} className={Styles.main_container__content__item} key={section?.id}>
                      <h6>{parent_onboarding_report_questionnaire(childName)[sectionSelector]?.section_name?.[selectedLanguage]}</h6>
                      <p>{parent_onboarding_report_questionnaire(childName)[sectionSelector]?.feedback?.[selectedLanguage]}</p>
                    </div>
                  )
                }
              })}
              {familyDynamicsReport?.section_name && (
                <div className={Styles.main_container__content__item}>
                  <h6>{familyDynamicsReport?.section_name?.[selectedLanguage]}</h6>
                  <p>{familyDynamicsReport?.feedback?.[selectedLanguage]}</p>
                </div>
              )}
              {parentalWellbeingReport?.section_name && (
                <div className={Styles.main_container__content__item}>
                  <h6>{parentalWellbeingReport?.section_name?.[selectedLanguage]}</h6>
                  <p>{parentalWellbeingReport?.feedback?.[selectedLanguage]}</p>
                </div>
              )}
            </div>
          )}
          {!isReportExist && (
            <div className={Styles.main_container__content}>
              <h5 style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>{t('report:no_report:header')}</h5>
              <p className="ClarityMask" style={{ direction: selectedLanguage === 'en' ? 'ltr' : 'rtl' }}>
                {returnParagraphForNoReport(childName)?.[selectedLanguage]}
              </p>
              <span>{t('report:no_report:call_to_action')}</span>
            </div>
          )}
          <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoBack} className={Styles.main_container__footer}>
            {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />} {t('report:footer:back')}
          </div>
        </>
      )}
      {reportLoading && (
        <div className={Styles.loading}>
          <ClockImage />
          <h5>{t('report:analyzing')}</h5>
        </div>
      )}
    </div>
  )
}
