/**
 * @fileoverview Redux slice for parent-related state management
 * @module parentSlice
 */

//Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Parent slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Object} initialState.childInfo - Information about the child.
 * @property {Array} initialState.assignedTherapists - List of therapists assigned to the child.
 * @property {Array} initialState.historyRecords - List of sessions related to the child.
 * @property {Object} initialState.currentTherapist - The current therapist's details.
 * @property {Object} initialState.assignedAdvisor - The advisor assigned to the child.
 * @property {Array} initialState.acceptedTherapists - List of therapists accepted by the parent.
 * @property {Object} initialState.freeConsultation - Information about free consultation.
 * @property {Array} initialState.childNotes - Notes related to the child.
 * @property {Array} initialState.allProfiles - List of all profiles.
 * @property {number|null} initialState.profileId - The ID of the current profile.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setChild - Sets the child information.
 * @property {Function} reducers.setAssignedTherapists - Sets the assigned therapists.
 * @property {Function} reducers.setHistoryRecords - Sets the sessions history.
 * @property {Function} reducers.setCurrentTherapist - Sets the current therapist.
 * @property {Function} reducers.setAssignedAdvisor - Sets the assigned advisor.
 * @property {Function} reducers.setAcceptedTherapists - Sets the accepted therapists.
 * @property {Function} reducers.setChildNotes - Sets the child notes.
 * @property {Function} reducers.setAllProfiles - Sets all profiles.
 * @property {Function} reducers.setFreeConsultation - Sets the free consultation information.
 * @property {Function} reducers.setProfileId - Sets the profile ID.
 */
export const parentSlice = createSlice({
  name: 'parent',
  initialState: {
    childInfo: {},
    assignedTherapists: [],
    historyRecords: null,
    currentTherapist: {},
    assignedAdvisor: {},
    acceptedTherapists: [],
    freeConsultation: null,
    childNotes: [],
    allProfiles: [],
    profileId: null,
  },
  reducers: {
    /**
     * Sets the child information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The child information.
     */
    setChild: (state, action) => {
      state.childInfo = action.payload
    },
    /**
     * Sets the assigned therapists.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The list of assigned therapists.
     */
    setAssignedTherapists: (state, action) => {
      state.assignedTherapists = action.payload
    },
    /**
     * Sets the history records.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The list of history records.
     */
    setHistoryRecords: (state, action) => {
      state.historyRecords = action.payload
    },
    /**
     * Sets the current therapist.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The current therapist information.
     */
    setCurrentTherapist: (state, action) => {
      state.currentTherapist = action.payload
    },
    /**
     * Sets the assigned advisor.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The assigned advisor information.
     */
    setAssignedAdvisor: (state, action) => {
      state.assignedAdvisor = action.payload
    },
    /**
     * Sets the accepted therapists.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The list of accepted therapists.
     */
    setAcceptedTherapists: (state, action) => {
      state.acceptedTherapists = action.payload
    },
    /**
     * Sets the child notes.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The list of child notes.
     */
    setChildNotes: (state, action) => {
      state.childNotes = action.payload
    },
    /**
     * Sets all profiles.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The list of all profiles.
     */
    setAllProfiles: (state, action) => {
      state.allProfiles = action.payload
    },
    /**
     * Sets the free consultation information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The free consultation information.
     */
    setFreeConsultation: (state, action) => {
      state.freeConsultation = action.payload
    },
    /**
     * Sets the profile ID.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {number|null} action.payload - The profile ID.
     */
    setProfileId: (state, action) => {
      state.profileId = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setChild - Action creator for setting the child information.
 * @property {Function} setAssignedTherapists - Action creator for setting the assigned therapists.
 * @property {Function} setHistoryRecords - Action creator for setting the history records.
 * @property {Function} setCurrentTherapist - Action creator for setting the current therapist.
 * @property {Function} setAssignedAdvisor - Action creator for setting the assigned advisor.
 * @property {Function} setAcceptedTherapists - Action creator for setting the accepted therapists.
 * @property {Function} setChildNotes - Action creator for setting the child notes.
 * @property {Function} setAllProfiles - Action creator for setting all profiles.
 * @property {Function} setFreeConsultation - Action creator for setting the free consultation information.
 * @property {Function} setProfileId - Action creator for setting the profile ID.
 */

export const {
  setChild,
  setAssignedTherapists,
  setHistoryRecords,
  setCurrentTherapist,
  setAssignedAdvisor,
  setAcceptedTherapists,
  setChildNotes,
  setAllProfiles,
  setFreeConsultation,
  setProfileId,
} = parentSlice.actions

export default parentSlice.reducer
