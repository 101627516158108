/* eslint-disable no-useless-escape */
// Core
import { useDispatch, useSelector } from 'react-redux'
import { string } from 'prop-types'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Routing
import { Link } from 'react-router-dom'
// Actions
import { authActions } from '../../../actions'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, TextFieldPassword, SaveButton, Loader } from '../../GeneralComponents'

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com'),
  password: Yup.string().required('Please enter password'),
})

export const AdminSignIn = (props) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.general.loading)
  const error = useSelector((state) => state.general.error)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const onFormSubmit = (data) => {
    dispatch(authActions.loginUser(data))
  }

  return (
    <section className={Styles.signIn_form}>
      <div className={Styles.form_container}>
        <h3 className={Styles.signIn_caption}>{props.title}</h3>
        {error && (
          <div className={Styles.signIn_error}>
            <span />
            <span>{error}</span>
          </div>
        )}
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TextField label={'Email address'} requiredIcon={' *'} class={Styles.form_item} placeholder={'Enter your email'} error={errors?.email} register={register('email')} />
          <TextFieldPassword
            label={'Password'}
            requiredIcon={' *'}
            class={Styles.form_item}
            placeholder={'Enter your password'}
            error={errors?.password}
            register={register('password')}
          />
          <div className={Styles.message_wrap}>
            <Link to="/login/pass-recovery" className={Styles.message}>
              {'Forgot password?'}
            </Link>
          </div>
          {loading ? <Loader class={Styles.loader} /> : <SaveButton class={Styles.login_btn} isValid={isValid} title={'Log in'} />}
        </form>
      </div>
    </section>
  )
}

AdminSignIn.propTypes = {
  title: string,
}
