// Constants
import axios from 'axios'
import { apiPath } from '../constants'

export const authApi = {
  signupTherapist: (data) => {
    return fetch(`${apiPath}/therapists/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.data),
    })
  },
  signupUpdateAdvisor: (data) => {
    return fetch(`${apiPath}/advisors/finish-registration/${data.token}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data.update),
    })
  },
  verifyEmail: (data) => {
    return fetch(`${apiPath}/api-token-auth/resend-verify-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  confirmEmail: (data) => {
    return fetch(`${apiPath}/api-token-auth/verify-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  checkUserEmail: (data) => {
    return fetch(`${apiPath}/users/check-mail/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  loginUser: (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/api-token-auth/login/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
  },
  recoveryPassword: (data) => {
    return fetch(`${apiPath}/api-token-auth/send-reset-password-email/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  resetPasswordValidToken: (data) => {
    return fetch(`${apiPath}/api-token-auth/reset-password/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  resetPassword: (data) => {
    return fetch(`${apiPath}/api-token-auth/reset-password/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  logoutUser: (data) => {
    return fetch(`${apiPath}/api-token-auth/logout/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      body: JSON.stringify(data),
    })
  },
  getUser: (data) => {
    return fetch(`${apiPath}/users/${data.user.id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  signupParent: (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/landing/submit-questionnaire/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data),
    })
  },
}
