//Core
import React from 'react'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'

const DetailsNav = ({ onClose }) => {
  const handleClose = () => onClose()

  return (
    <div className={Styles.details_nav}>
      <div className={Styles.details_content}>
        <div className={Styles.details_back} onClick={handleClose}>
          <ArrowLeftIcon />
          <div className={Styles.back}>Back</div>
        </div>
        <div className={Styles.info}>Info</div>
      </div>
    </div>
  )
}

export default DetailsNav
