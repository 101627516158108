//Styles
import Styles from './styles.module.scss'
//MUI
import { Modal } from '@mui/material'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close.svg'

export const DocumentModal = ({ open, onClose, document }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <CloseIcon className={Styles.icon} onClick={onClose} />
        </div>
        <embed src={document} className={Styles.embed} />
      </div>
    </Modal>
  )
}