// Core
import { string, func, array, object, bool, shape } from 'prop-types'
// Instruments
import { Controller } from 'react-hook-form'
// import ShortUniqueId from 'https://esm.sh/short-unique-id';
// Mui
import { Radio, FormControlLabel, RadioGroup } from '@mui/material'
// Styles
import Styles from './styles.module.scss'

export const RadioGroupField = (props) => {
  // const uid = new ShortUniqueId();
  const returnData = (event) => {
    props?.onChange(event, props.radioButtons, props.id)
  }

  if (!props.name || !props.radioButtons || !props.control) return null

  return (
    <Controller
      defaultValue=""
      name={props.name}
      control={props.control}
      // key={uid.stamp(32)}
      render={({ field }) => (
        <div className={props?.mainClass}>
          <h6 id={props?.id} className={`${Styles.radio_label} ${props?.classLabel}`}>
            {props?.radioLabel}
            <span>{props?.requiredIcon}</span>
          </h6>

          <RadioGroup value={props?.defaultValue || ''} sx={{ paddingBottom: '20px' }} {...field} className={props?.radioClass}>
            {props.radioButtons.map((item, index) => (
              <FormControlLabel
                key={item.id}
                value={item.title || props?.defaultValue}
                disabled={props?.disabled}
                control={
                  <Radio
                    id={`${index}`}
                    sx={{
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: props.color ? props.color : '#8450A0',
                      },
                    }}
                  />
                }
                label={item.label || item.title}
                onChange={returnData}
                className={`${Styles.radio_wrapper} ${props?.class}`}
              />
            ))}
          </RadioGroup>
          <div className={`${Styles.error_radio} ${props.classError}`}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
        </div>
      )}
    />
  )
}

RadioGroupField.propTypes = {
  onChange: func,
  radioButtons: array,
  id: string,
  name: string,
  control: object,
  classLabel: string,
  radioLabel: string,
  requiredIcon: string,
  radioClass: string,
  disabled: bool,
  color: string,
  class: string,
  classError: string,
  error: shape({
    message: string,
  }),
}
