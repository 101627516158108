//Utils
import Countdown from 'react-countdown'
//Styles
import Styles from './styles.module.scss'
//Core
import { useState, useEffect } from 'react'
//Utils
import dayjs from 'dayjs'

const CountdownComponent = ({ creationTimestamp, onComplete }) => {

  const minutes = process.env.REACT_APP_PAYMENT_EXPIRATION_TIME;

  const targetDate = dayjs(creationTimestamp).add(minutes || 15, 'minute').valueOf();
  const now = dayjs().valueOf();
  const isTimerNeeded = now < targetDate;

  const [isCompleted, setIsCompleted] = useState(isTimerNeeded);

  const renderer = ({ minutes, seconds }) => {
    let formatedSeconds;

    if (seconds < 10) {
      formatedSeconds = `0${seconds}`
    } else {
      formatedSeconds = seconds
    }

    return (
      <span >{minutes}:{formatedSeconds}</span>
    )
  };

  const handleCompleteTimer = () => {
    setIsCompleted(false)
    if (onComplete) onComplete()
  }

  useEffect(() => {
    if (!isTimerNeeded) {
      handleCompleteTimer();
    }
  }, [isTimerNeeded]);

  if(!isCompleted) return null

  return (
      <div className={Styles.main_container}>
        <div className={Styles.main_container__time_container}>
          <Countdown
            date={targetDate}
            renderer={renderer}
            onComplete={handleCompleteTimer}
          />
        </div>
      </div>
  );
}

export { CountdownComponent };