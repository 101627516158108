// Core
import React from 'react'
import { array } from 'prop-types'
// Routing
// eslint-disable-next-line no-unused-vars
import { Navigate } from 'react-router-dom'
// Styles
// eslint-disable-next-line no-unused-vars
import Styles from './styles.module.scss'
// Images
// eslint-disable-next-line no-unused-vars
import error_404 from '../../theme/assets/images/error_404.png'
// Components
// eslint-disable-next-line no-unused-vars
import { HeaderSection } from '../GeneralComponents'

export default class Catcher extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: false }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidCatch(error, stack) {
    this.setState({
      error: true,
    })
  }

  render() {
    const { error } = this.state
    const { children } = this.props

    if (error) {
      this.setState({ error: false })

      return <Navigate to={'/'} replace />
    }

    // if (children) {

    return children
    // }
  }
}