// Core
import { string, object, bool, shape } from 'prop-types'
// Instruments
import { Controller } from 'react-hook-form'
// Mui
import { Checkbox, FormControlLabel } from '@mui/material'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

export const CheckboxField = (props) => {
  if (!props.name || !props.control) return null

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <div>
            <FormControlLabel
              {...field}
              onChange={(event) => field.onChange(event.target.checked)}
              checked={field.value}
              disabled={props?.disabled}
              autoFocus={props?.autoFocus}
              control={
                <>
                  <Checkbox
                    onChange={(e) => {
                      props?.onCustomChange(e.target.checked)
                      field.onChange(e.target.checked)
                    }}
                    sx={{
                      color: '#D9D9D9',
                      '&.Mui-checked': {
                        color: props.color ? props.color : '#8450A0',
                      },
                    }}
                  />
                  {props?.children}
                </>
              }
              label={props?.label}
              className={`${Styles.checkbox_wrapper} ${props?.class}`}
            />
            {props?.error && (
              <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}

CheckboxField.propTypes = {
  name: string,
  control: object,
  label: string,
  disabled: bool,
  autoFocus: bool,
  color: string,
  class: string,
  error: shape({
    message: string,
  }),
}
