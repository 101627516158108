/**
 * @fileoverview Redux slice for therapist-related state management
 * @module therapistSlice
 */
//Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Therapist slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 *
 * @property {Array} initialState.allApplications - Information about all therapist's applications (before first session with a client).
 * @property {Object} initialState.oneApplication - Information about specific therapist's application (before first session with a client).
 * @property {Array} initialState.allPatients -  Information about all therapist's patients (after client's accepting).
 * @property {Array} initialState.history - Information about all sessions with a client.
 * @property {Array} initialState.documents - Information about therapist's documents (personal, professional, educational).
 *
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setAllApplications - Sets all therapist's applications.
 * @property {Function} reducers.setOneApplication - Sets specific therapist's application.
 * @property {Function} reducers.setAllPatients - Sets all therapist's patients.
 * @property {Function} reducers.setHistory - Sets all sessions with a client.
 * @property {Function} reducers.setDocuments - Sets therapist's documents.
 */
export const therapistSlice = createSlice({
  name: 'therapist',
  initialState: {
    allApplications: [],
    oneApplication: {},
    allPatients: [],
    history: null,
    documents: [],
  },
  reducers: {
    setAllApplications: (state, action) => {
      state.allApplications = action.payload
    },
    setOneApplication: (state, action) => {
      state.oneApplication = action.payload
    },
    setAllPatients: (state, action) => {
      state.allPatients = action.payload
    },
    setHistory: (state, action) => {
      state.history = action.payload
    },
    setDocuments: (state, action) => {
      state.documents = action.payload
    },
  },
})

export const { setAllApplications, setOneApplication, setAllPatients, setHistory, setDocuments } = therapistSlice.actions

export default therapistSlice.reducer
