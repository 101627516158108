// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const advisorOnboardingApi = {
  changeStatusApply: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/landing/consultations/${data.id}/in-a-meeting/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getAllApplies: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/landing/consultations/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getResults: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/landing/consultations/${data.meeting_id}/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  submitApply: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/landing/consultations/${data.meeting_id}/accept/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  declineApply: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/landing/consultations/${data.meeting_id}/decline/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getAllTherapists: async (data) => {
    return axios({
      url: `${apiPath}/therapists/all/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getAllParentsForAssign: async (data) => {
    return axios({
      url: `${apiPath}/initial-calls/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getParentInfoForAssign: async (data) => {
    return axios({
      url: `${apiPath}/initial-calls/${data.id}/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  assignTherapists: async (data) => {
    return axios({
      url: `${apiPath}/initial-calls/${data.id}/assign/`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getProfile: async (data) => {
    return axios({
      url: `${apiPath}/advisors/profiles/${data.profileId}/`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
}
