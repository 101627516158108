// Core
import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
// Components
import { ChannelsList } from '../ChannelsList'
import { MessagesHistory } from '../MessagesHistory'
import { ContactDetails } from '../ContactDetails'
import { Placeholder } from '../MessagesHistory/Placeholder'
// Actions
import { chatActions, parentActions } from '../../../../actions'
import { setAllChannels, setContactDetails } from '../../../../reducers/chatSlice'
// Styles
import Styles from './styles.module.scss'
import { DocumentModal } from '../../../GeneralComponents'
import SelectedTherapistChat from '../SelectedTherapistChat'
import DetailsNav from '../DetailsNav'
//Tools
import WebSocketService from '../../../../websocket'
//Sound
import soundUrl from '../../../../theme/assets/bell-sound.wav'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

/**
 * Represents a sound component for notifications.
 * @component
 * @param {Object} props - Component props
 * @param {boolean} props.play - Indicates if the notification sound should play
 * @param {Function} props.setPlayNotification - Function to control the notification sound state
 */
export const NotificationSound = ({ play, setPlayNotification }) => {
  const audioRef = useRef(null)

  useEffect(() => {
    if (play) {
      audioRef.current.play()
      setPlayNotification(false)
    }
  }, [play])

  return (
    <audio ref={audioRef}>
      <source src={soundUrl} type="audio/mpeg" />
    </audio>
  )
}

/**
 * Represents the Chat component.
 * @component
 */
export const Chat = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const chatSystem = useSelector((state) => state.chatSystem)
  const allProfiles = useSelector((state) => state.parent.allProfiles)

  const { profileId } = useParams()

  /**
   * Component state
   */
  const [webSocketInstance, setWebSocketInstance] = useState(null)

  const [selectedChatRoomId, setSelectedChatRoomId] = useState(undefined)
  const [selectedChatId, setSelectedChatId] = useState(undefined)
  const [selectedChannelId, setSelectedChannelId] = useState(undefined)
  const [selectedClientChannel, setSelectedClientChannel] = useState(undefined)

  const [currentProfile, setCurrentProfile] = useState({})

  const [selectedChat, setSelectedChat] = useState(undefined)
  const [documentFile, setDocumentFile] = useState(undefined)

  const [showContactDetails, setShowContactDetails] = useState(false)
  const [openDocumentPopup, setOpenDocumentPopup] = useState(false)
  const [isVisibleChannelList, setIsVisibleChatList] = useState(false)

  const [isNotificationInit, setIsNotificationInit] = useState(true)

  const [palyNotification, setPalyNotification] = useState(false)

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'chat', en)
    i18n.addResourceBundle('ar', 'chat', ar)
  }, [])

  /**
   * Updates channel information based on incoming notifications.
   * @param {Object} notification - The notification object received
   */
  const handleUpdateChatsInformation = (notification) => {
    let channelIndexToUpdate
    let chatIndexToUpdate

    chatSystem?.allChannels?.forEach((channel, channelIndex) => {
      const foundChatIndex = channel?.chats?.findIndex((chat) => chat?.chat_id === notification?.chat_id)
      if (foundChatIndex !== -1) {
        channelIndexToUpdate = channelIndex
        chatIndexToUpdate = foundChatIndex
        return
      }
    })

    if (channelIndexToUpdate !== -1 && chatIndexToUpdate !== -1) {
      const newAllChannels = JSON.parse(JSON.stringify(chatSystem.allChannels))

      newAllChannels[channelIndexToUpdate].chats[chatIndexToUpdate] = {
        ...newAllChannels[channelIndexToUpdate].chats[chatIndexToUpdate],
        ...notification,
      }

      newAllChannels[channelIndexToUpdate].participant = {
        ...newAllChannels[channelIndexToUpdate].participant,
        unread_messages_for_all_chats: notification.unread_messages_for_all_chats,
      }

      dispatch(setAllChannels(newAllChannels))
    }

    setPalyNotification(true)
  }

  /**
   * Initializes WebSocket connection for notifications and sets up callbacks.
   */
  useEffect(() => {
    const webSocket = new WebSocketService('notifications', 0, user)

    if (chatSystem?.allChannels?.length && isNotificationInit) {
      webSocket.setNotificationCallback((notification) => {
        handleUpdateChatsInformation(notification)
      })

      setWebSocketInstance(webSocket)
      setIsNotificationInit(false)
    }
  }, [chatSystem])

  /**
   * Connects WebSocket instance when initialized or updated.
   */
  useEffect(() => {
    if (webSocketInstance && webSocketInstance?.state !== 1) {
      webSocketInstance.connect()
    }
  }, [webSocketInstance])

  /**
   * Handles the selection of a chat room.
   * @param {string} room_id - The ID of the selected chat room
   * @param {string} chat_id - The ID of the selected chat
   * @param {string} channelId - The ID of the selected channel
   * @param {string} clientChannelId - The ID of the client channel
   */
  const handleChannelsListUpload = () => {
    if (user.token) {
      const data = {
        token: user.token,
      }

      if (user.user.user_type === 'parent') {
        data.id = profileId
        dispatch(chatActions.getAllChannelsClient(data))
        dispatch(parentActions.getProfilesForChat(data))
      } else {
        dispatch(chatActions.getAllChannels(data))
      }
    }
  }

  /**
   * Fetches all channels based on user token when user updates.
   */
  useEffect(() => {
    handleChannelsListUpload()
  }, [user])

  /**
   * Updates current profile information when `profileId` or `allProfiles` change.
   */
  useEffect(() => {
    const selectedProfile = allProfiles?.find((profile) => profile?.id === +profileId)
    setCurrentProfile(selectedProfile)
  }, [profileId, allProfiles])

  /**
   * Marks the selected chat messages as viewed.
   */
  const handleViewLastMessages = () => {
    if (user.token && selectedChatId) {
      const data = {
        token: user.token,
        chatId: selectedChatId,
      }
      dispatch(chatActions.viewedChat(data))
    }
  }

  /**
   * Opens contact details for the selected chat.
   */
  const handleOpenConatctDetails = () => {
    //API call
    const dataForContactDetails = {
      token: user.token,
      chatId: selectedChatId,
    }
    dispatch(chatActions.getContactDetails(dataForContactDetails))
    dispatch(chatActions.getChatAttachemnts(dataForContactDetails))
    setShowContactDetails(true)
  }

  /**
   * Opens the document popup for the selected file.
   * @param {Object} file - The file object to be displayed
   */
  const handleOpenDocumentPopup = (file) => {
    setOpenDocumentPopup(true)
    setDocumentFile(file)
  }

  /**
   * Closes the document popup.
   */
  const handleCloseDocumentPopup = () => {
    setOpenDocumentPopup(false)
  }

  /**
   * Handles re-fetching channels when `selectedChatRoomId` or `selectedChatId` change.
   */
  useEffect(() => {
    handleChannelsListUpload()
  }, [selectedChatRoomId, selectedChatId])

  /**
   * Handles the selection of a chat room.
   * @param {string} room_id - The ID of the selected chat room
   * @param {string} chat_id - The ID of the selected chat
   * @param {string} channelId - The ID of the selected channel
   * @param {string} clientChannelId - The ID of the client channel
   */
  const handleChatSelection = (room_id, chat_id, channelId, clientChannelId) => {
    const selectedChat = chatSystem.allChannels
      .flatMap((channel) =>
        channel.chats.map((chat) => ({
          ...chat,
          user_name: channel?.participant?.user_name,
          user_role: channel?.participant?.user_role,
          user_profile_pic: channel?.participant?.profile_pic,
        })),
      )
      .find((chat) => chat.chat_id === chat_id)
    setSelectedChat(selectedChat)
    setSelectedChannelId(channelId)
    setShowContactDetails(false)
    dispatch(setContactDetails({}))
    setSelectedClientChannel(clientChannelId)
    if (room_id && chat_id) {
      localStorage.setItem('room_id', room_id)
      setSelectedChatRoomId(room_id)
      setSelectedChatId(chat_id)
      setIsVisibleChatList(true)
    } else {
      setSelectedChatId(0)
      setSelectedChatRoomId(0)
    }
  }

  /**
   * Handles going back to all channels view.
   */
  const handleGoBackToAllChannels = () => {
    setSelectedClientChannel(null)
    setSelectedChatId(null)
    setSelectedChannelId(null)
    setIsVisibleChatList(false)
  }

  return (
    <div className={`${Styles.chat} ClarityMask`}>
      {showContactDetails && <DetailsNav onClose={setShowContactDetails} />}
      {chatSystem?.allChannels?.length ? (
        <>
          <div style={{ height: '100%' }} className={`${isVisibleChannelList && Styles.chat_channelList_container} ${Styles.channelList_left_block}`}>
            <ChannelsList
              currentUserId={user.user.id}
              currentUserType={user.user.user_type}
              channels={chatSystem.allChannels}
              channelsByType={chatSystem.channelsByType}
              onChatSelection={(room_id, chat_id, channel_id, clientChatId) => {
                handleChatSelection(room_id, chat_id, channel_id, clientChatId)
              }}
              selectedChatId={selectedChatId}
              selectedChannelId={selectedChannelId}
              selectedClientChannel={selectedClientChannel}
              currentProfile={currentProfile}
              allProfiles={allProfiles}
            />
          </div>
          {selectedChatRoomId ? (
            <div className={isVisibleChannelList ? Styles.chat_messagesHistory_container : Styles.chat_messagesHistory_base}>
              <MessagesHistory
                currentUserId={user.user.id}
                currentUserEmail={user.user.email}
                roomId={selectedChatRoomId || localStorage.getItem('room_id')}
                chatId={selectedChatId}
                currentChat={selectedChat}
                selectedChat={selectedChat}
                goBackToChatList={handleGoBackToAllChannels}
                onNewMessageSend={handleChannelsListUpload}
                onViewLastMessages={handleViewLastMessages}
                onChatDetailsOpenning={handleOpenConatctDetails}
              />
            </div>
          ) : (
            <Placeholder text={t('chat:select_chat')} />
          )}
        </>
      ) : (
        <Placeholder text={t('chat:no_chats')} />
      )}

      {showContactDetails && (
        <ContactDetails
          {...chatSystem.contactDetails}
          attachments={chatSystem.chatAttachments}
          onClose={() => setShowContactDetails(false)}
          currentUserType={user.user.user_type}
          openDocumentPopup={handleOpenDocumentPopup}
        />
      )}
      {openDocumentPopup && <DocumentModal open={openDocumentPopup} onClose={handleCloseDocumentPopup} document={documentFile} />}
      <NotificationSound play={palyNotification} setPlayNotification={setPalyNotification} />
    </div>
  )
}
