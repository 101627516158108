// Constants
import { apiPath } from '../constants'
import axios from 'axios'

export const adminApi = {
  getAllParents: (data) => {
    return fetch(`${apiPath}/parents/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getChildProfile: (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/profiles/${data.id}/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getChildHistory: (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/parents/profiles/${data.id}/history/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  provideDiscount: (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/payments/apply-discount/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getAllAdvisors: (data) => {
    return fetch(`${apiPath}/advisors/all/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },

  createAdvisor: (data) => {
    return fetch(`${apiPath}/advisors/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      body: JSON.stringify(data.credentials),
    })
  },

  resendAdvisor: (data) => {
    return fetch(`${apiPath}/advisors/resend-invitation/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      body: JSON.stringify(data.credentials),
    })
  },

  deleteAdvisor: (data) => {
    return fetch(`${apiPath}/advisors/${data.id}/delete/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${data.token}`,
      },
    })
  },

  setAPIKey: (data) => {
    return fetch(`${apiPath}/calcom/api-keys/create/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      body: JSON.stringify(data.info),
    })
  },
  approveTherapist: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/therapists/${data.id}/approve/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  declineTherapist: async (data) => {
    return axios({
      method: 'PATCH',
      url: `${apiPath}/therapists/${data.id}/decline/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  updateTherapistInfo: async (data) => {
    return axios({
      method: 'PUT',
      url: `${apiPath}/therapists/${data.id}/update/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
      data: JSON.stringify(data.payload),
    })
  },
  getAllMeetings: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/calcom/sessions/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  retryZoomMeeting: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/calcom/sessions/${data.id}/retry/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
}
