//Core
import { useSelector } from 'react-redux'
// Routing
import { NavLink } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as EnvelopIcon } from '../../../theme/assets/icons/envelop-animated-icon.svg'

export const TherapistNav = ({ isIconShow }) => {
  const profile = useSelector((state) => state.auth.user.profile)

  return (
    <div className={Styles.tabs}>
      <NavLink to="/profile/therapist/applications" className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
        <span>{'Applications'}</span>
      </NavLink>
      <NavLink to="/profile/therapist/patients" className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
        <span>{'My patients'}</span>
      </NavLink>
      <NavLink to={`/profile/therapist/chat`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
        <span>{'Chats'}</span>
        {isIconShow && <EnvelopIcon />}
      </NavLink>
    </div>
  )
}
