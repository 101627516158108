// Constants
import { apiPath } from '../constants'
// Instruments
import axios from 'axios'

export const chatApi = {
  getAllChannels: (data) => {
    return fetch(`${apiPath}/chats/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getAllChannelsClient: (data) => {
    return fetch(`${apiPath}/chats/profiles/${data.id}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getChannelsByType: (data) => {
    return fetch(`${apiPath}/chats/?user_type=${data.chatsType}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  archiveChat: (data) => {
    return fetch(`${apiPath}/chats/${data.chatId}/delete/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Token ${data.token}`,
      },
    })
  },
  viewedChat: (data) => {
    return fetch(`${apiPath}/chats/${data.chatId}/viewed/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getContactDetails: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/chats/${data.chatId}/contact/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
  getChatAttachemnts: async (data) => {
    return axios({
      method: 'GET',
      url: `${apiPath}/chats/${data.chatId}/attachments/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Token ${data.token}`,
      },
    })
  },
}
