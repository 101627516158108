//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close.svg'
import { ReactComponent as FileIcon } from '../../../../theme/assets/icons/file_new.svg'
//Core
import { useDispatch, useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
//Utils
import { getInitials, returnCountry, convertBytes } from '../../../../utils'
import { useTranslation } from 'react-i18next'
//Components
import { DocumentModal } from '../../../GeneralComponents'
//Actions
import { parentActions, therapistActions } from '../../../../actions'

/**
 * TherapistInfoModal component for displaying therapist information.
 * @param {object} props - Component props.
 * @param {boolean} props.open - Modal open state.
 * @param {Function} props.onClose - Function to close the modal.
 * @param {string} props.userType - Type of user ('parent' or 'therapist').
 * @param {string} props.therapistId - ID of the therapist.
 * @returns {JSX.Element} TherapistInfoModal component JSX.
 */
export const TherapistInfoModal = ({ open, onClose, userType, therapistId }) => {
  const dispatch = useDispatch()

  /**
   * User data fetched from Redux store.
   * @type {object}
   */
  const user = useSelector((state) => state.auth.user)

  /**
   * Therapist data fetched from Redux store.
   * @type {object}
   */
  const therapist = useSelector((state) => state.parent.currentTherapist)

  /**
   * Therapist documents fetched from Redux store.
   * @type {Array}
   */
  const documents = useSelector((state) => state.therapist.documents)

  /**
   * State for managing visibility of document preview modal.
   * @type {[boolean, function]}
   */
  const [isOpenDocumentPreview, setIsOpenDocumentPreview] = useState(false)

  /**
   * State for storing the path of the selected document to preview.
   * @type {[string, function]}
   */
  const [selectedDocumentPath, setSelectedDocumentPath] = useState('')

  /**
   * Effect to fetch therapist and documents data based on therapistId.
   */
  useEffect(() => {
    const data = {
      token: user?.token,
      id: therapistId,
    }

    if (user?.user?.user_type === 'parent') Object.assign(data, { profile_id: localStorage.getItem('lastSelectedProfile') })
    dispatch(parentActions.getOneTherapist(data))
    dispatch(therapistActions.getDocumentsForAdmins(data))
  }, [therapistId, user])

  /**
   * Opens the document preview modal with the selected document.
   * @param {string} documentPath - Path of the document to preview.
   */
  const handleOpenDocumentPreview = (documentPath) => {
    setSelectedDocumentPath(documentPath)
    setIsOpenDocumentPreview(true)
  }

  /**
   * Closes the document preview modal.
   */
  const handleCloseDocumentPreview = () => setIsOpenDocumentPreview(false)

  /**
   * Returns the degree level description based on abbreviation.
   * @param {string} degree - Abbreviation of the degree level.
   * @returns {string} Full description of the degree level.
   */
  const returnDegree = (degree) => {
    switch (degree) {
      case 'ml':
        return 'Masters Level'
      case 'bl':
        return 'Bachelors Level'
      case 'dl':
        return 'Doctoral Level'
      default:
        return '-'
    }
  }

  /**
   * Returns the clinical services status description based on abbreviation.
   * @param {string} clinicalServices - Abbreviation of the clinical services status.
   * @returns {string} Full description of the clinical services status.
   */
  const returnClinicalServices = (clinicalServices) => {
    switch (clinicalServices) {
      case 'pt':
        return 'Yes, part time'
      case 'ft':
        return 'Yes, full time'
      case 'na':
        return 'No, I am not active clinically'
      default:
        return '-'
    }
  }

  /**
   * Returns sorted documents grouped by type (personal, professional, educational).
   * @returns {object} Object containing arrays of sorted documents.
   */
  const returnSortedDocuments = () => {
    const sortedDocuments = {
      personal: [],
      professional: [],
      educational: [],
    }
    documents?.map((document) => {
      if (document.file_type === 'personal') {
        sortedDocuments.personal.push(document)
      } else if (document.file_type === 'professional') {
        sortedDocuments.professional.push(document)
      } else {
        sortedDocuments.educational.push(document)
      }
    })

    return sortedDocuments
  }

  return (
    <Modal open={open} onClose={onClose} className={Styles.modal}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.container_scroll}>
          <div className={Styles.main_info}>
            <div className={Styles.main_info__photo}>
              {therapist?.user?.profile_pic ? <img src={therapist?.user?.profile_pic} alt="therapist_photo" /> : getInitials(therapist?.user?.full_name)}
            </div>
            <div className={Styles.main_info__information}>
              <h5 className={Styles.main_info__title}>{therapist?.user?.full_name}</h5>
              <p>{therapist?.professional_qualities?.join(', ')}</p>
              <p className={Styles.main_info__information__about_me}>{therapist.bio}</p>
            </div>
          </div>
          {userType !== 'parent' && (
            <>
              <div className={Styles.personal_info}>
                <div className={Styles.personal_info__content}>
                  <div className={Styles.personal_info__content__item}>
                    <span>Country</span>
                    <>{returnCountry(therapist?.user?.country, Styles) || '-'}</>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>Timezone</span>
                    <p>{therapist?.user?.time_zone || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>Nationality</span>
                    <p>{therapist?.nationality || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>Language</span>
                    <p>{therapist?.languages?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.personal_info__content__item}>
                    <span>Age</span>
                    <p>{therapist?.age || '-'}</p>
                  </div>
                </div>
              </div>
              <div className={Styles.professional_info}>
                <h6>Professional info</h6>
                <div className={Styles.professional_info__content}>
                  <div className={Styles.professional_info__content__item}>
                    <span>Price for regular session</span>
                    <p>{`$${therapist?.session_price}` || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Years of experience</span>
                    <p>{therapist?.experience || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Educational degree</span>
                    <p>{returnDegree(therapist?.education_level) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Licensing</span>
                    <p>{therapist?.licence?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Areas of expertise</span>
                    <p>{therapist?.expertise?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>What would the main advantages of joining Numuw for you?</span>
                    <p>{therapist?.job_advantages || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>In which facility do you currently provide clinical services?</span>
                    <p>{therapist?.clinical_services_provided?.join('; ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Do you currently actively provide clinical services?</span>
                    <p>{returnClinicalServices(therapist?.current_status) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Which age groups do you currently work with</span>
                    <p>{therapist?.age_groups_served || '-'}</p>
                  </div>
                </div>
              </div>
              <div className={Styles.documents_info}>
                <h6>Professional qualification</h6>
                {returnSortedDocuments().personal.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>Personal</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().personal?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {returnSortedDocuments().professional.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>Professional</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().professional?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {returnSortedDocuments().educational.length !== 0 && (
                  <div className={Styles.documents_info__section}>
                    <p className={Styles.headline_files}>Educational</p>
                    <div className={Styles.documents_info__section__documents}>
                      {returnSortedDocuments().educational?.map((document) => {
                        return (
                          <div key={document.id} onClick={() => handleOpenDocumentPreview(document.file)} className={Styles.document_container}>
                            <div className={Styles.preview}>
                              <FileIcon />
                            </div>
                            <div className={Styles.text}>
                              <p className={Styles.name}>{document.filename}</p>
                              <span>({convertBytes(document.size)})</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
          {userType === 'parent' && (
            <>
              <div className={Styles.professional_info}>
                <div className={Styles.professional_info__content}>
                  <div className={Styles.professional_info__content__item}>
                    <span>Price for regular session</span>
                    <p>{therapist?.session_price === 0 ? 'Free' : `$${therapist?.session_price}`}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Years of experience</span>
                    <p>{therapist?.experience || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Language</span>
                    <p>{therapist?.languages?.join(', ') || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Educational degree</span>
                    <p>{returnDegree(therapist?.education_level) || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Licensing</span>
                    <p>{therapist?.licence || '-'}</p>
                  </div>
                  <div className={Styles.professional_info__content__item}>
                    <span>Areas of expertise</span>
                    <p>{therapist?.expertise?.join(', ') || '-'}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        {isOpenDocumentPreview && <DocumentModal open={isOpenDocumentPreview} onClose={handleCloseDocumentPreview} document={selectedDocumentPath} />}
      </div>
    </Modal>
  )
}
