//Core
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
//Actions
import { therapistActions } from '../../../actions/therapistActions'
import { setIsSelectedOpenZoomModal } from '../../../reducers/zoomSlice'
//Styles
import Styles from './styles.module.scss'
//Components
import { Table } from '../../Applies/Table'
import { Spinner } from '../../ChatSystem/Common/Spinner'
import { NoDataComponent, CustomPagination } from '../../GeneralComponents'
//MUI
import { InputBase } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../../theme/assets/icons/search_convex.svg'
//Utils
import { paginateArray } from '../../../utils'

/**
 * Patients component for managing therapist patients.
 * @returns {JSX.Element} Patients component.
 */
export const Patients = () => {
  const dispatch = useDispatch()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const allPatients = useSelector((state) => state.therapist.allPatients)
  const loading = useSelector((state) => state.general.loading)

  /**
   * Component state
   */
  const [patients, setPatients] = useState([])

  const [currentPage, setCurrentPage] = useState(1)

  /**
   * Effect to fetch all patients on initial load and when user token changes.
   */
  useEffect(() => {
    const data = {
      token: user.token,
    }
    dispatch(therapistActions.getAllPatients(data))
  }, [user.token])

  /**
   * Effect to paginate patients when allPatients state changes or currentPage changes.
   */
  useEffect(() => {
    const paginatedData = paginateArray(allPatients)
    if (paginatedData.length) {
      setPatients([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setPatients([])
    }
  }, [allPatients, currentPage])

  /**
   * Headers for the table
   */
  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF PROFILE', sorting: true },
    { text: 'PROBLEM', sorting: true },
    { text: 'BIRTHDAY', sorting: false },
    { text: 'MEETING TIME', sorting: true },
    { text: 'ACTION', sorting: false },
    { text: 'DETAILS', sorting: false },
  ]

  /**
   * Handles joining a Zoom meeting.
   * @param {string} calcomSessionId - The ID of the Calcom session for Zoom meeting.
   */
  const handleJoinMeeting = (calcomSessionId) => {
    dispatch(setIsSelectedOpenZoomModal(calcomSessionId))
  }

  /**
   * Click event handlers for various actions.
   */
  const onClickEvents = {
    joinMeeting: handleJoinMeeting,
  }

  /**
   * Handles input change for search.
   * @param {Object} e - The input change event.
   */
  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allPatients.filter((patient) => patient.full_name.toLowerCase().includes(inputValue))
    setPatients(filteredArray)
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <h6>My patients</h6>
        <div className={Styles.header__search}>
          <div className={Styles.header__search__container}>
            <SearchIcon />
            <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
          </div>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : allPatients.length ? (
        <div className={Styles.data_container}>
          <Table headers={headers} data={patients} userType={user?.user?.user_type} userDataType={'patient'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There are no patients yet.'} />
      )}
      <CustomPagination dataArray={allPatients} currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </div>
  )
}
