//Images
import { ReactComponent as NoDataImage } from '../../../theme/assets/images/no_data_image.svg'
//Styles
import Styles from './styles.module.scss'

export const NoDataComponent = ({ text }) => {
  return <div className={Styles.main}>
    <p>{text}</p>
    <NoDataImage />
  </div>
}