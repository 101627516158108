//MUI
import { Modal } from "@mui/material"
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close.svg'
//Components
import { Summary } from "../Summary"
//Core
import { useDispatch } from 'react-redux'
import { setOneApply } from '../../../reducers/advisorOnbordingSlice'

export const SeeResultsPopup = ({ open, onClose, isAdult }) => {
  const dispatch = useDispatch()

  const handleCloseResultsPopup = () => {
    dispatch(setOneApply({}))
    onClose()
  }
  return <Modal open={open} onClose={handleCloseResultsPopup}>
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <CloseIcon className={Styles.header__icon} onClick={handleCloseResultsPopup} />
      </div>
      <Summary isModal={true} isAdult={isAdult} />
    </div>
  </Modal>
}