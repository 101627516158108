// Core
import { useEffect } from 'react'
// Routing
import { NavLink } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as EnvelopIcon } from '../../../theme/assets/icons/envelop-animated-icon.svg'
//i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const ParentNav = ({ isIconShow }) => {
  const profileId = localStorage.getItem('lastSelectedProfile')

  const { t } = useTranslation()

  useEffect(() => {
    i18n.addResourceBundle('en', 'parent_nav', en)
    i18n.addResourceBundle('ar', 'parent_nav', ar)
  }, [])

  return (
    <div className={Styles.tabs}>
      <NavLink to="/profile/parent/therapy" className={({ isActive }) => (isActive ? Styles.active : Styles.tab)}>
        <span>{t('parent_nav:therapy')}</span>
      </NavLink>
      <NavLink to={`/profile/parent/chat/${profileId}`} className={({ isActive }) => (isActive ? `${Styles.active} ${Styles.chat}` : `${Styles.tab} ${Styles.chat}`)}>
        <span>{t('parent_nav:chats')}</span>
        {isIconShow && <EnvelopIcon />}
      </NavLink>
    </div>
  )
}
