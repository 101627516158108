/**
 * @fileoverview Redux slice for general state management
 * @module generalSlice
 */

// Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * General slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {boolean} initialState.loading - Indicates if the general loading state is active.
 * @property {boolean} initialState.modalLoading - Indicates if the modal loading state is active.
 * @property {string} initialState.error - Stores error messages.
 * @property {string} initialState.message - Stores general messages.
 * @property {number} initialState.status - Stores the status code.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setLoading - Sets the general loading state.
 * @property {Function} reducers.setError - Sets the error message.
 * @property {Function} reducers.clearError - Clears the error message.
 * @property {Function} reducers.setMessage - Sets the success message.
 * @property {Function} reducers.clearMessage - Clears the success message.
 * @property {Function} reducers.setStatus - Sets the status code.
 * @property {Function} reducers.setModalLoading - Sets the modal loading state.
 */

export const generalSlice = createSlice({
  name: 'general',
  initialState: {
    loading: false,
    modalLoading: false,
    error: '',
    message: '',
    status: 0,
  },
  reducers: {
    /**
     * Sets the general loading state.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {boolean} action.payload - The new loading state.
     */
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    /**
     * Sets the error message.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new error message.
     */
    setError: (state, action) => {
      state.error = action.payload
    },
    /**
     * Clears the error message.
     * @param {Object} state - The current state.
     */
    clearError: (state) => {
      state.error = ''
    },
    /**
     * Sets the success message.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The new success message.
     */
    setMessage: (state, action) => {
      state.message = action.payload
    },
    /**
     * Clears the success message.
     * @param {Object} state - The current state.
     */
    clearMessage: (state) => {
      state.message = ''
    },
    /**
     * Sets the status code.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {number} action.payload - The new status code.
     */
    setStatus: (state, action) => {
      state.status = action.payload
    },
    /**
     * Sets the modal loading state.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {boolean} action.payload - The new modal loading state.
     */
    setModalLoading: (state, action) => {
      state.modalLoading = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setLoading - Action creator for setting the loading state.
 * @property {Function} setError - Action creator for setting the error message.
 * @property {Function} clearError - Action creator for clearing the error message.
 * @property {Function} setMessage - Action creator for setting the success message.
 * @property {Function} clearMessage - Action creator for clearing the message.
 * @property {Function} setStatus - Action creator for setting the status code.
 * @property {Function} setModalLoading - Action creator for setting the modal loading state.
 */
export const { setLoading, setError, clearError, setMessage, clearMessage, setStatus, setModalLoading } = generalSlice.actions

export default generalSlice.reducer
