// Components
import { Authorization, PasswordRecovery } from '../../components'

/**
 * PasswordRecoveryPage component for Authorization and PasswordRecovery.
 *
 * @component
 * @category Public routing
 * @returns {JSX.Element} Components
 * @example
 * <{@link Authorization} />
 *    <{@link PasswordRecovery}  />
 * </Authorization>
 */

export const PasswordRecoveryPage = () => {
  return (
    <Authorization>
      <PasswordRecovery />
    </Authorization>
  )
}
