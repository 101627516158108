//Core
import { useState, useRef, useEffect } from 'react'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as UserIcon } from '../../../../theme/assets/icons/user.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
import { translateTherapistStatuses, translateTherapistProfQualities } from '../../../../data/locale.data'

export const TherapistCard = ({
  discovery: { therapist },
  discovery,
  actionForDetailsPopup,
  openDiscoveryCallPopup,
  setCalComLink,
  setDeclinePopupOpen,
  setDiscoveryId,
  acceptTherapist,
  setTherapistId,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    i18n.addResourceBundle('en', 'therapist_card', en)
    i18n.addResourceBundle('ar', 'therapist_card', ar)
  }, [])

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  //DECLINE THERAPIST POPUP
  const handleOpenDeclinePopup = (event) => {
    event.stopPropagation()
    setDiscoveryId(discovery.id)
    setDeclinePopupOpen(true)
  }

  //ACCEPT THERAPIST
  const handleAcceptTherapist = (event) => {
    event.stopPropagation()
    acceptTherapist(discovery.id)
  }

  //THERAPIST INFO POPUP
  const handleOpenDetailsPopup = (event) => {
    event.stopPropagation()
    setTherapistId(therapist.id)
    actionForDetailsPopup(true)
  }

  //DISCOVERY CALL POPUP
  const handleOpenDiscoveryCallPopup = (event, link) => {
    event.stopPropagation()
    if (link) {
      setCalComLink(link)
      openDiscoveryCallPopup(true)
    }
  }

  return (
    <div className={Styles.main}>
      <div className={Styles.header}>
        <div className={Styles.header__photo}>{therapist?.user?.profile_pic ? <img src={therapist?.user?.profile_pic} /> : <UserIcon />}</div>
        <div className={Styles.status}>
          {discovery.parent_status === 'waiting' ? (
            <p className={Styles.status_waiting}>{t('therapist_card:statuses:pending')}</p>
          ) : (
            <p className={Styles.status_accepted}>{translateTherapistStatuses[discovery?.parent_status][selectedLanguage]?.toUpperCase()}</p>
          )}
        </div>
      </div>
      <div className={Styles.info}>
        <div className={Styles.info__name}>{therapist?.user?.full_name}</div>
        <span>{therapist?.professional_qualities?.map((value) => translateTherapistProfQualities?.[value]?.[selectedLanguage] || value)?.join(', ')}</span>
        <p onClick={(e) => handleOpenDetailsPopup(e, therapist?.id)}>{t('therapist_card:view_more')}</p>
      </div>
      <div className={Styles.btns}>
        <button
          className={Styles.btns__call}
          style={{
            borderColor: discovery?.calcom_session?.get_status ? '#C7BCCE' : '#8450A0',
            color: discovery?.calcom_session?.get_status ? '#C7BCCE' : '#8450A0',
            cursor: discovery?.calcom_session?.get_status && 'default',
          }}
          disabled={discovery.calcom_session?.get_status}
          onClick={(e) => handleOpenDiscoveryCallPopup(e, discovery?.therapist?.discovery_link)}
        >
          {t('therapist_card:discovery_call')}
        </button>
        {discovery?.parent_status != 'accepted' && (
          <div className={Styles.btns__actions}>
            <div onClick={(e) => handleAcceptTherapist(e)} className={Styles.accept_btn}>
              {t('therapist_card:actions:accept')}
            </div>
            <div onClick={(e) => handleOpenDeclinePopup(e)} className={Styles.decline_btn}>
              {t('therapist_card:actions:decline')}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
