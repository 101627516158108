/**
 * @fileoverview Redux slice for user-verification-related state management
 * @module verificationSlice
 */
//Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Verification slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {string} initialState.responseStatus - Response status of login by authorization link request.

 * @property {Function} reducers.setStatus - Sets the response status of login by authorization link request.
 */
export const verificationSlice = createSlice({
  name: 'verification',
  initialState: {
    responseStatus: 0,
  },
  reducers: {
    /**
     * Sets the response status of login by authorization link request.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The response status.
     */
    setStatus: (state, action) => {
      state.responseStatus = action.payload
    },
  },
})

export const { setStatus } = verificationSlice.actions

export default verificationSlice.reducer
