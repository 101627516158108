// Core
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
// Styles
import Styles from './Styles.module.scss'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
// Utils
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// Data
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE } from '../../../../data/enums'
import { OPTIONAL_SECTIONS_NAMES, parent_onboarding_family_dynamics, parent_onboarding_parental_wellbeing } from '../../../../data'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

export const ParentOnboardingOptionalDisclaimer = ({ setOnboardingStep, sectionName }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'optional_disclaimer', en)
    i18n.addResourceBundle('ar', 'optional_disclaimer', ar)
  }, [])

  const handleGoBack = () => {
    if (sectionName === ONBOARDING_STEP.FAMILY_DYNAMICS) {
      setOnboardingStep(ONBOARDING_STEP.QUESTIONNAIRE)
    } else {
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      const familyDynamics = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.FAMILY_DYNAMICS))
      const theLastAnsweredQuestionFamilyDynamics = familyDynamics?.find((question) => question?.answer !== SKIPPED_VALUE)
      if (theLastAnsweredQuestionFamilyDynamics) {
        mainLocalStorage.family_dynamics_read_view = theLastAnsweredQuestionFamilyDynamics?.id
        setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS)
      } else {
        mainLocalStorage.family_dynamics_read_view = null
        setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER)
      }
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
    }
  }

  const handleGoNext = () => {
    if (sectionName === ONBOARDING_STEP.FAMILY_DYNAMICS) {
      setOnboardingStep(ONBOARDING_STEP.FAMILY_DYNAMICS)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.step = ONBOARDING_STEP.FAMILY_DYNAMICS
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
    } else {
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.step = ONBOARDING_STEP.PARENTAL_WELLBEING
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
    }
  }

  const handleSkipTheSection = () => {
    if (sectionName === ONBOARDING_STEP.FAMILY_DYNAMICS) {
      const familyDynamics = parent_onboarding_family_dynamics
      for (let i = 0; i < familyDynamics.length; i++) {
        familyDynamics[i].answer = SKIPPED_VALUE
      }

      setLocalStorage(ONBOARDING_STEP.FAMILY_DYNAMICS, JSON.stringify(familyDynamics))
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
    } else {
      const parentalWellbeing = parent_onboarding_parental_wellbeing
      for (let i = 0; i < parentalWellbeing.length; i++) {
        parentalWellbeing[i].answer = SKIPPED_VALUE
      }

      setLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING, JSON.stringify(parentalWellbeing))
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.REPORT
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      setOnboardingStep(ONBOARDING_STEP.REPORT)
    }
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.main_container__header}>
        <div>
          {sectionName === ONBOARDING_STEP.FAMILY_DYNAMICS ? (
            <h6>{t('optional_disclaimer:family_dynamics:header')}</h6>
          ) : (
            <h6>{t('optional_disclaimer:parental_wellbeing:header')}</h6>
          )}
          {sectionName === ONBOARDING_STEP.FAMILY_DYNAMICS && <div>{t('optional_disclaimer:family_dynamics:post_header')}</div>}
        </div>
        <span>{t('optional_disclaimer:optional')} </span>
      </div>
      <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} className={Styles.main_container__btns}>
        <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleSkipTheSection}>
          <CloseIcon />
          {t('optional_disclaimer:footer:skip')}
        </div>
        <span style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoNext}>
          {t('optional_disclaimer:footer:proceed')}
          {selectedLanguage === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
        </span>
      </div>
      <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} className={Styles.main_container__footer}>
        <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoBack}>
          {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
          {t('optional_disclaimer:footer:back')}
        </div>
      </div>
    </div>
  )
}
