// Core
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
// Styles
import Styles from './Styles.module.scss'
// Data
import { parent_onboarding_parental_wellbeing } from '../../../../data'
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME, SKIPPED_VALUE } from '../../../../data/enums'
// Utils
import { getLocalStorage, setLocalStorage } from '../../../../utils'
// Icons
import { ReactComponent as ArrowLeftIcon } from '../../../../theme/assets/icons/arrow_left_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../../theme/assets/icons/arrow_right_icon.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'
import en from '../AddChild/locale.js/en.json'
import ar from '../AddChild/locale.js/ar.json'
import { translateParentalWellbeing } from '../../../../data/locale.data'

export const ParentOnboardingParentalWellbeing = ({ setOnboardingStep }) => {
  const [searchParams] = useSearchParams()
  const { t } = useTranslation()

  const [questionId, setQuestionId] = useState(0)

  const [localStorageObject, setLocalStorageObject] = useState(JSON.parse(getLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING))?.[0])

  const [selectedLanguage, setSelectedLanguage] = useState(searchParams.get('lng'))

  useEffect(() => {
    setSelectedLanguage(searchParams.get('lng'))
    i18n.changeLanguage(searchParams.get('lng'))
  }, [searchParams])

  useEffect(() => {
    i18n.addResourceBundle('en', 'add_child', en)
    i18n.addResourceBundle('ar', 'add_child', ar)
  }, [])

  const amountOfQuestions = parent_onboarding_parental_wellbeing?.length

  useEffect(() => {
    const storage = JSON.parse(getLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING))
    if (!storage) {
      setLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING, JSON.stringify(parent_onboarding_parental_wellbeing))
    } else {
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      if (mainLocalStorage.parental_wellbeing_read_view) {
        setQuestionId(mainLocalStorage.parental_wellbeing_read_view)
      } else {
        for (const question of storage) {
          if (question?.answer) {
            continue
          } else {
            setQuestionId(question?.id)
            break
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    setLocalStorageObject(JSON.parse(getLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING))[questionId])
  }, [questionId])

  const handleSelectOption = (answer) => {
    localStorageObject.answer = answer
    const finalStorage = JSON.parse(getLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING))
    finalStorage?.splice(questionId, 1, localStorageObject)
    setLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING, JSON.stringify(finalStorage))

    if (localStorageObject?.id + 1 === amountOfQuestions) {
      setOnboardingStep(ONBOARDING_STEP.REPORT)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.REPORT
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      return
    } else {
      setQuestionId((prev) => prev + 1)
    }
  }

  const handleGoBack = () => {
    if (questionId === 0) {
      setOnboardingStep(ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER)
    } else {
      setQuestionId((prev) => prev - 1)
    }
  }

  const handleGoNext = () => {
    if (!localStorageObject?.answer) {
      localStorageObject.answer = SKIPPED_VALUE
      const finalStorage = JSON.parse(getLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING))
      finalStorage?.splice(questionId, 1, localStorageObject)
      setLocalStorage(ONBOARDING_STEP.PARENTAL_WELLBEING, JSON.stringify(finalStorage))
    }

    if (localStorageObject?.id + 1 === amountOfQuestions) {
      setOnboardingStep(ONBOARDING_STEP.REPORT)
      const mainLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))
      mainLocalStorage.state = ONBOARDING_STEP.REPORT
      mainLocalStorage.parental_wellbeing_read_view = null
      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(mainLocalStorage))
      return
    } else {
      setQuestionId((prev) => prev + 1)
    }
  }

  return (
    <div className={Styles.main_container}>
      {localStorageObject?.header && <h5>{translateParentalWellbeing?.[localStorageObject?.header]?.[selectedLanguage]}</h5>}
      {localStorageObject?.id === questionId && (
        <div className={Styles.main_container__content}>
          <h4>{translateParentalWellbeing?.[localStorageObject?.question]?.[selectedLanguage]}</h4>
          <div className={Styles.main_container__content__btns}>
            {localStorageObject?.options?.map((option) => (
              <div
                onClick={() => handleSelectOption(option?.value)}
                className={localStorageObject.answer === option.value ? Styles.main_container__content__btns__selected : Styles.main_container__content__btns__item}
                key={option?.id}
              >
                {translateParentalWellbeing?.[option?.value]?.[selectedLanguage]}
              </div>
            ))}
          </div>
          <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} className={Styles.main_container__footer}>
            <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoBack}>
              {selectedLanguage === 'en' ? <ArrowLeftIcon /> : <ArrowRightIcon />}
              {t('add_child:footer:back')}
            </div>
            <div style={{ flexDirection: selectedLanguage === 'en' ? 'row' : 'row-reverse' }} onClick={handleGoNext}>
              {t('add_child:footer:next')} {selectedLanguage === 'en' ? <ArrowRightIcon /> : <ArrowLeftIcon />}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
