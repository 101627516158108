// Components
import { Authorization, VerifyEmail, SliderAuth } from '../../components'

/**
 * VerifyEmailPage component for Authorization and VerifyEmail, SliderAuth.
 *
 * @component
 * @category Public routing
 * @returns {JSX.Element} Components
 * @example
 * <{@link Authorization} />
 *    <{@link VerifyEmail} />
 *    <{@link SliderAuth} />
 * </Authorization>
 */

export const VerifyEmailPage = () => {
  return (
    // <Authorization>
    <VerifyEmail />
    // </Authorization> 
  )
}
