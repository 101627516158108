// Styles
import Styles from './styles.module.scss'
// Components
import { Chat } from '../Common/Chat'

export const TherapistChatSystem = () => {
  return (
    <section className={Styles.therapist_chat_system}>
      <Chat />
    </section>
  )
}
