/**
 * @fileoverview Redux slice for admin-related state management
 * @module adminSlice
 */

// Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Admin slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {Array} initialState.allParents - List of all parents.
 * @property {Object} initialState.child - Child information.
 * @property {Array} initialState.childRecords - List of sessions for child profile.
 * @property {Array} initialState.allAdvisors - List of all advisors.
 * @property {Array} initialState.allZoomMeetings - List of all Zoom meetings.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setAllParents - Sets the list of all parents.
 * @property {Function} reducers.setChild - Sets the child information.
 * @property {Function} reducers.setChildRecords - Sets the list of sessions for child profile.
 * @property {Function} reducers.setAllAdvisors - Sets the list of all advisors.
 * @property {Function} reducers.setAllMeetings - Sets the list of all Zoom meetings.
 */

export const adminSlice = createSlice({
  name: 'admin',
  initialState: {
    allParents: [],
    child: {},
    childRecords: [],
    allAdvisors: [],
    allZoomMeetings: [],
  },
  reducers: {
    /**
     * Sets the list of all parents.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all parents.
     */
    setAllParents: (state, action) => {
      state.allParents = action.payload
    },
    /**
     * Sets the child information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new child information.
     */
    setChild: (state, action) => {
      state.child = action.payload
    },
    /**
     * Sets the list of sessions for child profile.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of sessions for child profile.
     */
    setChildRecords: (state, action) => {
      state.childRecords = action.payload
    },
    /**
     * Sets the list of all advisors.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all advisors.
     */
    setAllAdvisors: (state, action) => {
      state.allAdvisors = action.payload
    },
    /**
     * Sets the list of all Zoom meetings.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Array} action.payload - The new list of all Zoom meetings.
     */
    setAllMeetings: (state, action) => {
      state.allZoomMeetings = action.payload
    },
  },
})

/**
 * Action creators generated from the slice.
 * @type {Object}
 * @property {Function} setAllParents - Action creator for setting the list of all parents.
 * @property {Function} setChild - Action creator for setting the child information.
 * @property {Function} setChildRecords - Action creator for setting the list of child records.
 * @property {Function} setAllAdvisors - Action creator for setting the list of all advisors.
 * @property {Function} setAllMeetings - Action creator for setting the list of all Zoom meetings.
 */

export const { setAllParents, setChild, setChildRecords, setAllAdvisors, setAllMeetings } = adminSlice.actions

export default adminSlice.reducer
