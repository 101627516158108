//Core
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowDownIcon } from '../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowRightIcon } from '../../../theme/assets/icons/arrow_right_icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../../theme/assets/icons/arrow_up_icon.svg'
import { ReactComponent as CameraIcon } from '../../../theme/assets/icons/camera_icon.svg'
import { ReactComponent as CrossIcon } from '../../../theme/assets/icons/cross_circle_icon.svg'
import { ReactComponent as Sorting } from '../../../theme/assets/icons/sorting_icon.svg'
import { ReactComponent as TickIcon } from '../../../theme/assets/icons/tick_circle_icon.svg'
import { ReactComponent as MenuIcon } from '../../../theme/assets/icons/vertical_dots_icon.svg'
import { ReactComponent as WhatsAppIcon } from '../../../theme/assets/icons/whatsapp_icon.svg'
//Utils
import { getDate, getInitials, getMinutesBeforeEvent, returnCountry, returnStatus, sortArray } from '../../../utils'
//Components
import { CustomTooltip } from '../../GeneralComponents'
//MUI
import Collapse from '@mui/material/Collapse'

export const Table = ({ data, headers, refetch, showNotification, userType, userDataType, onClickEvents }) => {
  const minutesToCall = process.env.REACT_APP_MINUTES_BEFORE_CALL
  const navigate = useNavigate()

  //For closing "Actions" div by clicking somewhere on screen
  const actionsContainerRef = useRef(null)

  const [sortDirection, setSortDirection] = useState(false)
  const [dataForTable, setDataForTable] = useState([])

  const [openActionsMenu, setOpenActionsMenu] = useState(false)

  const [selectedUserId, setSelectedUserId] = useState(0)

  const [isCollapsed, setIsCollapsed] = useState(false)
  const [selectedRowToCollapse, setSelectedRowToCollapse] = useState(0)

  useEffect(() => {
    setDataForTable(data)
  }, [data])

  const handleSortData = (data, fieldName) => {
    setSortDirection((prev) => !prev)
    sortArray(sortDirection, data, fieldName)
  }

  //For closing "Actions" div by clicking somewhere on screen
  const handleClickOutsideActions = (event) => {
    if (actionsContainerRef.current && !actionsContainerRef.current.contains(event.target)) {
      setOpenActionsMenu(false)
    }
  }

  //For closing "Actions" div by clicking somewhere on screen
  useEffect(() => {
    document.body.addEventListener('click', handleClickOutsideActions)
    return () => document.body.removeEventListener('click', handleClickOutsideActions)
  }, [])

  //ACTIONS MENU (SET API)
  const handleOpenActionsMenu = (userId) => {
    event.stopPropagation()
    setSelectedUserId(userId)
    setOpenActionsMenu((prev) => !prev)
  }

  const returnApplier = (fullName, email = '', userId, profilePic = '', shouldDisplayInfo = false, isAdult) => {
    return (
      <div className={Styles.applier_container}>
        <div className={Styles.applier_container__initials}>{profilePic ? <img src={profilePic} /> : getInitials(fullName)}</div>
        <div className={Styles.applier_container__info}>
          <span
            className={shouldDisplayInfo ? Styles.applier_container__info__name__active_link : Styles.applier_container__info__name}
            onClick={() => {
              if (shouldDisplayInfo) {
                onClickEvents.openUserDetails(userId, isAdult)
              }
            }}
          >
            {fullName}
          </span>
          {email && <span>{email}</span>}
        </div>
      </div>
    )
  }

  const returnPhone = (phone, country) => {
    return (
      <div className={Styles.phone_container}>
        {country && <img width={20} src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} alt="flag" />}
        <span>{phone}</span>
      </div>
    )
  }

  const returnUsersArray = (users, actionOnClick, isTooltip) => {
    return (
      <div className={Styles.therapists_container}>
        {users.map((user) => {
          if (user?.user?.profile_pic || user?.profile_pic) {
            return (
              <CustomTooltip title={isTooltip && user?.user?.full_name} placement={'top'}>
                <div
                  onClick={() => {
                    actionOnClick && onClickEvents[actionOnClick](user?.id)
                  }}
                  className={Styles.therapists_container__photo}
                  style={{ cursor: actionOnClick ? 'pointer' : 'default' }}
                >
                  <img src={user?.user?.profile_pic || user?.profile_pic} alt="photo" />
                </div>
              </CustomTooltip>
            )
          } else {
            return (
              <CustomTooltip title={isTooltip && user?.user?.full_name} placement={'top'}>
                <div
                  onClick={() => {
                    actionOnClick && onClickEvents[actionOnClick](user?.id)
                  }}
                  className={Styles.therapists_container__initials}
                  style={{ cursor: actionOnClick ? 'pointer' : 'default' }}
                >
                  {getInitials(user?.user?.full_name || user?.full_name)}
                </div>
              </CustomTooltip>
            )
          }
        })}
      </div>
    )
  }

  const returnCalComConection = (calcomConnection, userId, userDataType) => {
    return (
      <div className={Styles.calcom_connection}>
        {calcomConnection ? <TickIcon className={Styles.calcom_connection__icon} /> : <CrossIcon className={Styles.calcom_connection__icon} />}
      </div>
    )
  }

  const returnVerticalMenu = (userId, userDataType, isCompletedRegistration) => {
    return (
      <div ref={actionsContainerRef} style={{ position: 'relative' }}>
        <MenuIcon className={Styles.calcom_connection__icon_menu} onClick={() => handleOpenActionsMenu(userId)} />
        {selectedUserId === userId && openActionsMenu && (
          <div className={Styles.action_menu}>
            <p
              onClick={() => {
                setOpenActionsMenu(false)
                onClickEvents.setApiKey(userId)
              }}
            >
              Set API key
            </p>
            {userDataType !== 'therapist' && !isCompletedRegistration && (
              <p
                onClick={() => {
                  setOpenActionsMenu(false)
                  onClickEvents.resendInvitation(userId)
                }}
              >
                Resend invitation
              </p>
            )}
            {userDataType !== 'therapist' && (
              <p
                className={Styles.action_menu__delete}
                onClick={() => {
                  setOpenActionsMenu(false)
                  onClickEvents.deleteAdvisor(userId)
                }}
              >
                Delete
              </p>
            )}
            {userDataType === 'therapist' && (
              <p
                onClick={() => {
                  setOpenActionsMenu(false)
                  onClickEvents.editProfile(userId)
                }}
              >
                Edit profile
              </p>
            )}
          </div>
        )}
      </div>
    )
  }
  const actionsForAdvisorsByAdmin = (calcomConnection, userId, userDataType, isCompletedRegistration) => {
    return (
      <div className={Styles.vertical_flex}>
        {returnCalComConection(calcomConnection)}
        {returnVerticalMenu(userId, userDataType, isCompletedRegistration)}
      </div>
    )
  }

  const actionsForTherapistsByAdmin = (status, userId, userDataType, userName, isCompletedRegistration) => {
    if (status !== 'created') {
      return (
        <div className={Styles.vertical_flex}>
          {returnStatus(status)}
          {returnVerticalMenu(userId, userDataType, isCompletedRegistration)}
        </div>
      )
    }
    return (
      <div className={Styles.vertical_flex}>
        <div className={Styles.therapistActionBtnsContainer}>
          <button className={Styles.therapistActionBtnsContainer__decline} onClick={() => onClickEvents.declineTherapist(userId)}>
            Decline
          </button>
          <button
            className={Styles.therapistActionBtnsContainer__accept}
            onClick={() => {
              onClickEvents.acceptTherapist(userId)
              showNotification(userName)
            }}
          >
            Accept
          </button>
        </div>
        {returnVerticalMenu(userId, userDataType, isCompletedRegistration)}
      </div>
    )
  }

  const returnActionsForTherapist = (screeningId, status, profileName, meetingId, meetingStartTime) => {
    const minutesBeforeEvent = getMinutesBeforeEvent(meetingStartTime)

    return (
      <div className={Styles.therapistActionBtnsContainer}>
        {status === 'waiting' ? (
          <>
            <button
              className={Styles.therapistActionBtnsContainer__decline}
              onClick={() => {
                onClickEvents.declineApplication(screeningId, profileName)
                showNotification(profileName, 'decline')
              }}
            >
              Decline
            </button>
            <button
              className={Styles.therapistActionBtnsContainer__accept}
              onClick={() => {
                onClickEvents.acceptApplication(screeningId, profileName)
                showNotification(profileName, 'accept')
              }}
            >
              Accept
            </button>
          </>
        ) : meetingId ? (
          <>
            {minutesBeforeEvent > minutesToCall && (
              <CustomTooltip title="Your call will be available when there are less than 5 minutes left before the event starts." placement="top" titleFontSize="14px">
                <div className={Styles.purpleBtn_disabled}>Join meeting</div>
              </CustomTooltip>
            )}
            {minutesBeforeEvent <= minutesToCall && (
              <div onClick={() => onClickEvents.joinMeeting(meetingId)} className={Styles.purpleBtn}>
                Join meeting
              </div>
            )}
          </>
        ) : (
          '-'
        )}
      </div>
    )
  }

  const returnChildPlofile = (childId, userType) => {
    return (
      <div className={Styles.therapistGoToProfile_container}>
        <p className={Styles.therapistGoToProfile_container__btn} onClick={() => navigate(`child/${childId}?type=${userType}`)}>
          Go to the profile
        </p>
        <ArrowRightIcon />
      </div>
    )
  }

  const returnActionsForPatientsByTherapist = (calcomSessionId, sessionStartTime) => {
    const minutesBeforeEvent = getMinutesBeforeEvent(sessionStartTime)

    return (
      <>
        {minutesBeforeEvent > minutesToCall && (
          <CustomTooltip title="Your call will be available when there are less than 5 minutes left before the event starts." placement="top" titleFontSize="14px">
            <div className={Styles.purpleBtn_disabled}>Join meeting</div>
          </CustomTooltip>
        )}
        {minutesBeforeEvent <= minutesToCall && (
          <div onClick={() => onClickEvents.joinMeeting(calcomSessionId)} className={Styles.purpleBtn}>
            Join meeting
          </div>
        )}
      </>
    )
  }

  const returnActionsForApplicationsByAdvisor = (application) => {
    const { status, id, email, full_name, meeting_id, start_time, is_adult } = application

    const minutesBeforeEvent = getMinutesBeforeEvent(start_time)

    if (status === 'failed') {
      return '-'
    }

    if (status === 'planned') {
      return (
        <>
          {minutesBeforeEvent > minutesToCall && (
            <CustomTooltip title="Your call will be available when there are less than 5 minutes left before the event starts." placement="top" titleFontSize="14px">
              <div className={Styles.purpleBtn_disabled}>Join meeting</div>
            </CustomTooltip>
          )}
          {minutesBeforeEvent <= minutesToCall && (
            <div onClick={() => onClickEvents.joinMeeting(id, meeting_id)} className={Styles.purpleBtn}>
              Join meeting
            </div>
          )}
        </>
      )
    }

    if (status === 'accepted' || status === 'declined') {
      return (
        <div className={Styles.pinkBtn} onClick={() => onClickEvents.openResults(id, is_adult)}>
          See results
        </div>
      )
    }

    if (status === 'in_a_meeting') {
      return (
        <div className={Styles.start_meeting_container}>
          <div className={Styles.start_meeting}>
            <CameraIcon onClick={() => onClickEvents.joinMeeting(0, meeting_id)} />
          </div>
          <div
            onClick={() =>
              onClickEvents.startOnboarding(
                {
                  meetingId: id,
                  email,
                  fullName: full_name,
                },
                is_adult,
                {
                  profile_name: application?.child_info?.child_name || 'Test Child',
                  birth_date: application?.child_info?.birth_date || 'Test date',
                  language: application?.child_info?.languages || ['en'],
                },
              )
            }
            className={Styles.noBorderBtn}
          >
            Start onboarding
          </div>
        </div>
      )
    }
  }

  const returnActionsForParentsByAdvisor = (application) => {
    const { id, type } = application

    return (
      <div onClick={() => navigate(`assign/${id}?type=${type}`)} className={Styles.purpleBtn}>
        Assign
      </div>
    )
  }

  const returnParents = (parents, advisor) => {
    return (
      <div onClick={() => onClickEvents.openParentsPopup(parents, advisor)} className={Styles.parents_container}>
        {parents.length}
      </div>
    )
  }

  const returnDate = (date, withTime) => {
    return (
      <CustomTooltip title={withTime ? 'DD/MM/YYYY H : mm' : 'DD/MM/YYYY'} placement="bottom">
        {getDate(date, withTime)}
      </CustomTooltip>
    )
  }

  const returnSingleUser = (fullName, profilePic, actionOnClick = false, profileId) => {
    return (
      <div className={Styles.applier_container}>
        <div className={Styles.applier_container__initials}>{profilePic ? <img src={profilePic} /> : getInitials(fullName)}</div>
        <div className={Styles.applier_container__info}>
          <div
            className={actionOnClick ? Styles.applier_container__info__name__active_link : Styles.applier_container__info__name}
            onClick={() => {
              if (actionOnClick) onClickEvents.goToProfile(profileId)
            }}
          >
            {fullName ? <p>{fullName}</p> : <span className={Styles.no_info}>New profile</span>}
          </div>
        </div>
      </div>
    )
  }

  const handleCollapseRow = (action, rowId) => {
    setSelectedRowToCollapse(rowId)
    setIsCollapsed(action)
  }

  const handleReturnAdminActionForMeetings = (isZoomStable, zoomId, row) => {
    const text = `Hello, ${row?.client?.full_name}. This is a soft reminder that you have an appointment at ${getDate(row?.start_time, false, true)} with ${
      row?.organizer?.full_name
    }.`

    return (
      <div className={Styles.admin_meetings_btns}>
        <WhatsAppIcon onClick={() => window.open(`https://api.whatsapp.com/send?phone=${row?.client?.phone_number}&text=${encodeURIComponent(text)}`, '_blank')} />
        {!isZoomStable && (
          <div style={{ width: '65%' }} onClick={() => onClickEvents.retryZoomMeeting(zoomId)} className={Styles.purpleBtn}>
            Retry
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={Styles.table_container}>
      <table className={Styles.table}>
        <thead>
          <tr>
            {headers?.map((item, index) => {
              return (
                <th key={index}>
                  <span className={Styles.header_item}>
                    {item.text}
                    {item.sorting && (
                      <Sorting className={Styles.sort_icon} onClick={() => handleSortData(data, `${userType.toUpperCase()}_${userDataType.toUpperCase()}_${item.text}`)} />
                    )}
                  </span>
                </th>
              )
            })}
          </tr>
        </thead>
        <tbody className={Styles.body}>
          {dataForTable?.map((row) => (
            <>
              <tr key={row.id}>
                {userType === 'admin' && userDataType === 'advisor' && (
                  <>
                    {(row?.user?.created_at && <td className={Styles.table_item}>{returnDate(row?.user?.created_at, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {((row?.user?.full_name || row?.user?.email) && <td className={Styles.table_item}>{returnApplier(row.user.full_name, row.user.email)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.languages.length && <td className={Styles.table_item}>{row.languages.join(', ')}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.user?.country && <td className={Styles.table_item}>{returnCountry(row.user.country, Styles)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.parents && <td className={Styles.table_item}>{returnParents(row.parents, row?.user)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    <td>{actionsForAdvisorsByAdmin(row.calcom_connect, row?.id, row.user.user_type, row?.is_completed_registration)}</td>
                  </>
                )}
                {userType === 'admin' && userDataType === 'parent' && (
                  <>
                    {((row?.user?.full_name || row?.user?.email) && (
                      <td className={isCollapsed && selectedRowToCollapse === row.id ? Styles.table_item_collapsed_parent : Styles.table_item}>
                        {returnApplier(row.user.full_name, row.user.email)}
                      </td>
                    )) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.user?.phone_number && (
                      <td className={isCollapsed && selectedRowToCollapse === row.id ? Styles.table_item_collapsed_parent : Styles.table_item}>
                        {returnPhone(row.user.phone_number)}
                      </td>
                    )) || <td className={Styles.table_item__no_info}>No information</td>}
                    {row?.profiles?.length > 1 ? (
                      <>
                        <td className={isCollapsed && selectedRowToCollapse === row.id ? Styles.table_item_collapsed_parent : Styles.table_item}>{'Open to show more info'}</td>
                        <td className={isCollapsed && selectedRowToCollapse === row.id ? Styles.table_item_collapsed_parent : Styles.table_item}>{'Open to show more info'}</td>
                        <td style={{ width: '50px' }} className={isCollapsed && selectedRowToCollapse === row.id ? Styles.table_item_collapsed_parent : Styles.table_item}>
                          {isCollapsed && selectedRowToCollapse === row.id ? (
                            <ArrowUpIcon onClick={() => handleCollapseRow(false, 0)} />
                          ) : (
                            <ArrowDownIcon onClick={() => handleCollapseRow(true, row.id)} />
                          )}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={Styles.table_item}>{returnSingleUser(row?.profiles[0]?.full_name, row?.profiles[0]?.profile_pic, true, row?.profiles[0]?.id)}</td>
                        <td className={Styles.table_item}>{returnSingleUser(row?.profiles[0]?.advisor?.full_name, row?.profiles[0]?.advisor?.profile_pic)}</td>
                        <td sty le={{ width: '50px' }} className={Styles.table_item}></td>
                      </>
                    )}
                  </>
                )}
                {userType === 'admin' && userDataType === 'therapist' && (
                  <>
                    {((row?.user?.full_name || row?.user?.email) && (
                      <td className={Styles.table_item}>{returnApplier(row?.user.full_name, row?.user.email, row?.id, row?.user?.profile_pic, true)}</td>
                    )) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.user?.country && <td className={Styles.table_item}>{returnCountry(row.user.country, Styles)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.languages.length && <td className={Styles.table_item}>{row.languages.join(', ')}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.experience && <td className={Styles.table_item}>{row.experience}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.professional_qualities && <td className={Styles.table_item}>{row.professional_qualities}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.profiles && <td className={Styles.table_item}>{returnParents(row.profiles, row?.user)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    <td>{returnCalComConection(row.calcom_connect, row?.id, row.user.user_type)}</td>
                    <td>{actionsForTherapistsByAdmin(row?.application_status, row?.id, row?.user.user_type, row?.user?.full_name, row?.user?.is_active)}</td>
                  </>
                )}
                {userType === 'admin' && userDataType === 'meetings' && (
                  <>
                    {((row?.organizer?.full_name || row?.organizer?.email) && (
                      <td className={Styles.table_item}>{returnApplier(row?.organizer.full_name, row?.organizer?.email, row?.id, row?.organizer?.profile_pic)}</td>
                    )) || <td className={Styles.table_item__no_info}>No information</td>}
                    {((row?.client?.full_name || row?.client?.email) && (
                      <td className={Styles.table_item}>{returnApplier(row?.client.full_name, row?.client?.email, row?.id, row?.client?.profile_pic)}</td>
                    )) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.start_time && <td className={Styles.table_item}>{returnDate(row?.start_time, true)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.end_time && <td className={Styles.table_item}>{returnDate(row?.end_time, true)}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    <td className={Styles.table_item}>{handleReturnAdminActionForMeetings(row?.zoom_meeting, row?.id, row)}</td>
                  </>
                )}
                {userType === 'therapist' && userDataType === 'application' && (
                  <>
                    {(row?.created_at && <td className={Styles.table_item}>{returnDate(row?.created_at, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.profile_name && <td className={Styles.table_item}>{returnApplier(row.profile_name, '', row.profile_id, '', true, row?.is_adult)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.concerns && <td className={Styles.table_item}>{row?.is_adult ? row?.concerns?.join(', ') : row?.concerns?.join(', ')}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.birth_date && <td className={Styles.table_item}>{row?.birth_date}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.therapist_status && <td className={Styles.table_item}>{returnStatus(row?.therapist_status)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.meeting_start_time && <td className={Styles.table_item}>{returnDate(row?.meeting_start_time, true)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.id && (
                      <td className={Styles.table_item}>
                        {returnActionsForTherapist(row?.id, row?.therapist_status, row?.profile_name, row?.meeting_id, row?.meeting_start_time)}
                      </td>
                    )) || <td className={Styles.table_item__no_info}>-</td>}
                  </>
                )}
                {userType === 'therapist' && userDataType === 'patient' && (
                  <>
                    {(row?.created_at && <td className={Styles.table_item}>{returnDate(row?.created_at, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.full_name && <td className={Styles.table_item}>{returnApplier(row.full_name, '', row.id, '', false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.concerns && <td className={Styles.table_item}>{row?.type === 'child' ? row?.concerns?.join(', ') : row?.concerns?.join(', ')}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.birth_date && <td className={Styles.table_item}>{row?.birth_date}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.calcom_session?.start_time && <td className={Styles.table_item}>{getDate(row?.calcom_session?.start_time, true)}</td>) || (
                      <td className={Styles.table_item__no_info}>-</td>
                    )}
                    {(row?.calcom_session?.get_status === 'Planned' && (
                      <td className={Styles.table_item}>{returnActionsForPatientsByTherapist(row?.calcom_session?.zoom_meeting_id, row?.calcom_session?.start_time)}</td>
                    )) || <td className={Styles.table_item__no_info}>-</td>}
                    {<td className={Styles.table_item}>{returnChildPlofile(row?.id, row?.type)}</td>}
                  </>
                )}
                {userType === 'advisor' && userDataType === 'application' && (
                  <>
                    {(row?.created_at && <td className={Styles.table_item}>{returnDate(row?.created_at, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.full_name && <td className={Styles.table_item}>{returnApplier(row.full_name, row.email, row.profile_id, '', true, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.phone_number && <td className={Styles.table_item}>{returnPhone(row?.phone_number, row?.country_code)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.concerns && <td className={Styles.table_item}>{row?.is_adult ? row?.concerns?.join(', ') : row?.concerns?.join(', ')}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.status && <td className={Styles.table_item}>{returnStatus(row?.status)}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.start_time && <td className={Styles.table_item}>{getDate(row?.start_time, true)}</td>) || <td className={Styles.table_item__no_info}>-</td>}
                    {<td className={Styles.table_item}>{returnActionsForApplicationsByAdvisor(row)}</td> || <td className={Styles.table_item__no_info}>-</td>}
                  </>
                )}
                {userType === 'advisor' && userDataType === 'parent' && (
                  <>
                    {(row?.created_at && <td className={Styles.table_item}>{returnDate(row?.created_at, false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.profile_name && <td className={Styles.table_item}>{returnApplier(row.profile_name, row.email, row.id, '', false)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.phone_number && <td className={Styles.table_item}>{returnPhone(row?.phone_number, row?.country_code)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.status && <td className={Styles.table_item}>{returnStatus(row?.status)}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.assigned_therapists.length && <td className={Styles.table_item}>{returnUsersArray(row?.assigned_therapists, 'openUserDetails', true)}</td>) || (
                      <td className={Styles.table_item__no_info}>-</td>
                    )}
                    {(row?.therapist_approval.length && <td className={Styles.table_item}>{returnUsersArray(row?.therapist_approval, 'openUserDetails', true)}</td>) || (
                      <td className={Styles.table_item__no_info}>-</td>
                    )}
                    {(row?.client_confirmation.length && <td className={Styles.table_item}>{returnUsersArray(row?.client_confirmation, 'openUserDetails', true)}</td>) || (
                      <td className={Styles.table_item__no_info}>-</td>
                    )}
                    {<td className={Styles.table_item}>{returnActionsForParentsByAdvisor(row)}</td>}
                  </>
                )}
                {userType === 'advisor' && userDataType === 'therapist' && (
                  <>
                    {(row?.user?.full_name && <td className={Styles.table_item}>{returnApplier(row?.user?.full_name, row?.user?.email, row?.id, '', true)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.user?.country && <td className={Styles.table_item}>{returnCountry(row.user.country, Styles)}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                    {(row?.languages && <td className={Styles.table_item}>{row.languages.join(', ')}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.experience && <td className={Styles.table_item}>{row?.experience}</td>) || <td className={Styles.table_item__no_info}>No information</td>}
                    {(row?.professional_qualities && <td className={Styles.table_item}>{row?.professional_qualities}</td>) || (
                      <td className={Styles.table_item__no_info}>No information</td>
                    )}
                  </>
                )}
              </tr>
              {isCollapsed && selectedRowToCollapse === row.id && (
                <>
                  {row.profiles.map((profile) => (
                    <tr key={profile.id}>
                      <td className={Styles.table_item_collapsed_child}>{returnApplier(row.user?.full_name, row.user?.email, row.user?.id, row.user?.profile_pic)}</td>
                      <td className={Styles.table_item_collapsed_child}>{returnPhone(row.user?.phone_number)}</td>
                      <td className={Styles.table_item_collapsed_child}>{returnSingleUser(profile?.full_name, profile?.profile_pic, true, profile?.id)}</td>
                      <td className={Styles.table_item_collapsed_child}>
                        {profile?.advisor ? returnSingleUser(profile?.advisor?.full_name, profile?.advisor?.profile_pic) : 'No info'}
                      </td>
                      <td style={{ width: '50px' }} className={Styles.table_item_collapsed_child}></td>
                    </tr>
                  ))}
                </>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  )
}
