//Core
import { useNavigate } from 'react-router-dom'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
//MUI
import { Modal } from '@mui/material'
//Utils
import { getInitials } from '../../../../utils'

export const ParentsPopup = ({ open, onClose, parents, user, userType }) => {
  const navigate = useNavigate()

  const handleOpenChildProfile = (childId) => {
    onClose()
    navigate(`/admin_panel/admin/parents/${childId}`)
  }
  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <h6>{userType} profile</h6>
          <CloseIcon className={Styles.icon} onClick={onClose} />
        </div>
        <div className={Styles?.content}>
          <div className={Styles?.content__advisor_container}>
            <div className={Styles.user_container}>
              {user?.profile_pic ? (
                <img src={user?.profile_pic} alt='user_pic' />
              ) : getInitials(user?.full_name)}
            </div>
            <div className={Styles?.content__advisor_container__text}>
              <p>{user?.full_name}</p>
              <span>{user?.email}</span>
            </div>
          </div>
          <span>{userType === 'Advisor'?  'Clients': 'Profiles'} are under the {userType.toLowerCase()}</span>
          <div className={Styles?.content__parents}>
            {parents?.length ? (
              <>
                {parents?.map(parent => (
                  <div
                    key={parent?.id}
                    className={Styles?.content__parents__item}>
                    <div className={Styles.content__parents__item__parent_container}>
                      <div className={Styles.user_container}>
                        {parent?.profile_pic ? (
                          <img src={parent?.profile_pic} alt='user_pic' />
                        ) : getInitials(parent?.full_name)
                        }
                      </div>
                      <div className={Styles?.content__parents__item__parent_container__text}>
                        <p>{parent?.full_name}</p>
                        <span>{parent?.email}</span>
                      </div>

                    </div>
                    <div className={Styles?.content__parents__item__children}>
                      {parent?.children?.map(child => (
                        <div
                          style={{
                            width: '25px',
                            height: '25px',
                            fontSize: '14px'
                          }}
                          onClick={() => handleOpenChildProfile(child?.id)}
                          className={`${Styles.user_container} ${Styles.click}`}>
                          {child?.profile_pic ? (
                            <img src={child?.profile_pic} alt='user_pic' />
                          ) : getInitials(child?.full_name)}
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <span className={Styles.no_data}>This {userType.toLowerCase()} hasn't worked with any {userType === 'Advisor'?  'clients': 'profiles'} yet.</span>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
} 