// Core
import { useSelector } from 'react-redux'
// Routing
import { Routes, Route, Outlet } from 'react-router-dom'
// Components
import { Main } from '../components'
// Pages
import {
  AdminChatPage,
  AdminParentProfilePage,
  AdvisorApplicationsPage,
  AdvisorAssignTherapistsPage,
  AdvisorChatPage,
  AdvisorParentsPage,
  AdvisorTherapistsPage,
  AdvisorsPage,
  Error404Page,
  ParentChatPage,
  TherapistChatPage,
  QuestionsPage,
  SummaryPage,
  TherapyPage,
  TherapistApplicationsPage,
  TherapistPatientsPage,
  ChildProfilePage,
  PaymentPage,
  ParentsAdminPage,
  NotesPage,
  AdminMeetingsPage,
  SignInPage,
  ParentQuestionnaireForExistingAccount,
  AdvisorParentProfilePage,
} from '../pages'

export const Private = ({ user }) => {
  console.log('im private', user)
  return (
    <Routes>
      <Route path="/" element={<Main />}>
        <Route path="profile" element={<Outlet />}>
          {user?.user?.user_type === 'parent' && (
            <Route path="parent" element={<Outlet />}>
              <Route path="therapy" element={<TherapyPage />} />
              <Route path="therapy/add_questionnaire" element={<ParentQuestionnaireForExistingAccount isAccountExists={true} />} />
              <Route path="therapy/:childId/notes" element={<NotesPage />} />
              <Route path="chat/:profileId" element={<ParentChatPage />} />
            </Route>
          )}
          {user?.user?.user_type === 'therapist' && (
            <Route path="therapist" element={<Outlet />}>
              <Route path="applications" element={<TherapistApplicationsPage />} />
              <Route path="patients" element={<TherapistPatientsPage />} />
              <Route path="patients/child/:profileId" element={<ChildProfilePage />} />
              <Route path="patients/child/:profileId/notes" element={<NotesPage />} />
              <Route path="chat" element={<TherapistChatPage />} />
            </Route>
          )}
        </Route>
        <Route path="admin_panel" element={<Outlet />}>
          {user?.user?.user_type === 'advisor' && (
            <Route path="advisor" element={<Outlet />}>
              <Route path="applications" element={<AdvisorApplicationsPage />} />
              <Route path="chat" element={<AdvisorChatPage />} />
              <Route path="therapists" element={<AdvisorTherapistsPage />} />
              <Route path="profiles" element={<AdvisorParentsPage />} />
              <Route path="profiles/assign/:profileId" element={<AdvisorAssignTherapistsPage />} />
              <Route path="applications/:profileId" element={<AdvisorParentProfilePage />} />
              <Route path="applications/onboarding_children" element={<QuestionsPage />} />
              <Route path="applications/onboarding_children/summary" element={<SummaryPage />} />
            </Route>
          )}
          {user?.user?.user_type === 'admin' && (
            <Route path="admin" element={<Outlet />}>
              <Route path="advisors" element={<AdvisorsPage />} />
              <Route path="therapists" element={<AdvisorTherapistsPage />} />
              <Route path="parents" element={<ParentsAdminPage />} />
              <Route path="parents/:childId" element={<AdminParentProfilePage />} />
              <Route path="parents/:childId/notes" element={<NotesPage />} />
              <Route path="chat" element={<AdminChatPage />} />
              <Route path="meetings" element={<AdminMeetingsPage />} />
            </Route>
          )}
          {user?.user?.user_type === 'supervisor' && <Route path="admin/parents" element={<ParentsPage />} />}
          {user?.user?.user_type === 'supervisor' && (
            <>
              <Route path="children/therapist_info" element={<ChildrenHistoryTherapistDetailsPage />} />
              <Route path="children/history" element={<ChildViewHistoryPage />} />
            </>
          )}
          {user?.user?.user_type === 'supervisor' && <Route path="advisors" element={<AdvisorsPage />} />}
        </Route>
        <Route path="payment" element={<PaymentPage />} />
      </Route>
      <Route path="*" element={<Error404Page />} />
      <Route path="/login" element={<SignInPage />} />
    </Routes>
  )
}
