// Styles
import { string } from 'prop-types'
import Styles from './styles.module.scss'

export const Placeholder = ({ text }) => {
  return (
    <div className={Styles.placeholder}>
      <p>{text}</p>
    </div>
  )
}

Placeholder.propTypes = {
  text: string
}

