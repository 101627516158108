// Instruments
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import * as Yup from 'yup'

export const schemaValid = () => {
  const maxDate = new Date()
  const minDate = new Date()

  maxDate.setFullYear(maxDate.getFullYear() - 18)
  minDate.setFullYear(minDate.getFullYear() - 1)

  return yupResolver(
    Yup.object().shape({
      child_name: Yup.string()
        .required('This field is required')
        .max(30, 'Your name should be at least 3-30 characters long')
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, 'Your name should not contain numbers'),
      birth_date: Yup.date()
        .typeError('Invalid date!')
        .test('not-in-future', 'Invalid date', (value) => !value || value <= new Date())
        .max(minDate, `Date must be at least 1 year ago`)
        .min(maxDate, `Child must be younger`)
        .nullable(true)
        .required('This field is required'),
      concerns: Yup.array()
        .of(
          Yup.lazy((value) => {
            switch (typeof value) {
              case 'object':
                return Yup.object().shape({
                  value: Yup.string().min(3, 'This field should be at least 3 characters long'),
                })
              case 'string':
                return Yup.string().min(3, 'This field should be at least 3 characters long')
              default:
                return Yup.mixed()
            }
          }),
        )
        .nullable(true)
        .required('This field is required')
        .min(1, 'This field should be at least 1 option'),
    }),
  )
}
