//Core
import { useSelector } from 'react-redux'
//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
//Components
import { TextAreaField } from '../Fields'
import { Spinner } from '../../ChatSystem/Common/Spinner'
// Instruments
import { useForm } from 'react-hook-form'

export const ConfirmationPopup = ({ open, onClose, content, width, isDeclinePopup }) => {
  const { title, secondaryText, buttons } = content

  const loading = useSelector((state) => state.general.modalLoading)

  const { handleSubmit, control } = useForm({
    mode: 'all',
    defaultValues: {
      reason: '',
    },
  })

  return (
    <Modal open={open} onClose={onClose} className={Styles.modal}>
      <div className={Styles.main_container} style={{ width }}>
        <CloseIcon className={Styles.icon} onClick={onClose} />
        <div>
          <h6>{title}</h6>
          <div>
            {secondaryText && <p className={secondaryText.style}>{secondaryText.text}</p>}
            {isDeclinePopup && <TextAreaField control={control} name={`reason`} label={' '} placeholder={'The reason'} inputClass={Styles.input_text_area} />}
          </div>
        </div>
        <div className={buttons.direction === 'vertical' ? Styles.btns_vertical : Styles.btns_horizontal}>
          {buttons.content?.map((button) => {
            if (isDeclinePopup) {
              return (
                <button key={button.id} className={`${button.style}`} onClick={handleSubmit(button.onClick)}>
                  {button.text}
                </button>
              )
            } else {
              return (
                <button key={button.id} className={`${button.style}`} onClick={button.onClick}>
                  {button.text}
                </button>
              )
            }
          })}
        </div>
        {loading && (
          <div className={Styles.loading_conatiner}>
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  )
}
