// Core
import { useState } from 'react'
import { string, object, bool, shape } from 'prop-types'
// Styles
import Styles from './styles.module.scss'
// Images
import eye_invisible from '../../../theme/assets/icons/eye_visible.svg'
import eye_visible from '../../../theme/assets/icons/eye_invisible.svg'
import errorIcon from '../../../theme/assets/icons/error.svg'

export const TextFieldPassword = (props) => {
  if (!props.register || !props.label) return null

  const [showPass, setShowPass] = useState(false)

  return (
    <div className={`${Styles.password_wrapper} ${props?.class}`}>
      <label>
        {props.label}
        <span>{props?.requiredIcon}</span>
      </label>
      <input
        autoComplete="new-password"
        type={!showPass ? 'password' : 'text'}
        autoFocus={props?.autoFocus}
        placeholder={props?.placeholder}
        disabled={props?.disabled}
        {...props.register}
      />
      <div className={`${props?.error ? Styles.icon_wrap_right : Styles.icon_wrap} ${props?.icon_class}`} onClick={() => setShowPass(!showPass)}>
        <img src={!showPass ? eye_visible : eye_invisible} alt="view_icon" />
      </div>
      {props?.error && (
        <div className={`${Styles.error_wraper} ${props?.error_wraper}`}>
          {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
          <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
        </div>
      )}
    </div>
  )
}

TextFieldPassword.propTypes = {
  register: object,
  label: string,
  class: string,
  requiredIcon: string,
  autoFocus: bool,
  placeholder: string,
  disabled: bool,
  error: shape({
    message: string,
  }),
}
