// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'

export const schemaValid = () => {
  return yupResolver(
    Yup.object().shape({
      full_name: Yup.string()
        .required('Please enter your full name')
        .min(3, 'Your name should be at least 3-30 characters long')
        .max(30, 'Your name should be at least 3-30 characters long')
        .matches(/^[a-z\s]*$/i, 'Your name should not contain numbers'),
      password: Yup.string()
        .required('Please enter password')
        .min(7, 'Password should contain more than 7 symbols')
        .max(30, 'Password should contain no more than 30 symbols')
        .matches(
          /.*([a-z]+[A-Z]+[0-9]+|[a-z]+[0-9]+[A-Z]+|[A-Z]+[a-z]+[0-9]+|[A-Z]+[0-9]+[a-z]+|[0-9]+[a-z]+[A-Z]+|[0-9]+[A-Z]+[a-z]+).*/,
          'Include at least 1 digit number, 1 lowercase and 1 uppercase letter',
        ),
    }),
  )
}
