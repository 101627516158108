/**
 * @fileoverview Redux slice for Zoom-related state management
 * @module zoomSlice
 */

//Core
import { createSlice } from '@reduxjs/toolkit'

/**
 * Zoom slice
 * @type {Slice}
 * @property {string} name - The name of the slice.
 * @property {Object} initialState - The initial state of the slice.
 * @property {boolean} initialState.isOpenZoomModal - Indicates if the Zoom modal is open.
 * @property {string} initialState.isSelectedOpenZoomModal - ID of the selected Zoom modal.
 * @property {Object} initialState.meetingInfo - Information about the Zoom meeting.
 * @property {Object} initialState.sessionNote - Information about the notes for the session.
 * @property {Object} reducers - The slice reducers.
 * @property {Function} reducers.setMeetingInfo - Sets the meeting information.
 * @property {Function} reducers.setIsOpenZoomModal - Opens the Zoom modal.
 * @property {Function} reducers.setIsSelectedOpenZoomModal - Sets the selected Zoom modal ID.
 * @property {Function} reducers.setSessionNote - Sets the session note.
 */

export const zoomSlice = createSlice({
  name: 'zoom',
  initialState: {
    isOpenZoomModal: false,
    isSelectedOpenZoomModal: '',
    meetingInfo: {},
    sessionNote: {},
  },
  reducers: {
    /**
     * Sets the zoom meeting information.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new zoom meeting information.
     */
    setMeetingInfo: (state, action) => {
      state.meetingInfo = action.payload
    },
    /**
     * Opens the Zoom modal.
     * @param {Object} state - The current state.
     */
    setIsOpenZoomModal: (state) => {
      state.isOpenZoomModal = true
    },
    /**
     * Sets the selected Zoom modal ID.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {string} action.payload - The selected Zoom modal ID.
     */
    setIsSelectedOpenZoomModal: (state, action) => {
      state.isSelectedOpenZoomModal = action.payload
    },
    /**
     * Sets the session note.
     * @param {Object} state - The current state.
     * @param {Object} action - The dispatched action.
     * @param {Object} action.payload - The new session note.
     */
    setSessionNote: (state, action) => {
      state.sessionNote = action.payload
    },
  },
})

/**
 * Action creators generated from the slice
 * @type {Object}
 * @property {Function} setMeetingInfo - Action creator for setting zoom meeting information.
 * @property {Function} setIsOpenZoomModal - Action creator for opening the Zoom modal.
 * @property {Function} setIsSelectedOpenZoomModal - Action creator for setting the selected Zoom modal ID.
 * @property {Function} setSessionNote - Action creator for setting the session note.
 */
export const { setMeetingInfo, setIsOpenZoomModal, setIsSelectedOpenZoomModal, setSessionNote } = zoomSlice.actions

export default zoomSlice.reducer
