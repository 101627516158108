//Core
import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
//Components
import {
  ParentFormRegistration,
  ParentOnboardingAddChild,
  ParentOnboardingFamilyDynamics,
  ParentOnboardingOptionalDisclaimer,
  ParentOnboardingParentalWellbeing,
  ParentOnboardingReport,
  ParentOnboardingSuccess,
  QuestionPartMandatory,
} from '../../components/Auth'
// Enums
import { ONBOARDING_STEP, PARENT_ONBOARDING_COOKIES_NAME } from '../../data/enums'
// Utils
import { getLocalStorage, setLocalStorage } from '../../utils'

export const ParentOnboardingPage = () => {
  const [onboardingStep, setOnboardingStep] = useState(ONBOARDING_STEP.ADD_CHILD)

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (!searchParams?.get('lng')) {
      setSearchParams({ lng: 'en' })
    }
  }, [])

  useEffect(() => {
    const onboardingStateFromLocalStorage = JSON.parse(getLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING))

    if (onboardingStateFromLocalStorage) {
      setOnboardingStep(onboardingStateFromLocalStorage?.state)
    } else {
      const parentOnboardingObject = {
        state: ONBOARDING_STEP.ADD_CHILD,
        report_loading: true,
      }

      setLocalStorage(PARENT_ONBOARDING_COOKIES_NAME.PARENT_ONBOARDING, JSON.stringify(parentOnboardingObject))
    }
  }, [])

  return (
    <>
      {onboardingStep === ONBOARDING_STEP.ADD_CHILD && <ParentOnboardingAddChild setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.QUESTIONNAIRE && <QuestionPartMandatory setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.FAMILY_DYNAMICS} setOnboardingStep={setOnboardingStep} />
      )}
      {onboardingStep === ONBOARDING_STEP.FAMILY_DYNAMICS && <ParentOnboardingFamilyDynamics setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING_DISCLAIMER && (
        <ParentOnboardingOptionalDisclaimer sectionName={ONBOARDING_STEP.PARENTAL_WELLBEING} setOnboardingStep={setOnboardingStep} />
      )}
      {onboardingStep === ONBOARDING_STEP.PARENTAL_WELLBEING && <ParentOnboardingParentalWellbeing setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.REPORT && <ParentOnboardingReport setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.FORM_REGISTRATION && <ParentFormRegistration setOnboardingStep={setOnboardingStep} />}
      {onboardingStep === ONBOARDING_STEP.SUCCESS && <ParentOnboardingSuccess />}
    </>
  )
}
