import { shape, number, string, bool, array, arrayOf } from 'prop-types'

export const TherapistType = shape({
  id: string,
  user: shape({
    profile_pic: string,
    full_name: string,
    country: string,
  }),
  comment: shape({
    text: string,
  }),
  cost: bool,
  session_price: number,
  professional_qualities: array,
  education: array,
  licence: array,
  age: string,
  languages: array,
  j_advantage: array,
  provide_clinical: array,
  services: array,
  experience: array,
  age_groups: array,
  expertise: array,
  meet_link: string,
  nationality: string,
})

export const ChannelType = shape({
  contact_avatar: string,
  contact_name: string,
  // online: bool, not yet implemented on the backend
  unread_messages: number,
  room_id: string,
  id: number,
  messages_history: arrayOf(
    shape({
      author: number,
      created_at: string,
      text: string,
      viewed: bool,
    }),
  ),
})
