//MUI
import { Modal } from '@mui/material'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
//Core
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
//Components
import { TextAreaField, TextFieldAdornment } from '../../../GeneralComponents/Fields'
import { Spinner } from '../../../ChatSystem/Common/Spinner'
//Actions
import { therapistActions } from '../../../../actions'

export const CreateNotePopup = ({ open, onClose, childId }) => {
  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.modalLoading)

  const { handleSubmit, control, register } = useForm({
    mode: 'all',
    defaultValues: {
      notes: '',
      title: '',
    },
  })

  const submit = (data) => {
    const dataForSubmit = {
      token: user.token,
      id: childId,
      payload: {
        note: data?.notes,
        title: data?.title,
      },
    }

    dispatch(therapistActions.createNote(dataForSubmit)).then((res) => onClose())
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <TextFieldAdornment class={Styles.field_class} placeholder={'New note'} register={register('title')} />
          <CloseIcon className={Styles.icon} onClick={onClose} />
        </div>
        <TextAreaField control={control} name={'notes'} label={' '} placeholder={'Write your note...'} inputClass={Styles.input_text_area} />
        <div className={Styles.btns}>
          <button className={Styles.btns__next} onClick={handleSubmit(submit)}>
            Save
          </button>
          <button className={Styles.btns__cancel} onClick={onClose}>
            Cancel
          </button>
        </div>
        {loading && (
          <div className={Styles.loading_conatiner}>
            <Spinner />
          </div>
        )}
      </div>
    </Modal>
  )
}
