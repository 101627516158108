// Instruments
import axios from 'axios'
// Constants
import { apiPath } from '../constants'

export const verificationApi = {
  createVerificationLink: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/api-token-auth/request-login-url/`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(data.payload)
    })
  },
  verifyToken: async (data) => {
    return axios({
      method: 'POST',
      url: `${apiPath}/api-token-auth/verify-login-url/${data.token}/`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}