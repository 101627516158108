//Core
import { useState, useEffect } from 'react'
//Styles
import Styles from './styles.module.scss'
//Icons
import { ReactComponent as ArrowDownIcon } from '../../../../../theme/assets/icons/arrow_down_icon.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../../theme/assets/icons/arrow_up_icon.svg'
//Components
import { UserAvatar } from '../../UserAvatar'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'

const ChanelInstance = ({ channel, currentUserId, currentClientId, isCollapsedChannel, selectedClientChannel, isProfile }) => {
  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'channel_preview', en)
    i18n.addResourceBundle('ar', 'channel_preview', ar)
  }, [])

  return (
    <div className={`${Styles.channel__participant_container}`}>
      <div className={Styles.channel__participant_container__info}>
        <UserAvatar avatar={channel?.participant?.profile_pic || channel?.profile_pic} />
        <div className={Styles.channel__participant_container__info__txt}>
          {channel?.participant?.user_name || channel?.profile_name ? (
            <p>{channel?.participant?.user_name || channel?.profile_name}</p>
          ) : (
            <p className={Styles.new_profile}>New profile</p>
          )}
          {channel?.participant?.user_role && channel?.participant?.user_role !== 'parent' ? (
            <span>
              {channel?.chats[0]?.last_message?.author === currentUserId && t('channel_preview:you')}
              {channel?.chats[0]?.last_message?.text || t('channel_preview:start_conversation')}
            </span>
          ) : channel?.chats?.length > 1 ? (
            <span>{t('channel_preview:open')}</span>
          ) : (
            <span>
              {((channel?.chats && channel?.chats[0]?.last_message?.author === currentUserId) || channel?.last_message?.author === currentUserId) && t('channel_preview:you')}
              {(channel?.chats && channel?.chats[0]?.last_message?.text) || channel?.last_message?.text || t('channel_preview:start_conversation')}
            </span>
          )}
        </div>
      </div>
      <div className={Styles.channel__participant_container__collapse}>
        {(channel?.participant?.unread_messages_for_all_chats > 0 || channel?.unread_messages > 0) && channel?.chats?.[0]?.last_message?.author !== currentUserId && (
          <div className={Styles.channel__participant_container__collapse__circle}>{channel?.participant?.unread_messages_for_all_chats || channel?.unread_messages}</div>
        )}
        {!isProfile &&
          channel?.participant?.user_role === 'parent' &&
          channel?.chats?.length > 1 &&
          (selectedClientChannel === currentClientId ? <ArrowUpIcon /> : <ArrowDownIcon />)}
      </div>
    </div>
  )
}

export const ChannelPreview = ({ channel, currentUserId, chatSelection, selectedChannelId, selectedClientChannel }) => {
  const {
    participant: { user_id },
    chats,
  } = channel

  const [isCollapsedChannel, setIsCollapsedChannel] = useState(false)

  const handleSelectChannel = (channelInstance, isProfile, clientChatId) => {
    if (channel?.chats?.length > 1) {
      setIsCollapsedChannel(true)
    }

    if (channelInstance?.chats?.length > 1) {
      chatSelection(0, 0, channelInstance?.participant?.user_id, channelInstance?.participant?.user_id)
    } else {
      if (isProfile) {
        chatSelection(channelInstance?.room_id, channelInstance?.chat_id, channelInstance?.chat_id, clientChatId)
      } else {
        chatSelection(channelInstance?.chats[0]?.room_id, channelInstance?.chats[0]?.chat_id, channelInstance?.chats[0]?.chat_id, channelInstance?.participant?.user_id)
      }
    }
  }

  return (
    <>
      <div
        onClick={() => handleSelectChannel(channel)}
        className={`${Styles.channel}  ${(selectedClientChannel === user_id || selectedChannelId === chats[0]?.chat_id) && Styles.selected_channel}`}
        key={user_id}
      >
        <ChanelInstance
          channel={channel}
          currentUserId={currentUserId}
          isCollapsedChannel={isCollapsedChannel}
          selectedChannel={selectedChannelId}
          currentClientId={user_id}
          selectedClientChannel={selectedClientChannel}
        />
      </div>
      {isCollapsedChannel &&
        selectedClientChannel === user_id &&
        chats?.map((chat) => (
          <div
            onClick={() => handleSelectChannel(chat, true, channel?.participant?.user_id)}
            className={`${Styles.channel__profiles} ${selectedChannelId === chat?.chat_id && Styles.selected_profile}`}
          >
            <ChanelInstance key={chat.id} channel={chat} currentUserId={currentUserId} selectedClientChannel={selectedClientChannel || 0} currentClientId={user_id} />
          </div>
        ))}
    </>
  )
}
