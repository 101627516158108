//Core
import { shape, string, number } from 'prop-types'

//Components
import { Spinner } from '../../Spinner'

//Styles
import Styles from './styles.module.scss'

//Utils
import { convertBytes } from '../../../../../utils'

export const FileAttachmentLoader = ({ file }) => {
  const { name, size } = file
  return (
    <div className={Styles.fileAttachmentLoader}>
      <div className={Styles.fileAttachmentLoader__content}>
        <div>
          <Spinner />
        </div>
        <div className={Styles.fileAttachmentLoader__text}>
          <span>
            {name}
          </span>
          <p>{convertBytes(size)}</p>
        </div>
      </div>
    </div>
  )
}

FileAttachmentLoader.propTypes = {
  file: shape({
    name: string,
    size: number,
    url: string
  })
}