import Styles from './styles.module.scss'

export const Spinner = ({ background = '#f3f3f3', color = '#8450a0', width = '40px' }) => {
  return (
    <div>
      <div style={{ width: width, height: width }} className={Styles.loadingSpinner}>
        <div style={{ borderColor: background, borderTopColor: color }} className={Styles.loadingSpinnerInner}></div>
      </div>
    </div>
  )
}
