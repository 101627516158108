// Core
import { useEffect, useState } from 'react'
//Styles
import Styles from './Styles.module.scss'
//MUI
import { Modal, Stepper, Step, StepLabel, StepContent } from '@mui/material'
import styled from '@emotion/styled'
//Icons
import { ReactComponent as CloseIcon } from '../../../theme/assets/icons/close_updated.svg'
// i18n
import { useTranslation } from 'react-i18next'
import i18n from '../../../i18n'
import en from './locale/en.json'
import ar from './locale/ar.json'
// Components
import { CheckboxField } from '../Fields'
//Form
import { useForm } from 'react-hook-form'

export const StepperModal = ({ open, onClose, data }) => {
  const { t } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language'))

  useEffect(() => {
    setSelectedLanguage(localStorage.getItem('language'))
  }, [localStorage.getItem('language')])

  useEffect(() => {
    i18n.addResourceBundle('en', 'hint_modal', en)
    i18n.addResourceBundle('ar', 'hint_modal', ar)
  }, [])

  //Styles
  const CustomStep = styled(Step)`
    & .MuiSvgIcon-root {
      color: #c7bcce;
    }
    ,
    & .MuiStepIcon-root.Mui-active {
      color: #8450a0 !important;
      font-family: 'Poppins_Regular';
      font-weight: 400;
    }
    ,
    & .MuiStepLabel-label.Mui-disabled {
      font-family: 'Poppins_Regular';
    }
    ,
    & .MuiStepLabel-label {
      font-size: 16px !important;
      font-family: 'Poppins_Regular';
      font-weight: 600;
      color: #8450a0 !important;
    }
    ,
    & .MuiStepIcon-root.Mui-completed {
      color: #c7bcce !important;
    }
    & .MuiCollapse-wrapperInner {
      color: #9b9b9b !important;
      font-size: 14px !important;
      margin-left: 5px;
      font-family: 'Poppins_Regular';
    }
  `

  const { handleSubmit, setValue, control } = useForm({
    mode: 'all',
    defaultValues: {
      is_dont_show: false,
    },
  })

  const handleCloseModal = (formData) => {
    if (formData?.is_dont_show) {
      data?.action()
    } else {
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.stepper}>
          <Stepper orientation="vertical">
            {data?.data?.map((step) => (
              <CustomStep active={true} key={step?.id}>
                <StepLabel>{step?.label}</StepLabel>
                <StepContent>{step?.description}</StepContent>
              </CustomStep>
            ))}
          </Stepper>
        </div>
        <div style={{ justifyContent: data?.isFirstView ? 'space-between' : 'flex-end' }} className={Styles.footer}>
          {data?.isFirstView && (
            <div>
              <CheckboxField
                class={Styles.check_box}
                control={control}
                name={'is_dont_show'}
                onCustomChange={(value) => setValue('is_dont_show', value)}
                children={<div className={Styles.check_box__content}>{t('hint_modal:dont_show')}</div>}
              />
            </div>
          )}
          <div onClick={handleSubmit(handleCloseModal)} className={Styles.btn}>
            {t('hint_modal:close')}
          </div>
        </div>
      </div>
    </Modal>
  )
}
