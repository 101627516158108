// Core
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
// Instruments
import { useForm } from 'react-hook-form'
// Actions
import { clearError, setError } from '../../../reducers/generalSlice'
import { authActions } from '../../../actions'
import { verificationActions } from '../../../actions'
// Validation
import { schemaValid } from './validLogic'
// Styles
import Styles from './styles.module.scss'
// Components
import { TextField, TextFieldPassword, Loader, SaveButton } from '../../GeneralComponents'

export const AdvisorSignUpNewPassword = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const token = searchParams.get('token')

  const dispatch = useDispatch()

  const loading = useSelector((state) => state.general.loading)
  const error = useSelector((state) => state.general.error)

  const {
    register,
    formState: { errors, isValid },
    handleSubmit,
  } = useForm({
    mode: 'all',
    resolver: schemaValid(),
    defaultValues: {
      full_name: '',
      password: '',
    },
  })

  const onFormSubmit = (values) => {
    if (!token) {
      dispatch(clearError())
      dispatch(setError('Something went wrong, please try again later!'))

      return null
    }

    const data = {
      update: {
        full_name: values.full_name,
        password: values.password,
      },
      token: token,
    }

    dispatch(authActions.signupUpdateAdvisor(data))
  }

  return (
    <section className={Styles.signup_form}>
      {error && (
        <div className={Styles.signup_error}>
          <span />
          <span>{error}</span>
        </div>
      )}
      <div className={Styles.form_container}>
        <h4 className={Styles.signup_caption}>{'Sign up'}</h4>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <TextField label={'Full name'} requiredIcon={' *'} class={Styles.form_item} placeholder={'Enter your name'} error={errors?.full_name} register={register('full_name')} />
          <TextFieldPassword
            label={'Password'}
            requiredIcon={' *'}
            class={Styles.form_item}
            placeholder={'Create a password'}
            error={errors?.password}
            register={register('password')}
          />
          {loading ? <Loader class={Styles.loader} /> : <SaveButton class={Styles.signup_btn} isValid={isValid} title={'Sign up'} />}
        </form>
      </div>
    </section>
  )
}
