// Core
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// Instruments
import Cropper from 'react-easy-crop'
// Actions
import { setError } from '../../../reducers/generalSlice'
import { setAvatar } from '../../../reducers/profileSlice'
import { profileActions, settingsActions } from '../../../actions'
// Utils
import { toBase64 } from '../../../utils'
// Styles
import Styles from './styles.module.scss'
// Images
import mockAvatar from '../../../theme/assets/images/avatar.svg'

export const CreateAvatar = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.auth.user)
  const parentChild = useSelector((state) => state.profile.parentChild)
  const avatar = useSelector((state) => state.profile.avatar)

  const [avatarState, setAvatarState] = useState('')
  const [cropState, setCropState] = useState({ x: 0, y: 0 })
  const [zoomState, setZoomState] = useState(1)
  const [croppedAreaPixelsState, setCroppedAreaPixelsState] = useState(null)
  const [croppedAvatar, setCroppedAvatar] = useState('')

  useEffect(() => {
    dispatch(setError(''))
  }, [])

  const onAvatarChange = async (event) => {
    if (croppedAvatar) {
      setCroppedAvatar('')
      dispatch(setAvatar(''))
    }

    const file = event.target.files[0]

    if (!file) return null

    const fileType = file && file.type.split('/')[1]

    if (file.size > 5 * 1000 * 1000) {
      event.target.value = ''

      return dispatch(setError('Max size: 5 MB'))
    }

    if (fileType !== 'jpg' && fileType !== 'jpeg' && fileType !== 'png') {
      event.target.value = ''

      return dispatch(setError('Available formats: JPEG, JPG, PNG'))
    }

    if (file) {
      const avatarUrl = await toBase64(file)
      setAvatarState(avatarUrl)
      event.target.value = ''
      dispatch(setError(''))
    }
  }

  const onCropChange = (crop) => {
    setCropState(crop)
  }

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixelsState(croppedAreaPixels)
  }

  const onZoomChange = (zoom) => {
    setZoomState(zoom)
  }

  const onCropAvatar = () => {
    const data = {
      imgUrl: avatarState,
      pixelCrop: croppedAreaPixelsState,
    }

    if (user?.user?.user_type === 'therapist') {
      dispatch(settingsActions.cropUserAvatar(data))
    }

    if (user?.user?.user_type === 'parent') {
      dispatch(profileActions.cropChildAvatar(data))
    }

    setAvatarState('')
  }
  return (
    <div className={Styles.container}>
      <input type="file" id="avatar" name="avatar" onChange={onAvatarChange} className={Styles.avatar_input} />
      {!avatarState ? (
        <div className={Styles.avatar}>
          {user?.user?.user_type === 'therapist' && <img src={avatar || user?.user?.profile_pic || mockAvatar} className={Styles.avatar_img} alt="avatar image" />}
          {user?.user?.user_type === 'parent' && <img src={avatar || parentChild?.profile_pic || mockAvatar} className={Styles.avatar_img} alt="avatar image" />}
          <label className={Styles.avatar_label} htmlFor="avatar"></label>
        </div>
      ) : (
        <div className={Styles.cropper}>
          <Cropper
            image={avatarState}
            crop={cropState}
            maxZoom={5}
            zoom={zoomState}
            aspect={1}
            cropShape={'round'}
            restrictPosition={false}
            onCropChange={onCropChange}
            onCropComplete={onCropComplete}
            onZoomChange={onZoomChange}
          />
          <span className={Styles.crop_btn} onClick={onCropAvatar}>
            {'Apply changes'}
          </span>
        </div>
      )}
    </div>
  )
}
