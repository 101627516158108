/**
 * Functional component for managing and displaying applications.
 *
 * @component
 */

//Styles
import Styles from './styles.module.scss'
//Core
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
//Actions
import { advisorOnbordingActions, zoomActions } from '../../actions'
import { setIsOpenZoomModal } from '../../reducers/zoomSlice'
//Components
import { Table } from './Table'
import { Spinner } from '../ChatSystem/Common/Spinner'
import { NoDataComponent, CustomPagination } from '../GeneralComponents'
import { SeeResultsPopup } from '../OnbordingParent/SeeResultsPopup'
//MUI
import { InputBase } from '@mui/material'
//Icons
import { ReactComponent as SearchIcon } from '../../theme/assets/icons/search_convex.svg'
//Utils
import { paginateArray } from '../../utils'

export const Applies = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state) => state.auth.user)
  const allApplications = useSelector((state) => state.advisorOnbording.allApplies)
  const loading = useSelector((state) => state.general.loading)

  const [applications, setApplications] = useState([])
  const [selectedTab, setSelectedTab] = useState('all')

  const [isOpenResults, setIsOpenResults] = useState(false)
  const [isAdult, setIsAdult] = useState(false)

  const [refetch, setRefetch] = useState(false)

  const [currentPage, setCurrentPage] = useState(1)
  const [dataForPagination, setDataForPagination] = useState([])

  /**
   * Fetches all applications when the component is mounted or when `refetch` or `user` changes.
   */

  useEffect(() => {
    const data = {
      token: user.token,
    }
    dispatch(advisorOnbordingActions.getAllApplies(data))
  }, [refetch, user])

  /**
   * Updates the applications state with paginated data whenever `allApplications` changes.
   */

  useEffect(() => {
    const paginatedData = paginateArray(allApplications)
    if (paginatedData.length) {
      setApplications([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setApplications([])
    }
  }, [allApplications])

  /**
   * Updates the applications state based on the selected tab and current page.
   */

  useEffect(() => {
    if (selectedTab === 'all') {
      const paginatedData = paginateArray(allApplications)
      setDataForPagination(allApplications)
      if (paginatedData.length) {
        setApplications([...(paginatedData[currentPage - 1] || 0)])
      } else {
        setApplications([])
      }
      return
    }

    const sortedData = allApplications.filter((application) => application.status === selectedTab)
    const paginatedData = paginateArray(sortedData)
    if (paginatedData.length) {
      setDataForPagination(sortedData)
      setApplications([...(paginatedData[currentPage - 1] || 0)])
    } else {
      setDataForPagination(sortedData)
      setApplications([])
    }
  }, [selectedTab, currentPage])

  const headers = [
    { text: 'DATE CREATED', sorting: true },
    { text: 'NAME OF APPLIER', sorting: true },
    { text: 'PHONE', sorting: true },
    { text: 'PROBLEM', sorting: true },
    { text: 'STATUS', sorting: true },
    { text: 'MEETING TIME', sorting: true },
    { text: 'ACTION', sorting: false },
  ]

  /**
   * Sets the selected tab.
   *
   * @param {string} tabName - The name of the tab to select.
   */

  const handleSelectTab = (tabName) => {
    setSelectedTab(tabName)
  }

  const handleChangeInput = (e) => {
    const inputValue = e.target.value.toLowerCase()

    const filteredArray = allApplications.filter((application) => application.full_name.toLowerCase().includes(inputValue) || application.email.toLowerCase().includes(inputValue))
    setApplications(filteredArray)
  }

  /**
   * Opens the results popup and fetches application details.
   *
   * @param {string} meetingId - The ID of the meeting.
   * @param {boolean} isAdult - Indicates if the application is for an adult.
   */

  const handleOpenResultsPopup = (meetingId, isAdult) => {
    const data = {
      meeting_id: meetingId,
      token: user.token,
    }
    dispatch(advisorOnbordingActions.getOneApply(data))
    setIsOpenResults(true)
    setIsAdult(isAdult)
  }

  /**
   * Closes the results popup.
   */

  const handleCloseResultsPopup = () => setIsOpenResults(false)

  /**
   * Joins a meeting and updates the application status.
   *
   * @param {string} meetingId - The ID of the meeting.
   * @param {string} zoomMeetingId - The ID of the Zoom meeting.
   */

  const handleJoinMeeting = (meetingId, zoomMeetingId) => {
    if (meetingId) {
      const data = {
        id: meetingId,
        token: user.token,
        payload: {
          status: 'in_a_meeting',
        },
      }
      dispatch(advisorOnbordingActions.changeStatusApplies(data))
      setTimeout(() => {
        setRefetch((prev) => !prev)
      }, 2000)
    }

    const dataForZoom = {
      id: zoomMeetingId,
      token: user.token,
    }
    dispatch(zoomActions.getMeetingInformation(dataForZoom))
    dispatch(setIsOpenZoomModal())
  }

  /**
   * Starts the onboarding process.
   *
   * @param {Object} data - The onboarding data.
   * @param {boolean} isAdult - Indicates if the onboarding is for an adult.
   */

  const handleStartOnboarding = (data, isAdult, childData) => {
    dispatch(advisorOnbordingActions.clearOnboardingDetails())
    dispatch(advisorOnbordingActions.setParent(data))
    dispatch(advisorOnbordingActions.setChildInfo(childData))
    navigate(`onboarding_children?type=${isAdult ? 'adult' : 'child'}`)
  }

  const handleOpenUserDetails = (profileId) => navigate(`${profileId}`)

  /**
   * Event handlers for button clicks.
   *
   * @type {Object}
   * @property {function(string, boolean): void} openResults - Opens the results popup.
   * @property {function(Object, boolean): void} startOnboarding - Starts the onboarding process.
   * @property {function(string, string): void} joinMeeting - Joins a meeting.
   */

  const onClickEvents = {
    openResults: handleOpenResultsPopup,
    startOnboarding: handleStartOnboarding,
    joinMeeting: handleJoinMeeting,
    openUserDetails: handleOpenUserDetails,
  }

  return (
    <div className={Styles.main_container}>
      <div className={Styles.header}>
        <div className={Styles.header__first_block}>
          <h6>Applications</h6>
          <div className={Styles.header__search}>
            <div className={Styles.header__search__container}>
              <SearchIcon />
              <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search by name or email" onChange={(e) => handleChangeInput(e)} className={Styles.header__search__container__text} />
            </div>
          </div>
        </div>
        <div className={Styles.tabs}>
          <p className={selectedTab === 'all' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('all')}>
            All
          </p>
          <p className={selectedTab === 'planned' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('planned')}>
            Planned meeting
          </p>
          <p className={selectedTab === 'accepted' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('accepted')}>
            Accepted
          </p>
          <p className={selectedTab === 'declined' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('declined')}>
            Declined
          </p>
          <p className={selectedTab === 'failed' ? Styles.tabs__selected_tab : ''} onClick={() => handleSelectTab('failed')}>
            Failed
          </p>
        </div>
      </div>
      {loading ? (
        <Spinner />
      ) : allApplications.length ? (
        <div className={Styles.data_container}>
          <Table data={applications} headers={headers} userType={user?.user?.user_type} userDataType={'application'} onClickEvents={onClickEvents} />
        </div>
      ) : (
        <NoDataComponent text={'There is no applications yet.'} />
      )}
      <CustomPagination dataArray={dataForPagination} currentPage={currentPage} setCurrentPage={setCurrentPage} />
      {isOpenResults && <SeeResultsPopup open={isOpenResults} isAdult={isAdult} onClose={handleCloseResultsPopup} />}
    </div>
  )
}
