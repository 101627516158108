// Core
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
// Styles
import Styles from './styles.module.scss'
// Images
import error_404 from '../../../theme/assets/images/error_404.png'
// Components
import { HeaderSection } from '../../GeneralComponents'
//Utils
import { deleteCookie } from '../../../utils'
import { setUser } from '../../../reducers/authSlice'

export const Error404 = () => {
  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [isResettingShown, setIsResettingShown] = useState(false)

  const user = useSelector((state) => state.auth.user)

  const getHomePage = () => {
    switch (user?.user?.user_type) {
      case 'therapist':
        return '/profile/therapist/applications'
      case 'parent':
        return '/profile/parent/therapy'
      case 'advisor':
        return '/admin_panel/advisor/applications'
      case 'admin':
        return '/admin_panel/admin/advisors'
      default:
        return '/login'
    }
  }

  const handleGoHome = () => {
    if (!user?.user?.user_type) {
      navigate('/login')
      localStorage.removeItem('token')
      deleteCookie('user')
    } else {
      navigate(getHomePage())
    }
    setIsResettingShown(true)
  }

  const handleResetData = () => {
    dispatch(setUser({}))
    localStorage.removeItem('token')
    deleteCookie('user')
    navigate('/login')
  }

  return (
    <section className={Styles.container}>
      <HeaderSection header_title={'Error 404 - Page Not Found'} />
      <div className={Styles.wrapper}>
        <div className={Styles.content}>
          <div className={Styles.content_title}>{'404'}</div>
          <div className={Styles.content_info}>{'Page Not Found'}</div>
          <div className={Styles.content_info}>
            {'This Page Does Not Exist. '}
            <div onClick={handleGoHome}>{'Back To Home'}</div>
          </div>
          {isResettingShown && (
            <div className={Styles.reset_container}>
              Still have problems?{' '}
              <span onClick={handleResetData} className={Styles.reset}>
                Reset the data
              </span>
            </div>
          )}
          <img src={error_404} className={Styles.content_img} alt={'error image'} />
        </div>
      </div>
    </section>
  )
}
