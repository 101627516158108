// Core
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
// Actions
import { authActions } from './actions'
// Utils
import { getCookie } from './utils'
// Navigation
import { Private, Public } from './navigations'
//Components
import { NewLoader } from './components/GeneralComponents/NewLoader'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state?.auth?.user)

  const [currentUser, setCurrentUser] = useState(null)

  const userStorage = JSON.parse(getCookie('user'))

  useEffect(() => {
    if (userStorage) {
      dispatch(authActions.getUser(userStorage))
    }
    setCurrentUser(userStorage)
  }, [])

  const route = () => {
    if (localStorage.getItem('token') && userStorage?.user?.user_type) {
      console.log('in private route')
      return <Private user={userStorage} />
    } else {
      console.log('in public route')
      return <Public />
    }
  }

  return route()
}

export default App
