// Styles
import Styles from './Styles.module.scss'
// Utils
import { getInitials, returnCountry } from '../../../utils'

export const GeneralProfileInformation = ({ profile, client, advisor }) => {
  return (
    <div className={Styles.main_container}>
      <div className={Styles.main_container__header}>
        <div className={Styles.main_container__header__initials}>{getInitials(profile?.full_name)}</div>
        <h6>{profile?.full_name}</h6>
      </div>
      <div className={Styles.main_container__profile_info}>
        <div className={Styles.main_container__profile_info__container}>
          <div>
            <p>Age:</p>
            <span>{profile?.age}</span>
          </div>
          <div>
            <p>Birthday:</p>
            <span>{profile?.birth_date}</span>
          </div>
        </div>
        <div className={Styles.main_container__profile_info__container}>
          <div>
            <p>Languages:</p>
            <span>{profile?.languages?.join(', ')}</span>
          </div>
          <div>
            <p>Problems:</p>
            <span>{profile?.concerns?.join(', ')}</span>
          </div>
        </div>
        <div className={Styles.main_container__profile_info__container}>
          <div style={{ width: '100%' }}>
            <p>Usual behavior:</p>
            <span>{profile?.behavior?.join(', ')}</span>
          </div>
        </div>
      </div>
      <div className={Styles.main_container__client_info}>
        <div className={Styles.main_container__client_info__container}>
          <div>
            <p>Client name:</p>
            <span>{client?.full_name}</span>
          </div>
          <div>
            <p>Country:</p>
            {returnCountry(client?.country)}
          </div>
        </div>
      </div>
      {advisor && (
        <div className={Styles.main_container__advisor_info}>
          <div className={Styles.main_container__advisor_info__initials}>{getInitials(advisor?.full_name)}</div>
          <div>
            <p>{advisor?.full_name}</p>
            <span>Advisor</span>
          </div>
        </div>
      )}
    </div>
  )
}
