// Instruments
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
//REGEX
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../../constants'

export const schemaValid = (language) => {
  return yupResolver(
    Yup.object().shape({
      email: Yup.string()
        .required(language === 'en' ? 'This field is required' : 'هذه الخانة مطلوبه')
        .matches(EMAIL_REGEX, language === 'en' ? 'Please enter a valid email address, e.g. joe@mail.com' : 'يرجى إدخال عنوان بريد إلكتروني صالح'),
      first_name: Yup.string()
        .required(language === 'en' ? 'This field is required' : 'هذه الخانة مطلوبه')
        .min(3, language === 'en' ? 'Your first name should be at least 3-30 characters long' : 'يجب أن يكون اسمك الأول مكونًا من 3 إلى 30 حرفًا على الأقل')
        .max(30, language === 'en' ? 'Your first name should be at least 3-30 characters long' : 'يجب أن يتراوح طول اسمك الأول بين 3 إلى 30 حرفًا')
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, language === 'en' ? 'Your first name should not contain numbers' : 'يجب ألا يحتوي اسمك الأول على أرقام'),
      last_name: Yup.string()
        .required(language === 'en' ? 'This field is required' : 'هذه الخانة مطلوبه')
        .min(3, language === 'en' ? 'Your last name should be at least 3-30 characters long' : 'يجب أن يتراوح طول اسم عائلتك بين 3 إلى 30 حرفًا')
        .max(30, language === 'en' ? 'Your last name should be at least 3-30 characters long' : 'يجب أن يتراوح طول اسم عائلتك بين 3 إلى 30 حرفًا')
        .matches(/^[a-zA-Z\u0600-\u06FF\s.'-]*$/i, language === 'en' ? 'Your last name should not contain numbers' : 'يجب ألا يحتوي اسم عائلتك على أرقام'),
      password: Yup.string()
        .required(language === 'en' ? 'This field is required' : 'هذه الخانة مطلوبه')
        .matches(
          PASSWORD_REGEX,
          language === 'en'
            ? 'Your password should be minimum eight characters, contain at least one letter, one number and special character'
            : 'يجب أن تتكون كلمة المرور الخاصة بك من ثمانية أحرف على الأقل، وأن تحتوي على حرف واحد على الأقل، ورقم واحد وحرف خاص',
        ),
      password_2: Yup.string()
        .required(language === 'en' ? 'This field is required' : 'هذه الخانة مطلوبه')
        .matches(
          PASSWORD_REGEX,
          language === 'en'
            ? 'Your password should be minimum eight characters, contain at least one letter, one number and special character'
            : 'يجب أن تتكون كلمة المرور الخاصة بك من ثمانية أحرف على الأقل، وأن تحتوي على حرف واحد على الأقل، ورقم واحد وحرف خاص',
        ),
      is_agree_terms_conditions: Yup.boolean().oneOf([true], language === 'en' ? 'You must agree to the terms and conditions' : 'يجب عليك الموافقة على الشروط والأحكام'),
      is_agree_privacy_policy: Yup.boolean().oneOf([true], language === 'en' ? 'You must agree to the privacy policy' : 'يجب عليك الموافقة على سياسة الخصوصية'),
    }),
  )
}
