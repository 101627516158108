// Core
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { func } from 'prop-types'
// Instruments
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
// Actions
import { adminActions } from '../../../../actions'
import { setStatus } from '../../../../reducers/generalSlice'
//MUI
import { Modal } from '@mui/material'
// Styles
import Styles from './styles.module.scss'
// Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
// Components
import { TextField } from '../../../GeneralComponents'

const schema = Yup.object().shape({
  email: Yup.string()
    .required('Please enter your email address')
    .matches(/^[_a-z0-9-\+-]+(\.[_a-z0-9-\+-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i, 'Please enter a valid email address, e.g. joe@mail.com'),
})

export const AddAdvisor = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.general.loading)
  const status = useSelector((state) => state.general.status)
  const user = useSelector((state) => state.auth.user)

  const {
    register,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
    },
  })

  const onFormSubmit = () => {
    const email = getValues('email')
    if (user.token) {
      const data = {
        credentials: {
          user_type: 'advisor',
          email,
        },
        token: user.token,
      }
      dispatch(adminActions.createAdvisor(data))
      onClose()
    }
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <CloseIcon className={Styles.icon} onClick={onClose} />
        <div className={Styles.content}>
          <h6>Add an advisor</h6>
          <div className={Styles.content__form}>
            <div className={Styles.content__form__item}>
              <TextField
                label={'Email'}
                class={Styles.content__form__text_field}
                placeholder={'example@gmail.com'}
                error={errors?.email}
                register={register('email')} />
            </div>
          </div>
        </div>
        <div className={Styles.buttons}>
          <button className={isValid ? Styles.btn__btn_save : Styles.btn__btn_save_disabled}
            onClick={onFormSubmit}
            disabled={!isValid}>
            Send invitation
          </button>
          <button className={Styles.btn__btn_cancel} onClick={onClose}>Cancel</button>
        </div>
      </div>
    </Modal>
  )
}

AddAdvisor.propTypes = {
  onClose: func,
}