// Instruments
import { Controller } from 'react-hook-form'
// Mui
import { Autocomplete, TextField, createFilterOptions } from '@mui/material'
// Actions
import { clearError } from '../../../reducers/generalSlice'
// Styles
import Styles from './styles.module.scss'
// Images
import errorIcon from '../../../theme/assets/icons/error.svg'

const filter = createFilterOptions()

export const TextFieldMultiple = (props) => {
  const handlerChange = (event, options) => {
    if (event.target.innerText === 'Add custom' || event.target?.innerText?.includes('Other')) {
      props.openCustomField(true)
      return
    }
    const filtered = options.filter((item) => (props.therapists ? item.value.user.full_name : item.value || item) === event.target.innerText)

    let newOptions = []
    if (filtered.length > 1) {
      newOptions = options.filter((item) => (props.therapists ? item.value.user.full_name : item.value || item) !== event.target.innerText)

      return props.setValue(props.name, newOptions, {
        shouldValidate: true,
      })
    }

    if (props?.setNewValue) {
      props?.setNewValue((prev) => [...options])
    }

    return props.setValue(props.name, options, {
      shouldValidate: true,
    })
  }

  if (!props.name || !props.control || !props.options) return null

  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        return (
          <div className={`${Styles.multiple_wrapper} ${props?.class_wrapper}`}>
            <label htmlFor={props.name}>
              {props.label}
              <span>{props?.requiredIcon}</span>
            </label>
            <Autocomplete
              {...field}
              freeSolo={props?.freeSolo}
              autoSelect={false}
              disablePortal
              multiple
              value={props.value}
              limitTags={4}
              id={props.name}
              options={props.options.map((option) => option)}
              getOptionLabel={(option) => option?.[props?.language] || option.value || option}
              onChange={handlerChange}
              filterSelectedOptions={true}
              disabled={props?.disabled}
              className={`${Styles.multiple_field} ${props?.class_field}`}
              renderOption={(params, option) => (
                <li className={Styles.multiple_field__list} key={option.id} {...params}>
                  {option?.[props?.language] || option.value}
                </li>
              )}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)

                const { inputValue } = params

                const isExisting = options.some((option) => inputValue === option.value)

                if (inputValue !== '' && !isExisting) {
                  filtered.push({
                    inputValue,
                    value: inputValue,
                  })
                }

                return filtered
              }}
              renderInput={(params) => {
                const items = params.InputProps.startAdornment
                return <TextField {...params} type="text" autoFocus={props?.autoFocus} placeholder={!items ? props.placeholder : ''} />
              }}
            />
            {props?.hintMessage && <span className={Styles.hint_message}>{props?.hintMessage}</span>}
            {props?.error && (
              <div className={`${Styles.error_wraper} ${props.error_wraper}`}>
                {props?.error ? <img className={Styles.error_icon} src={errorIcon} alt={'error'} /> : null}
                <div className={Styles.error}>{props?.error ? <span>{props.error?.message || 'error'}</span> : null}</div>
              </div>
            )}
          </div>
        )
      }}
    />
  )
}
