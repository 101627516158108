//Core
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
//Styles
import Styles from './Styles.module.scss'
//Icons
import { ReactComponent as CloseIcon } from '../../../../theme/assets/icons/close_updated.svg'
import { ReactComponent as SaveIcon } from '../../../../theme/assets/icons/cloud-save-icon.svg'
import { ReactComponent as ErrorIcon } from '../../../../theme/assets/icons/error.svg'
//MUI
import { Modal } from '@mui/material'
//Helpers
import { useDebouncedCallback } from 'use-debounce'
import { convertDate, formatDateToReadable } from '../../../../utils'
//Components
import { Spinner } from '../../../ChatSystem/Common/Spinner'
import { TextAreaField } from '../../../GeneralComponents'
//Form
import { useForm } from 'react-hook-form'
//Actions
import { zoomActions } from '../../../../actions'
//Data
import { SESSION_NOTE_STATUS } from '../../../../data'
import dayjs from 'dayjs'

/**
 * Component for creating or updating session notes in a modal.
 * @param {Object} props - Component props.
 * @param {boolean} props.open - Flag to control modal visibility.
 * @param {Function} props.onClose - Function to handle modal close event.
 * @param {Object} props.record - Record object containing session details.
 * @param {string} props.clientName - Name of the client associated with the session.
 * @param {Object} props.parent - Parent object associated with the client.
 * @param {Function} props.openSessionModal - Function to open another modal.
 * @returns {JSX.Element} SessionModalCreate component.
 */
export const SessionModalCreate = ({ open, onClose, record, clientName, parent, openSessionModal }) => {
  const dispatch = useDispatch()

  /**
   * Redux state
   */
  const user = useSelector((state) => state.auth.user)
  const loading = useSelector((state) => state.general.modalLoading)

  /**
   * Component state
   */
  const [noteStatus, setNoteStatus] = useState(SESSION_NOTE_STATUS[2])

  /**
   * Form management using react-hook-form
   */
  const {
    formState: { errors },
    getValues,
    setValue,
    control,
  } = useForm({
    mode: 'all',
    defaultValues: {
      subjective: record?.meeting_notes?.subjective || '',
      objective: record?.meeting_notes?.objective || '',
      assessment: record?.meeting_notes?.assessment || '',
      plan: record?.meeting_notes?.plan || '',
    },
  })

  /**
   * Function to handle creation of session notes.
   */
  const handleCreateNote = () => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        ...getValues(),
        parent_user_id: parent?.id,
      },
      token: user?.token,
    }

    dispatch(zoomActions.createSessionNote(dataForRequest))
  }

  /**
   * Function to handle updating session notes.
   * @param {Object} data - Data to update in session notes.
   * @param {Function} action - Action to execute on successful update.
   * @param {Function} actionOnError - Action to execute on error during update.
   */
  const handleUpdateNote = (data, action, actionOnError) => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        ...data,
        parent_user_id: parent?.id,
        meeting_notes_id: record?.meeting_notes?.id,
      },
      token: user?.token,
      action,
      actionOnError,
    }

    dispatch(zoomActions.updateSessionNote(dataForRequest))
  }

  /**
   * Debounced callback for auto-saving session notes.
   */
  const handleAutoSave = useDebouncedCallback(() => {
    const data = getValues()
    handleUpdateNote(
      data,
      () => {
        setNoteStatus(SESSION_NOTE_STATUS[2])
      },
      () => {
        setNoteStatus(SESSION_NOTE_STATUS[0])
        setValue('assessment', record?.meeting_notes?.assessment || '')
        setValue('objective', record?.meeting_notes?.objective || '')
        setValue('plan', record?.meeting_notes?.plan || '')
        setValue('subjective', record?.meeting_notes?.subjective || '')
        setTimeout(() => {
          setNoteStatus(SESSION_NOTE_STATUS[2])
        }, 2000)
      },
    )
  }, 1000)

  /**
   * Effect to create session notes if they do not exist initially.
   */
  useEffect(() => {
    if (!record?.meeting_notes) {
      handleCreateNote()
    }
  }, [record])

  /**
   * Function to handle sending session notes for approval.
   */
  const handleSendForApproval = () => {
    const dataForRequest = {
      meetingId: record?.calcom_session?.zoom_meeting_id,
      payload: {
        meeting_notes_id: record?.meeting_notes?.id,
      },
      token: user?.token,
      action: () => {
        onClose()
        openSessionModal(true)
      },
    }

    // Confirmation before sending for approval
    if (window.confirm(`Once a client approves a note, you can't edit it. Make sure that all data is filled in correctly.`)) {
      handleUpdateNote(
        getValues(),
        () => {
          dispatch(zoomActions.submitSessionNote(dataForRequest))
        },
        () => {
          setNoteStatus(SESSION_NOTE_STATUS[0])
          setTimeout(() => {
            setNoteStatus(SESSION_NOTE_STATUS[2])
          }, 2000)
        },
      )
    }
  }

  /**
   * Function to determine styling based on noteStatus.
   * @returns {Object} Object containing background and color styles.
   */
  const returnSavingData = () => {
    const dataStatus = {}

    switch (noteStatus) {
      case SESSION_NOTE_STATUS[0]:
        dataStatus.background = '#f1c4b3'
        dataStatus.color = '#d75221'
        break
      case SESSION_NOTE_STATUS[1]:
        dataStatus.background = '#feebdc'
        dataStatus.color = '#fe7000'
        break
      case SESSION_NOTE_STATUS[2]:
        dataStatus.background = '#e0f9f5'
        dataStatus.color = '#1cd4b0'
        break
    }

    return dataStatus
  }

  return (
    <Modal id={'session_note'} BackdropProps={{ onClick: () => {} }} open={open} onClose={onClose}>
      <div className={Styles.main_container}>
        <div className={Styles.header}>
          <div>
            <h4>Session note</h4>
            <div style={{ background: returnSavingData().background, color: returnSavingData().color }} className={Styles.save}>
              {noteStatus === SESSION_NOTE_STATUS[0] && <ErrorIcon className={Styles.save__error} />}
              {noteStatus === SESSION_NOTE_STATUS[1] && <Spinner width="20px" background={returnSavingData().background} color={returnSavingData().color} />}
              {noteStatus === SESSION_NOTE_STATUS[2] && <SaveIcon style={{ fill: returnSavingData().color }} />}
              <p>
                {noteStatus} {noteStatus === SESSION_NOTE_STATUS[2] && dayjs(record?.meeting_notes?.updated_at).format('MMM DD, h:mm A')}
              </p>
            </div>
          </div>
          <CloseIcon className={Styles.header__icon} onClick={onClose} />
        </div>
        <div className={Styles.main_container__content}>
          <div className={Styles.main_container__content__info_block}>
            <div className={Styles.main_container__content__info_block__session_details}>
              <p>{user?.user?.user_type === 'therapist' ? 'Client name' : 'Therapist name'}: </p>
              <span>{clientName}</span>
            </div>
            <div className={Styles.main_container__content__info_block__session_details}>
              <p>Date: </p>
              <span>{convertDate(record?.calcom_session?.start_time)}</span>
            </div>
          </div>
          {loading && (
            <div className={Styles.loading}>
              <Spinner />
            </div>
          )}
          <div className={Styles.main_container__content__therapist_block}>
            <TextAreaField
              name="subjective"
              control={control}
              onChange={(event) => {
                setValue('subjective', event.target.value, {
                  shouldValidate: true,
                })
                setLength(event.target.value.length)
              }}
              label={'Subjective'}
              class={Styles.textarea_wrap}
              classError={Styles.error}
              placeholder={'Subjective'}
              error={errors?.subjective}
              customOnChange={() => {
                setNoteStatus(SESSION_NOTE_STATUS[1])
                handleAutoSave()
              }}
            />
            <TextAreaField
              name="objective"
              control={control}
              onChange={(event) => {
                setValue('objective', event.target.value, {
                  shouldValidate: true,
                })
                setLength(event.target.value.length)
              }}
              label={'Objective'}
              class={Styles.textarea_wrap}
              classError={Styles.error}
              placeholder={'Objective'}
              error={errors?.objective}
              customOnChange={() => {
                setNoteStatus(SESSION_NOTE_STATUS[1])
                handleAutoSave()
              }}
            />
            <TextAreaField
              name="assessment"
              control={control}
              onChange={(event) => {
                setValue('assessment', event.target.value, {
                  shouldValidate: true,
                })
                setLength(event.target.value.length)
              }}
              label={'Assessment'}
              class={Styles.textarea_wrap}
              classError={Styles.error}
              placeholder={'Assessment'}
              error={errors?.assessment}
              customOnChange={() => {
                setNoteStatus(SESSION_NOTE_STATUS[1])
                handleAutoSave()
              }}
            />
            <TextAreaField
              name="plan"
              control={control}
              onChange={(event) => {
                setValue('plan', event.target.value, {
                  shouldValidate: true,
                })
                setLength(event.target.value.length)
              }}
              label={'Plan'}
              class={Styles.textarea_wrap}
              classError={Styles.error}
              placeholder={'Plan'}
              error={errors?.plan}
              customOnChange={() => {
                setNoteStatus(SESSION_NOTE_STATUS[1])
                handleAutoSave()
              }}
            />
          </div>
          <div onClick={handleSendForApproval} className={Styles.main_container__content__btn}>
            Send for approval
          </div>
        </div>
      </div>
    </Modal>
  )
}
